import React, { useEffect, useState } from "react";
import { Hook, IComponentParent, TriggeredHook, generateUuid } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";
import { LocalStorageConfigs, loadFromLocalRaw } from "../../../utils/LocalStorage";
import LoadingExpander from "../../App/Components/LoadingExpander";
import IExperimentContextProps from "../Types/IExperimentContextProps";
import { ExperimentContextProvider } from "./ExperimentContext";

export interface IExperimentContextLoaderProps extends IComponentParent {
  /** The lead id for the current session; */
  leadId: string;
  /** The first hook which obtains an experiment context using the session's lead id. */
  firstUseGetExperimentContextHook: Hook<IExperimentContextProps, boolean, { leadId: string }>;
  /** The hook which triggers a connection error message. */
  reportConnectionErrorHook: TriggeredHook<unknown, string>;
}

/** Awaits the successful or failed connection attempt to the external experiment services before loading the
 * experiment context and its children. If anonymous experiments are enabled, the anonymous experiment id is randomly
 * generated.
 * @remarks since hooks cannot be mounted conditionally, we cannot wrap them into an array or other collector and
 * instead must instantiate them individually from the props interface.
 */
const ExperimentContextLoader: React.FC<IExperimentContextLoaderProps> = ({
  leadId,
  children,
  firstUseGetExperimentContextHook,
  reportConnectionErrorHook
}) => {
  const [experimentVisitorId] = useState(() => {
    if (EnvironmentConfiguration.anonymousExperiments) {
      let anonymousExperimentId = loadFromLocalRaw(LocalStorageConfigs.anonymousExperimentId.key);
      if (!anonymousExperimentId) {
        anonymousExperimentId = generateUuid();
        localStorage.setItem(LocalStorageConfigs.anonymousExperimentId.key, anonymousExperimentId);
      }
      return anonymousExperimentId;
    } else return leadId;
  });

  const { result: firstResult, error: firstError } = firstUseGetExperimentContextHook({ leadId: experimentVisitorId });

  const { execute } = reportConnectionErrorHook();

  useEffect(() => {
    if (firstError) {
      execute("Convert.com");
    }
  }, [firstError, execute]);

  const experimentContexts = firstResult ? [firstResult] : firstError ? [] : null;
  return experimentContexts ? (
    <ExperimentContextProvider experimentContexts={experimentContexts}>{children}</ExperimentContextProvider>
  ) : (
    <LoadingExpander />
  );
};

export default ExperimentContextLoader;
