import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { SBPalette } from "sonobello.utilities.react.mui";

export const classNames = {
  content: "loading-expander"
};

export interface ILoadingExpanderProps {
  message?: string;
}

/** A component which fills the space provided by its parent and renders a centered spinner. */
const LoadingExpander: React.FC<ILoadingExpanderProps> = ({ message }) => (
  <Box
    className={classNames.content}
    width="100%"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{ backgroundColor: SBPalette.light.white.main }}
  >
    <Stack spacing={3} justifyContent="center" alignItems="center">
      <CircularProgress size={100} />
      {message && <Typography variant="h5">{message}</Typography>}
    </Stack>
  </Box>
);

export default LoadingExpander;
