import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { AppBar, Box, Stack, Theme, Toolbar, useMediaQuery } from "@mui/material";
import React, { ReactNode } from "react";
import { Defined, IDisable } from "sonobello.utilities.react";
import { SBPalette, TrackedIconButton } from "sonobello.utilities.react.mui";

import SonoBelloLogo from "../assets/Sono_Bello_horizontal_PNG.png";
import { ViewKeyMap } from "../types/Views";
import IPathObserver from "../V2/Routing/Types/IPathObserver";
import MainResizingContainer from "./main/Main.ResizingContainer";

/** The App Bar component which is always visible at the top of the view. */
const Header: React.FC<IPathObserver> = ({ pathName }) => {
  const showMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const disableBackButton = Object.values(ViewKeyMap).some(({ uri }) => pathName.includes(uri));
  return (
    <AppBar
      color="default"
      sx={theme => ({
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: showMobile ? SBPalette.light.watermarkLightGray.main : SBPalette.light.white.main
      })}
    >
      <Toolbar sx={{ px: 0, display: "flex", alignItems: "center", overflow: "hidden", justifyContent: "center" }}>
        {showMobile ? (
          <MobileHeader disableBackButton={disableBackButton} />
        ) : (
          <DesktopHeader disableBackButton={disableBackButton} />
        )}
      </Toolbar>
    </AppBar>
  );
};

interface IHeaderProps {
  disableBackButton: boolean;
}

const DesktopHeader: React.FC<IHeaderProps> = ({ disableBackButton }) => (
  <MainResizingContainer>
    <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
      <Stack direction="row" alignItems="center" spacing={3.5} sx={{ px: 2 }}>
        <BackButton disabled={disableBackButton} />
        <TitleLink>
          <Box alignItems="center" justifyContent="center">
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
              <img src={SonoBelloLogo} style={{ height: "45px" }} />
            </Stack>
          </Box>
        </TitleLink>
      </Stack>
      <HomeButton backgroundColor={SBPalette.light.paleGray.main} />
    </Box>
  </MainResizingContainer>
);

const MobileHeader: React.FC<IHeaderProps> = ({ disableBackButton }) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5} px={2} width="100%">
    <BackButton disabled={disableBackButton} />
    <TitleLink>
      <Stack justifyContent="center" alignItems="center" sx={{ width: "100%", pt: 0.35 }}>
        <img src={SonoBelloLogo} style={{ height: "35px" }} />
      </Stack>
    </TitleLink>
    <HomeButton backgroundColor={SBPalette.light.white.main} />
  </Stack>
);

const TitleLink: React.FC<{ children: ReactNode }> = ({ children }) => (
  <a
    href={process.env.REACT_APP_ESCAPE_URL}
    onClick={e => !confirm("Are you sure you want to leave the booker?") && e.preventDefault()}
    style={{ textDecoration: "none" }}
  >
    {children}
  </a>
);

const HomeButton: React.FC<{ backgroundColor: string }> = ({ backgroundColor }) => (
  <TrackedIconButton
    id="header_homeButton"
    sx={{ p: 1, backgroundColor }}
    onClick={() => (location.href = process.env.REACT_APP_ESCAPE_URL!)}
  >
    <HomeIcon />
  </TrackedIconButton>
);

const BackButton: React.FC<Defined<IDisable>> = ({ disabled }) => (
  <div>
    <TrackedIconButton
      id="header_backButton"
      disabled={disabled}
      sx={{ p: 1, backgroundColor: SBPalette.light.white.main }}
      onClick={() => window.history.go(-1)}
    >
      <ArrowBackIcon />
    </TrackedIconButton>
  </div>
);

export default Header;
