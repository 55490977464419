import { DateTime } from "luxon";

import { AppContextProps } from "../../../../AppContext";
import { Customer } from "../../../../dtos/Customer";
import { MedicalForm, MedicalFormState } from "../../../../types/MedicalForm";
import { NoteTagConfiguration, buildNote } from "../../../../types/Notes";
import { ICalendarService } from "../../../Calendar/Types/ICalendar";
import ICenter from "../../../Types/ICenter";

/** The properties necessary to build {@link IAppointment.note} that are not directly attributable to the Appointment. */
export interface IAppointmentNoteProps {
  /** The customer properties to be recorded in the appointment note. */
  readonly customer: Pick<
    Customer,
    "height" | "weight" | "dateOfBirth" | "qualificationDateUtc" | "isPreviouslyQualified" | "distanceToCenter"
  >;
  /** The center properties to be recorded in the appointment note. */
  readonly center: Pick<ICenter, "distance" | "name">;
  /** The medical form properties to be recorded in the appointment note. */
  readonly medicalForm: MedicalForm;
  /** The service properties to be recorded in the appointment note. */
  readonly service: ICalendarService;
}

/** {@inheritdoc IAppointmentNoteProps} */
export class AppointmentNoteProps implements IAppointmentNoteProps {
  /** {@inheritdoc IAppointmentNoteProps.customer} */
  readonly customer: Pick<
    Customer,
    "height" | "weight" | "dateOfBirth" | "qualificationDateUtc" | "isPreviouslyQualified" | "distanceToCenter"
  >;
  /** {@inheritdoc IAppointmentNoteProps.center} */
  readonly center: Pick<ICenter, "distance" | "name">;
  /** {@inheritdoc IAppointmentNoteProps.medicalForm} */
  readonly medicalForm: MedicalForm;
  /** {@inheritdoc IAppointmentNoteProps.service} */
  readonly service: ICalendarService;

  constructor({
    customerCState,
    reservation,
    medicalFormCState
  }: Pick<AppContextProps, "customerCState" | "reservation" | "medicalFormCState">) {
    if (!reservation) throw new Error("A reservation is required to build appointment note props.");
    this.customer = customerCState;
    this.center = reservation.center;
    this.medicalForm = medicalFormCState;
    this.service = reservation.service;
  }
}

// CONFIGURE THE APPOINTMENT NOTE TAGS HERE
// If a value isn't valid, return undefined, else return the string to be inserted.
// Tags will be organized in the same order as they are configured.
export const appointmentNoteTagsConfig: NoteTagConfiguration<IAppointmentNoteProps>[] = [
  {
    template: ({ service }) => service.serviceSubCategoryName
  },
  {
    template: () => "OBX"
  },
  {
    template: ({ service }) => service.businessUnit.name
  },
  {
    template: ({ customer }) => {
      const tokens: string[] = [];
      if (customer.height) tokens.push(`${customer.height.feet}ft${customer.height.inches}in`);
      if (customer.weight) tokens.push(`${customer.weight}lbs`);
      const bmi = Customer.getBmi(customer);
      if (bmi) tokens.push(`${bmi}BMI`);
      return tokens.join(", ") || undefined;
    }
  },
  {
    template: ({ customer }) =>
      customer.dateOfBirth ? DateTime.fromISO(customer.dateOfBirth).toLocaleString(DateTime.DATE_MED) : undefined
  },
  {
    template: ({ customer, medicalForm }) => {
      let note =
        customer.isPreviouslyQualified && customer.qualificationDateUtc
          ? `MQ Pass ${DateTime.fromISO(customer.qualificationDateUtc).toLocaleString(DateTime.DATE_MED)}`
          : undefined;

      if (note) return note;

      if (!medicalForm) return undefined;
      const medicalFormResult = MedicalForm.getResult(medicalForm);
      if (medicalFormResult === MedicalFormState.Incomplete) return undefined;

      note = medicalFormResult === MedicalFormState.Valid ? "MQ Pass" : undefined;

      if (!note) return note;

      note = `${note} ${DateTime.now().toLocaleString(DateTime.DATE_MED)}`;

      return note;
    }
  },
  {
    template: ({ center, customer }) =>
      center.distance
        ? `${center.distance} mi`
        : customer?.distanceToCenter
        ? `${customer?.distanceToCenter} mi`
        : undefined
  },
  {
    template: ({ center }) => center.name
  }
];

/** A scheduled interaction with the customer to perform a specified service. */
interface IAppointment {
  /** The id of the center in which the appointment is scheduled. */
  readonly centerId: string;
  /** The id of the GEM customer for which the appointment is scheduled. */
  readonly customerId: string;
  /** The lead id which resulted in the appointment. */
  readonly leadId: string;
  /** The service which will be conducted during the appointment. */
  readonly services: ICalendarService[];
  /** The ISO start time of the appointment in UTC. */
  readonly startTimeUtc: DateTime;
  /** The ISO end time of the appointment in UTC. */
  readonly endTimeUtc: DateTime;
  /** Patient specific notes for the appointment */
  readonly note: string;
}

/** {@inheritdoc IAppointment } */
export class Appointment implements IAppointment {
  /** {@inheritdoc IAppointment.centerId} */
  readonly centerId: string;
  /** {@inheritdoc IAppointment.customerId} */
  readonly customerId: string;
  /** {@inheritdoc IAppointment.leadId} */
  readonly leadId: string;
  /** {@inheritdoc IAppointment.services} */
  readonly services: ICalendarService[];
  /** {@inheritdoc IAppointment.startTimeUtc} */
  readonly startTimeUtc: DateTime;
  /** {@inheritdoc IAppointment.endTimeUtc} */
  readonly endTimeUtc: DateTime;
  /** {@inheritdoc IAppointment.note} */
  readonly note: string;

  constructor(
    centerId: string,
    customerId: string,
    leadId: string,
    services: ICalendarService[],
    startTimeUtc: DateTime,
    endTimeUtc: DateTime,
    noteTagProps: IAppointmentNoteProps,
    noteTagConfiguration?: NoteTagConfiguration<IAppointmentNoteProps>[]
  ) {
    this.centerId = centerId;
    this.customerId = customerId;
    this.leadId = leadId;
    this.services = services;
    this.startTimeUtc = startTimeUtc;
    this.endTimeUtc = endTimeUtc;
    this.note = buildNote(noteTagConfiguration ?? appointmentNoteTagsConfig, noteTagProps);
  }
}

export default IAppointment;
