import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  BottomNavigation,
  BottomNavigationAction,
  BottomNavigationActionProps,
  CircularProgress,
  Paper,
  styled
} from "@mui/material";
import React, { useContext } from "react";
import { UserEvent, UserEventContext } from "sonobello.utilities.react.mui";
import { UserEventType } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEvent";

import { NextButtonProps } from "../types/NextButtonProps";
import classNames from "../V2/Constants/ClassNames";

/** The step's 'next' button when in mobile mode.
 * @remarks Anchors to the bottom of the screen using the MUI {@link BottomNavigation} component.
 */
const NextButtonMobile: React.FC<NextButtonProps> = ({ disabled, label, isLoading, onClick }) => {
  const { onEvent } = useContext(UserEventContext);
  const handleClick = () => {
    onEvent(new UserEvent(UserEventType.Click, "next-button-mobile"));
    onClick && onClick();
  };
  return (
    <Paper className={classNames.stepNextButton} sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation showLabels onChange={handleClick}>
        <StyledBottomNavigationAction
          label={label}
          icon={isLoading ? <CircularProgress color="inherit" sx={{ ml: 1 }} /> : <NavigateNextIcon />}
          disabled={disabled || isLoading}
          sx={{ maxWidth: "unset", fontSize: "12pt" }}
        />
      </BottomNavigation>
    </Paper>
  );
};

const StyledBottomNavigationAction = styled(BottomNavigationAction)<BottomNavigationActionProps>(
  ({ disabled, theme }) => ({
    backgroundColor: disabled ? theme.palette.action.disabled : undefined,
    "&": { flexDirection: "row-reverse" },
    "& .MuiSvgIcon-root": {
      fontSize: theme.typography.h5.fontSize,
      height: "2rem",
      width: "2rem",
      marginRight: "-1rem"
    },
    "& .MuiBottomNavigationAction-label": { fontSize: theme.typography.h5.fontSize }
  })
);

export default NextButtonMobile;
