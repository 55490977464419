import { useCallback, useEffect, useRef } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import useObx from "../../../utils/UseObx";
import { ISignalRConnectionConfiguration, IUseGetConnectionInfoProps } from "../Components/SignalRConnection";

/**
 * A hook which obtains a {@link ISignalRConnectionConfiguration} and returns it when executed.
 * @remarks retries connection failures up to the configured number of times before reporting an error.
 */
const usePostSignalRNegotiate: TriggeredHook<
  ISignalRConnectionConfiguration,
  undefined,
  boolean,
  IUseGetConnectionInfoProps
> = ({ maxConnectionAttempts }) => {
  const connectionAttemptCount = useRef(0);

  const { res, err, loading, setReq } = useObx<ISignalRConnectionConfiguration, unknown>("Negotiate SignalR", {
    method: "post",
    url: "signalr/negotiate"
  });

  const execute = useCallback(() => {
    connectionAttemptCount.current++;
    setReq(r => r && { ...r, payload: {} });
  }, []);

  useEffect(() => {
    if (res) connectionAttemptCount.current = 0;
  }, [res]);

  useEffect(() => {
    if (err && connectionAttemptCount.current < maxConnectionAttempts) execute();
  }, [err]);

  return {
    result: res?.payload || null,
    error: connectionAttemptCount.current === maxConnectionAttempts ? Boolean(err) : null,
    isLoading: loading,
    execute
  };
};

export default usePostSignalRNegotiate;
