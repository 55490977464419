import React, { useEffect } from "react";
import { Location } from "react-router-dom";
import { IComponentParent, IControlledMutator, Nullable } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";
import { LocalStorageConfigs } from "../../../utils/LocalStorage";
import LoadingExpander from "../../App/Components/LoadingExpander";
import ISession, { CachedSession } from "../../Types/ISession";
import Routes from "../Types/AppPaths";
import {
  IInvalidLinkViewRouter,
  ILoadSessionViewRouter,
  IRestartSessionRouter,
  ISessionViewRouter
} from "../Types/IRouter";

export interface ISessionResolverProps extends IControlledMutator<Nullable<ISession>>, IComponentParent {
  /** The current path name of the application. */
  pathName: Location["pathname"];
  /** The controller which allows this component control of the user's view routing. */
  router: ISessionViewRouter & IInvalidLinkViewRouter & ILoadSessionViewRouter & IRestartSessionRouter;
}

/** Resolves the session for the application if one has already been instantiated. Otherwise, forwards the user to the
 * LoadSessionView if a session key is found, or the InvalidLinkView if otherwise.
 */
const SessionResolver: React.FC<ISessionResolverProps> = ({ pathName, router, value, onChange, children }) => {
  const [isComplete, setIsComplete] = React.useState(false);
  useEffect(() => {
    if (!value) {
      let cachedSession: Nullable<CachedSession> = null;
      try {
        cachedSession = new CachedSession();
        if (cachedSession.version !== EnvironmentConfiguration.version) {
          router.restartSession(cachedSession.key);
          return;
        }
        localStorage.setItem(LocalStorageConfigs.version.key, EnvironmentConfiguration.version);
      } catch (error) {
        setIsComplete(true);
        if (pathName) router.goToLoadSessionView();
        else router.goToInvalidLinkView();
        return;
      }
      onChange(cachedSession);
    }
    if (!pathName.includes(Routes.stepPrefix)) router.goToSessionView();
    setIsComplete(true);
  }, []);

  return !isComplete ? <LoadingExpander /> : <>{children}</>;
};

export default SessionResolver;
