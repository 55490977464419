import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { IComponentParent } from "sonobello.utilities.react";

import { isMobileView } from "../../../../utils/Constants";
import ConsultInfo, { IConsultInfoProps } from "../../Components/ConsultInfo";

export type IConfirmationStepProps = IConsultInfoProps & IComponentParent;

/** Component which contains the content for the Consult Confirmation step. */
const ConfirmationStep: React.FC<IConfirmationStepProps> = ({ children, ...rest }) => {
  const showMobile = isMobileView();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={4}
      sx={{ width: "100%", height: "100%", pt: showMobile ? undefined : "15%" }}
    >
      <Box sx={{ width: "100%" }}>
        <Stack spacing={4} alignItems="center" mb={0}>
          {!showMobile && <EventAvailableIcon sx={{ fontSize: "3rem" }} color="primary" />}
          <Typography variant="h4" color="primary" fontWeight="bold">
            You&apos;re Almost Done!
          </Typography>
          <Typography variant="h5">Confirm Your Appointment</Typography>
        </Stack>
      </Box>
      {children}
      <ConsultInfo {...rest} />
    </Stack>
  );
};

export default ConfirmationStep;
