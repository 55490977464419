import { Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";

import AppContext from "../../../AppContext";

/** The Welcome Step to be used when the flow type is No Book. */
const NoBookWelcomeStep: React.FC = () => {
  const contextProps = useContext(AppContext);
  const { customerCState, flowCState } = contextProps;

  const stepCount = flowCState.GetStepCount(contextProps) - 1;

  return (
    <Stack spacing={2}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="h4" component="span" color="primary">
            Hi again,
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            id="welcomeStep_firstName"
            variant="h4"
            component="span"
            color="primary"
          >{` ${customerCState.displayFirstName}`}</Typography>
        </Grid>
      </Grid>
      <Typography variant="body1">Schedule your free consultation with these {stepCount} easy steps.</Typography>
    </Stack>
  );
};

export default NoBookWelcomeStep;
