import { TriggeredHook } from "sonobello.utilities.react";
import { BearerTokenProvider } from "sonobello.utilities.react.axios";

import { Token } from "../../../dtos/Token";
import useObx from "../../../utils/UseObx";

/** A hook which sends a request to renew the provided token using its refresh secret and returns the resulting newly
 * issued token.
 */
const usePutToken: TriggeredHook<Token, Token> = () => {
  const { res, err, loading, setReq } = useObx<Token, Token>("Refresh Token", { url: "token", method: "put" });

  return {
    result: res?.payload || null,
    error: Boolean(err),
    isLoading: loading,
    execute: payload =>
      setReq(
        r =>
          r && {
            ...r,
            payload,
            config: { ...r.config, authProvider: new BearerTokenProvider(payload.refresh, () => null, false) }
          }
      )
  };
};

export default usePutToken;
