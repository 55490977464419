import { useContext } from "react";
import { Hook, Nullable } from "sonobello.utilities.react";

import { Flow } from "../../../types/Flow";
import ExperimentContext from "../Components/ExperimentContext";
import IFlowResolver from "../Types/IFlowResolver";

export interface IUseGetFlowParams {
  /** The {@link IFlowResolver} which determines the flow to be experienced by the user. */
  flowResolvers: IFlowResolver[];
  /** The name of the flow originally assigned to the user's session. */
  sessionFlowName: string;
}

export const flowNotResolvedMessage = "No resolver was able to identify a flow to be used.";

/** A hook which provides the experiment context to the given flow resovler and returns the flow resolved for the user. */
const useGetFlow: Hook<Flow, boolean, IUseGetFlowParams> = ({ flowResolvers, sessionFlowName }) => {
  const experimentContext = useContext(ExperimentContext);

  let result: Nullable<Flow> = null;
  flowResolvers.find(flowResolver => {
    try {
      result = flowResolver.invoke(experimentContext, sessionFlowName);
      return true;
    } catch {
      return false;
    }
  });
  if (!result) throw new Error(flowNotResolvedMessage);

  return { result, isLoading: false, error: null };
};

export default useGetFlow;
