const medicalQuestions = [
  "Have you had a heart attack in the last 5 years?",
  "Have you had a heart surgery or bypass?",
  "Have you received a pacemaker, stents, or defibrillator?",
  "Do you have active cancer or are you going through chemo / radiation therapy?",
  "Are you currently pregnant?",
  "Are you on Dialysis?",
  "Do you use an Oxygen tank while awake?",
  "Have you had an organ transplant or are you currently on the organ transplant waiting list?",
  "Are you taking blood thinners?",
  "Have you have had surgery on the area you want treated in the last 6 months?"
];
const medicalQuestionDiabetic = "Are you diabetic?";
const a1cKnownQuestion = "Do you know your A1C?";
const a1CThresholdQuestion = `Is your A1C higher than ${process.env.REACT_APP_MAXIMUM_A1C}?`;

export enum MedicalFormState {
  Valid = 1,
  Invalid = 2,
  Incomplete = 3
}

export class MedicalForm {
  partOneQuestions: Question[];
  partTwoQuestions: Question[];
  diabeticQuestion: Question;
  a1CKnownQuestion: Question;
  a1CValueQuestion: Question;

  constructor() {
    const questions = medicalQuestions.map(q => new Question(q));
    const splitIndex = Math.floor(questions.length / 2);
    this.partOneQuestions = questions.slice(0, splitIndex);
    this.partTwoQuestions = questions.slice(splitIndex, questions.length - 1);
    this.diabeticQuestion = new Question(medicalQuestionDiabetic);
    this.a1CKnownQuestion = new Question(a1cKnownQuestion);
    this.a1CValueQuestion = new Question(a1CThresholdQuestion);
  }

  static getResult = (medicalForm: MedicalForm): MedicalFormState => {
    if (medicalForm.diabeticQuestion.answer === undefined) return MedicalFormState.Incomplete;
    const questionResults = Question.aggregateResults([
      ...medicalForm.partOneQuestions,
      ...medicalForm.partTwoQuestions
    ]);
    if (questionResults.incomplete) return MedicalFormState.Incomplete;
    if (
      medicalForm.diabeticQuestion.answer &&
      medicalForm.a1CKnownQuestion.answer &&
      medicalForm.a1CValueQuestion.answer
    )
      return MedicalFormState.Invalid;
    return questionResults.affirmatives > 0 ? MedicalFormState.Invalid : MedicalFormState.Valid;
  };

  static getResultPart = (medicalFormQuestions: Question[]): MedicalFormState => {
    const questionResults = Question.aggregateResults(medicalFormQuestions);
    if (questionResults.incomplete) return MedicalFormState.Incomplete;
    return questionResults.affirmatives > 0 ? MedicalFormState.Invalid : MedicalFormState.Valid;
  };
}

interface AggregateQuestionResults {
  incomplete: boolean;
  affirmatives: number;
  negatives: number;
}

export class Question {
  text: string;
  answer?: boolean;

  constructor(text: string) {
    this.text = text;
    this.answer = undefined;
  }

  static aggregateResults = (questions: Question[]): AggregateQuestionResults => {
    return questions.reduce(
      (results, question) => {
        if (question.answer !== undefined) {
          if (question.answer) results.affirmatives += 1;
          else results.negatives += 1;
        } else results.incomplete = true;
        return results;
      },
      { incomplete: false, affirmatives: 0, negatives: 0 } as AggregateQuestionResults
    );
  };
}
