import { useCallback } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import { FlowStepProgress } from "../../../dtos/FlowStepProgress";
import useObx from "../../../utils/UseObx";

export interface IUsePostFlowStepProgressProps {
  /** The id of the lead associated with the obx experience. */
  leadId: string;
  /** The id of the flow associated with the obx experience. */
  flowId: string;
}

/** A hook which reports the step progress for the current obx experience to the server. */
const usePostFlowStepProgress: TriggeredHook<null, FlowStepProgress, boolean, IUsePostFlowStepProgressProps> = ({
  leadId,
  flowId
}) => {
  const { err, loading, setReq } = useObx<unknown, FlowStepProgress>(undefined, {
    method: "post",
    url: `leads/${leadId}/flows/${flowId}/steps`
  });

  const execute = useCallback((payload: FlowStepProgress) => setReq(r => r && { ...r, payload }), []);

  return {
    result: null,
    error: Boolean(err),
    isLoading: loading,
    execute
  };
};

export default usePostFlowStepProgress;
