import React, { useContext, useEffect, useRef } from "react";

import AppContext from "../../AppContext";
import EnvironmentConfiguration from "../../constants/EnvironmentConfiguration";
import { CustomerNote } from "../../dtos/CustomerNote";
import useObx from "../../utils/UseObx";

const isNoteBeingLoaded = (noteOldState?: CustomerNote, noteNewState?: CustomerNote): boolean =>
  !noteOldState && Boolean(noteNewState?.id);
const shouldNoteBePosted = (noteOldState?: CustomerNote, noteNewState?: CustomerNote): boolean =>
  Boolean(!noteOldState && noteNewState && !noteNewState.id);
const shouldNoteBeUpdated = (noteOldState?: CustomerNote | undefined, noteNewState?: CustomerNote): boolean => {
  return Boolean(noteOldState?.id && noteNewState?.id && JSON.stringify(noteOldState) !== JSON.stringify(noteNewState));
};

const RecordCustomerNote: React.FC = () => {
  const { customerCState, leadIdCState, setCustomerCState } = useContext(AppContext);
  const noteRef = useRef(customerCState.note);
  const { setReq, res, loading } = useObx<CustomerNote, CustomerNote>("PostCustomerNote");
  const loadingRef = useRef(loading);
  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);
  useEffect(
    () =>
      res &&
      setCustomerCState(c =>
        c.note?.id
          ? c
          : {
              ...c,
              distanceToCenter:
                c.distanceToCenter ||
                EnvironmentConfiguration.optimizedPatientScheduler.fallbackCustomerDistanceToCenter,
              note: res.payload
            }
      ),
    [res]
  );
  useEffect(() => {
    if (!isNoteBeingLoaded(noteRef.current, customerCState.note) && !loadingRef.current)
      if (shouldNoteBePosted(noteRef.current, customerCState.note))
        setReq({
          url: `Leads/${leadIdCState}/Customers/${customerCState.id}/Notes`,
          method: "post",
          payload: customerCState.note,
          custom: {}
        });
      else if (shouldNoteBeUpdated(noteRef.current, customerCState.note))
        setReq({
          url: `Leads/${leadIdCState}/Customers/${customerCState.id}/notes/${customerCState.note!.id}`,
          method: "put",
          payload: customerCState.note,
          custom: {}
        });
    noteRef.current = customerCState.note;
  }, [customerCState.note]);

  return <React.Fragment />;
};

export default RecordCustomerNote;
