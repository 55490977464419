import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Stack, Typography } from "@mui/material";
import React from "react";

import EscapePhone from "../EscapePhone";
import ViewWrapper, { ViewWrapperIconProps } from "./ViewWrapper";

export interface NoAvailabilityViewProps {
  /** The name of the customer whom has already completed this link. */
  customerFirstName: string;
}

/** The web page shown when the user has a valid link but the link has already been completed. */
const NoAvailabilityView: React.FC<NoAvailabilityViewProps> = ({ customerFirstName }) => {
  return (
    <ViewWrapper
      header={`Sorry ${customerFirstName}.`}
      subheader="There are no available appointment times on the calendar."
      icon={<EventBusyIcon {...ViewWrapperIconProps} />}
      hideNeedHelpDialog
    >
      <Stack alignItems="center" justifyContent="center" spacing={3} sx={{ width: "100%" }}>
        <Typography variant="h5">Still Interested?</Typography>
        <Typography variant="body1">
          Please contact us at <EscapePhone phoneNumber={process.env.REACT_APP_ESCAPE_PHONE_NUMBER} /> to schedule a new
          consultation.
        </Typography>
      </Stack>
    </ViewWrapper>
  );
};

export default NoAvailabilityView;
