import { useEffect, useState } from "react";
import { Hook, Nullable } from "sonobello.utilities.react";

import { Flow } from "../../../types/Flow";
import { Step } from "../../../types/Step";
import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import ICenter from "../../Types/ICenter";
import ICenterResponse, { ResponseDefaultCenter } from "../../Types/ICenterResponse";

export interface UseGetClosestCentersProps {
  /** The center to be returned if the given flow does not support multi-center booking. */
  defaultCenter: ICenter;
  /** The Postal Code to use as the origin. */
  postalCode?: string;
  /** The requested Center Id. */
  centerId?: string;
  /** The current flow for the session. */
  flow: Pick<Flow, "ContainsStep">;
}

/** A hook that obtains the list of centers in which the application can book appointments if a calendar can be resolved. */
const useGetClosestCenters: Hook<ICenter[], boolean, UseGetClosestCentersProps> = ({
  defaultCenter,
  postalCode,
  centerId,
  flow
}) => {
  const { res, err, loading, setReq } = useObx<ICenterResponse[]>("Get Bookable Centers");

  const [result, setResult] = useState<Nullable<ICenter[]>>(() => {
    if (flow.ContainsStep(Step.centerSelection)) {
      setReq(
        r => r && { ...r, url: `${ApiRequestPaths.getCentersV2()}?postalCode=${postalCode}&centerId=${centerId}` }
      );
      return null;
    }
    return [defaultCenter];
  });

  useEffect(() => {
    res && setResult(res.payload.map(c => new ResponseDefaultCenter(c)));
  }, [res]);

  return {
    result,
    error: Boolean(err),
    isLoading: loading
  };
};

export default useGetClosestCenters;
