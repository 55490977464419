import { Collapse, Typography } from "@mui/material";
import React, { useContext } from "react";

import AppContext from "../../../AppContext";
import { isMobileView } from "../../../utils/Constants";
import { MedicalStepHeader } from "./MedicalStep";
import FormQuestion from "./MedicalStep.FormQuestion";

/** Component which contains the content for the Medical A1C question. */
const A1CQuestion: React.FC = () => {
  const showMobile = isMobileView();
  const { medicalFormCState, setMedicalFormCState } = useContext(AppContext);

  return (
    <>
      {MedicalStepHeader}
      <FormQuestion
        id="medicalStep-diabeticQuestion"
        eventId="medicalStep-diabeticQuestion"
        isMobile={showMobile}
        question={medicalFormCState.diabeticQuestion}
        onChange={answer => setMedicalFormCState(s => ({ ...s, diabeticQuestion: { ...s.diabeticQuestion, answer } }))}
      />
      <Collapse in={medicalFormCState.diabeticQuestion.answer === true}>
        <FormQuestion
          id="medicalStep-a1CKnownQuestion"
          eventId="medicalStep-a1CKnownQuestion"
          isMobile={showMobile}
          question={medicalFormCState.a1CKnownQuestion}
          onChange={answer =>
            setMedicalFormCState(s => ({ ...s, a1CKnownQuestion: { ...s.a1CKnownQuestion, answer } }))
          }
        />
      </Collapse>
      <Collapse
        in={medicalFormCState.a1CKnownQuestion.answer === true && medicalFormCState.diabeticQuestion.answer === true}
      >
        <FormQuestion
          id="medicalStep-a1CThresholdQuestion"
          eventId="medicalStep-a1CThresholdQuestion"
          isMobile={showMobile}
          question={medicalFormCState.a1CValueQuestion}
          onChange={answer =>
            setMedicalFormCState(s => ({ ...s, a1CValueQuestion: { ...s.a1CValueQuestion, answer } }))
          }
        />
      </Collapse>
      <Collapse
        in={medicalFormCState.a1CKnownQuestion.answer === false && medicalFormCState.diabeticQuestion.answer === true}
      >
        <Typography>
          {`Please note, we are unable to treat patients with A1C greater than ${process.env.REACT_APP_MAXIMUM_A1C}.`}
        </Typography>
      </Collapse>
    </>
  );
};

export default A1CQuestion;
