import { DateTime, IANAZone } from "luxon";

import CalendarScheduleDay from "./CalendarScheduleDay";
import CalendarServiceSchedule from "./CalendarServiceSchedule";
import ICalendar, { ICachedScheduleSlot, ICalendarSchedule, ICalendarServiceSchedule, ScheduleSlot } from "./ICalendar";
import ILegacyCalendarResponse, {
  CalendarRequestSlotType,
  ILegacyCalendarRequestServiceSchedule,
  ILegacyCalendarRequestSlot
} from "./ILegacyCalendarResponse";

/** A {@link ScheduleSlot} for the legacy booking process. */
export class LegacyScheduleSlot extends ScheduleSlot {
  /**
   * @param requestSlot - The calendar slot received from the api calendar request.
   * @param zone - The time zone in which the slot's center is located.
   */
  constructor(requestSlot: ILegacyCalendarRequestSlot, zone: IANAZone) {
    super(requestSlot.startTimeUtc, requestSlot.endTimeUtc, requestSlot.type !== CalendarRequestSlotType.Open, zone);
  }

  override getCachedPrimitive = (): ICachedScheduleSlot => {
    return {
      startTimeUtcIso: this.startTimeUtc.toISO(),
      endTimeUtcIso: this.endTimeUtc.toISO(),
      timeZoneName: this.startTimeLocal.zone.name,
      isOccupied: this.isOccupied
    };
  };
}

export class LegacyCalendarServiceSchedule extends CalendarServiceSchedule {
  /**
   * @param requestSchedule - The calendar schedule received from the api calendar request.
   * @param zone - The time zone in which the slot's center is located.
   */
  constructor(serviceSchedule: ILegacyCalendarRequestServiceSchedule, zone: IANAZone) {
    const days: ICalendarSchedule = {};
    Object.entries(serviceSchedule.days).forEach(([isoDay, scheudule]) => {
      days[DateTime.fromISO(isoDay).toUTC().toISODate()] = new CalendarScheduleDay(
        scheudule?.map(s => new LegacyScheduleSlot(s, zone)) ?? []
      );
    });
    super(serviceSchedule.service, days, zone);
  }
}

/** An implementation of {@link ICalendar} from the legacy calendar payload pattern. */
class LegacyCalendar implements ICalendar {
  readonly schedules: ICalendarServiceSchedule[];

  /**
   * @param requestResponse - The calendar request response from the api.
   * @param zone - The time zone in which the slot's center is located.
   */
  constructor(requestResponse: ILegacyCalendarResponse, zone: IANAZone) {
    this.schedules = requestResponse.schedules.map(s => new LegacyCalendarServiceSchedule(s, zone));
  }
}

export default LegacyCalendar;
