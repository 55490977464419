import { Box, FormControl, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IControlledMutator, IOptions } from "sonobello.utilities.react";
import { Modal } from "sonobello.utilities.react.mui";

import { ICalendarService } from "../../../Calendar/Types/ICalendar";
import { ICenterServiceSelectorProps } from "./CenterSelectStepWrapper";

export interface ICenterServiceModalProps extends ICenterServiceSelectorProps {
  /** The form element to be used for the designation of the new service. */
  ServiceSelector: React.FC<IControlledMutator<ICalendarService> & IOptions<ICalendarService>>;
}

/** A selector which allows the user to identify the service to use when changing centers. */
const CenterServiceModal: React.FC<ICenterServiceModalProps> = ({
  disabled,
  options,
  value,
  onChange,
  setDisabled,
  ServiceSelector
}) => {
  const [service, setService] = useState(value);
  useEffect(() => setService(value), [value]);
  const handleConfirm = () => {
    setDisabled();
    onChange(service);
  };
  return (
    <Modal open={!disabled} onCancel={setDisabled} onConfirm={handleConfirm} confirmDisabled={service.id === value.id}>
      <Stack spacing={2}>
        <Typography variant="body1">
          The service you&apos;ve chosen is not available at your selected center.
        </Typography>
        <Typography variant="body1">Please select a different service:</Typography>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <ServiceSelector value={service} options={options} onChange={setService} />
          </FormControl>
        </Box>
      </Stack>
    </Modal>
  );
};

export default CenterServiceModal;
