import { useCallback, useEffect, useRef } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import useObx from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import OpsReservation from "../../../Types/OpsReservation";
import { ReservationRequest } from "./UseCreateLegacyReservation";
import { IUseReleaseOpsReservationProps } from "./UseReleaseHybridReservation";

/** Handles the network requests necessary to release a held reservation for the OPS calendar process. */
const useReleaseOpsReservation: TriggeredHook<
  void,
  OpsReservation,
  boolean,
  IUseReleaseOpsReservationProps
> = props => {
  const propsRef = useRef(props);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const { setReq, err, loading } = useObx<void, ReservationRequest>("Release Reservation", { method: "post" });

  const execute = useCallback(
    (reservation: OpsReservation) =>
      setReq(
        r =>
          r && {
            ...r,
            url: ApiRequestPaths.postOpsReservationUrl(
              propsRef.current.leadId,
              propsRef.current.customerId,
              reservation.center.id,
              reservation.service.id,
              reservation.slot.id
            ),
            payload: new ReservationRequest(reservation.slot)
          }
      ),
    []
  );

  return { result: null, error: Boolean(err), isLoading: loading, execute };
};

export default useReleaseOpsReservation;
