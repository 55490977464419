import { useContext, useEffect } from "react";

import AppContext from "../../../AppContext";
import { LeadResult, LeadResultType } from "../../../dtos/LeadResult";
import useObx from "../../../utils/UseObx";

/** Component which contains the content for the Medical verification step. */
const usePostLeadResult = () => {
  const { customerCState, leadIdCState, setLeadResultCState } = useContext(AppContext);

  const { res, err, loading, setReq } = useObx<LeadResult, LeadResult>("Post LeadResult", {
    method: "post",
    url: `leads/${leadIdCState}/result`
  });
  useEffect(() => {
    if (!res && !err) return;
    setLeadResultCState(
      res?.payload ||
        new LeadResult(customerCState.id, customerCState.opportunity.id, LeadResultType.ObxNonCandidateBmi)
    );
  }, [res, err]);

  return { setReq, loading };
};

export default usePostLeadResult;
