import React, { useContext, useEffect } from "react";

import AppContext from "../../AppContext";
import { DisqualifyingReason } from "../../dtos/DisqualifyingReason";
import { Flow } from "../../types/Flow";
import { Step } from "../../types/Step";
import { ViewConnector } from "../../types/ViewConnector";
import useObx from "../../utils/UseObx";

interface DisqualifyingReasonsProps {
  viewConnector: ViewConnector;
}

const getFlowNeedsDisqualifyingReasons = (flow: Flow): boolean =>
  flow.ContainsStep(Step.qualify) || flow.ContainsStep(Step.medicalPartOne);

/** Obtains the allowed disqualifying reasons from the api. */
const DisqualifyingReasons: React.FC<DisqualifyingReasonsProps> = ({ viewConnector }) => {
  const { disqualifyingReasonsCState, flowCState, leadIdCState, setDisqualifyingReasonsCState } =
    useContext(AppContext);
  const flowNeedsDisqualifyingReasons = getFlowNeedsDisqualifyingReasons(flowCState);
  const { res, err } = useObx<DisqualifyingReason[]>("Disqualifying Reasons", {
    url: `leads/${leadIdCState}/disqualifyingReasons`,
    noRequest: Boolean(disqualifyingReasonsCState) || !flowNeedsDisqualifyingReasons
  });
  useEffect(() => {
    if (!flowNeedsDisqualifyingReasons) setDisqualifyingReasonsCState([]);
  }, []);
  useEffect(() => res && setDisqualifyingReasonsCState(res.payload), [res]);
  useEffect(() => err && viewConnector.goToSomethingWentWrongView(), [err]);

  return <React.Fragment />;
};

export default DisqualifyingReasons;
