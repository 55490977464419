import { ComponentProps } from "react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import ConfirmationStepWrapper, {
  IConfirmationStepWrapperConfig,
  IConfirmationStepWrapperProps
} from "../Components/ConfirmationStepWrapper";

/**
 * A composed {@link ConfirmationStepWrapper} with props provided by the App Context which submits its appointments to
 * the new OPS appointment endpoint.
 */
const AppContextOpsConfirmationStepWrapper: React.FC<IConfirmationStepWrapperConfig> = AppContextValueProvider<
  IConfirmationStepWrapperProps,
  ComponentProps<typeof ConfirmationStepWrapper>
>({
  Component: ConfirmationStepWrapper,
  map: ({ customerCState, leadIdCState, medicalFormCState, reservation, setCustomerCState }) => {
    if (!reservation) throw "Must have a selected booking center and reservation.";
    return {
      customer: customerCState,
      leadId: leadIdCState,
      reservation,
      service: reservation.service,
      medicalForm: medicalFormCState,
      onConfirmedAppointment: customerNoteText =>
        setCustomerCState(c => ({ ...c, note: { ...c.note!, text: customerNoteText } }))
    };
  }
});

export default AppContextOpsConfirmationStepWrapper;
