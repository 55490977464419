import { EnhancedToken } from "../../../dtos/Token";
import AuthorizationRenewer, { IAuthorizationRenewerProps } from "../Components/AuthorizationRenewer";
import usePutToken from "../Hooks/UsePutToken";
import AppContextValueProvider from "./AppContextValueProvider";

const AppContextAuthorizationRenewer: React.FC<Pick<IAuthorizationRenewerProps, "router">> = AppContextValueProvider<
  Omit<IAuthorizationRenewerProps, "router">,
  IAuthorizationRenewerProps
>({
  Component: AuthorizationRenewer,
  map: ({ customerCState, refreshTokenCState, sessionKeyCState, setTokenCState }) => ({
    endedSessionProps: { customerName: customerCState.displayFirstName, sessionKey: sessionKeyCState },
    value: refreshTokenCState || null,
    onChange: t => setTokenCState(t ? new EnhancedToken(t) : undefined),
    usePutToken
  })
});

export default AppContextAuthorizationRenewer;
