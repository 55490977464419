import { NewPatientFlow } from "../../../flows/NewPatientFlow";
import { Flow } from "../../../types/Flow";
import { Step } from "../../../types/Step";
import StepperId from "../../App/Types/StepperId";

/** The flow that a new user should experience when booking an appointment that is lacking the welcome step. */
export const NewPatientNoWelcomeFlow: Flow = {
  ...NewPatientFlow,
  name: "newPatientNoWelcome",
  steps: {
    [Step.qualify]: NewPatientFlow.steps[Step.qualify],
    [Step.medicalPartOne]: NewPatientFlow.steps[Step.medicalPartOne],
    [Step.medicalPartTwo]: NewPatientFlow.steps[Step.medicalPartTwo],
    [Step.medicalA1C]: NewPatientFlow.steps[Step.medicalA1C],
    [Step.centerSelection]: NewPatientFlow.steps[Step.centerSelection],
    [Step.scheduling]: NewPatientFlow.steps[Step.scheduling],
    [Step.confirmation]: NewPatientFlow.steps[Step.confirmation]
  },
  stepperConfiguration: NewPatientFlow.stepperConfiguration.filter(({ stepperId }) => stepperId !== StepperId.Welcome)
};
