import React from "react";

import { FlowType } from "../../../types/Flow";
import NewPatientWelcomeStep from "./WelcomeStep.NewPatient";
import NoBookWelcomeStep from "./WelcomeStep.NoBook";
import RebookWelcomeStep from "./WelcomeStep.Rebook";
import WelcomeStepWrapper from "./WelcomeStep.Wrapper";

interface WelcomeStepProps {
  flowType: FlowType;
}

/** The Welcome Step. */
const WelcomeStep: React.FC<WelcomeStepProps> = ({ flowType }) => {
  return (
    <WelcomeStepWrapper>
      {flowType === FlowType.newPatient ? (
        <NewPatientWelcomeStep />
      ) : flowType === FlowType.noBook ? (
        <NoBookWelcomeStep />
      ) : (
        <RebookWelcomeStep />
      )}
    </WelcomeStepWrapper>
  );
};

export default WelcomeStep;
