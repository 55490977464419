import { useCallback } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import { LeadResult } from "../../../../dtos/LeadResult";
import useObx from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";

export interface IUseCreateLeadResultProps {
  /** The id of the session's lead. */
  leadId: string;
}

/** A hook which dispatches the result for the session's lead to the OBX server. */
const useCreateLeadResult: TriggeredHook<LeadResult, LeadResult, boolean, IUseCreateLeadResultProps> = ({ leadId }) => {
  const { res, err, loading, setReq } = useObx<LeadResult, LeadResult>("Post Lead Result", {
    method: "post",
    url: ApiRequestPaths.postLeadResult(leadId)
  });
  const execute = useCallback((leadResult: LeadResult) => setReq(r => r && { ...r, payload: leadResult }), []);
  return { result: res?.payload || null, error: Boolean(err), isLoading: loading, execute };
};

export default useCreateLeadResult;
