import { Nullable } from "sonobello.utilities.react";

/** A binding of a SignalR topic to some custom data we want to associate with it.
 * @typeparam TCustom - The type of custom data we want to bind to the topic.
 */
export interface ITopicSource<TCustom = undefined> {
  /** The name of the topic which the SignalR webhook will broadcast on. */
  topic: string;
  /** Some static custom data we want to bind to the topic. */
  custom: TCustom;
}

export class TopicSource<TCustom = undefined> implements ITopicSource<TCustom> {
  readonly topic: string;
  readonly custom: TCustom;

  constructor(topic: string, custom: TCustom) {
    this.topic = topic;
    this.custom = custom;
  }

  /** Bind a SignalR message payload to its topic source. */
  GetPayload<TPayload>(payload: TPayload): TopicPayload<TCustom, TPayload> {
    return new TopicPayload(this.topic, this.custom, payload);
  }
}

/** A binding of a SignalR message payload to its topic source and custom data.
 * @typeparam TCustom - The type of custom data we want to bind to the topic.
 * @typeparam TPayload - The type of the message payload that was broadcast for the SignalR topic.
 */
export interface ITopicPayload<TCustom, TPayload> extends ITopicSource<TCustom> {
  /** The message payload that was broadcast for the SignalR topic. */
  readonly payload: Nullable<TPayload>;
}

export class TopicPayload<TCustom, TPayload> implements ITopicPayload<TCustom, TPayload> {
  readonly topic: string;
  readonly custom: TCustom;
  payload: Nullable<TPayload>;

  constructor(topic: string, custom: TCustom, payload: Nullable<TPayload>) {
    this.topic = topic;
    this.custom = custom;
    this.payload = payload;
  }
}
