import { BrowserInfo, detect as detectDevice } from "detect-browser";
import React, { useContext, useEffect } from "react";

import AppContext from "../../AppContext";
import { ObxLeadDevice } from "../../dtos/ObxLeadDevice";
import useObx from "../../utils/UseObx";

/** Reports the current step progress to the API. */
const ReportLeadDevice: React.FC = () => {
  const appContext = useContext(AppContext);
  const { isDeviceLoggedCState, leadIdCState, setIsDeviceLoggedCState } = appContext;

  const { setReq: setLeadsDevicesRequest } = useObx<unknown, ObxLeadDevice>(undefined, {
    url: `leads/${leadIdCState}/devices`,
    method: "post"
  });
  useEffect(() => {
    // on mount report the device if it has not been reported before
    if (isDeviceLoggedCState) return;
    const device = detectDevice() as BrowserInfo;
    const obxLeadDevice = new ObxLeadDevice(leadIdCState, device);
    setLeadsDevicesRequest(r => r && { ...r, payload: obxLeadDevice });
    setIsDeviceLoggedCState(true);
  }, []);

  return <React.Fragment />;
};

export default ReportLeadDevice;
