import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import React, { useContext } from "react";
import { UserEvent, UserEventContext } from "sonobello.utilities.react.mui";
import { UserEventType } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEvent";

import { NextButtonProps } from "../types/NextButtonProps";
import classNames from "../V2/Constants/ClassNames";

/** The step's 'next' button when in desktop mode. */
const NextButtonDesktop: React.FC<NextButtonProps> = ({ disabled, label, isLoading, onClick }) => {
  const { onEvent } = useContext(UserEventContext);
  const handleClick = () => {
    onEvent(new UserEvent(UserEventType.Click, "next-button-desktop"));
    onClick && onClick();
  };
  return (
    <Grid item mt="2rem">
      <Box width="100%" display="flex" justifyContent="center">
        <Button
          className={classNames.stepNextButton}
          disabled={disabled || isLoading}
          onClick={handleClick}
          endIcon={isLoading ? <CircularProgress color="inherit" sx={{ ml: 1 }} size="1.5rem" /> : <NavigateNextIcon />}
        >
          {label}
        </Button>
      </Box>
    </Grid>
  );
};

export default NextButtonDesktop;
