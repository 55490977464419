import { useTheme } from "@mui/material";
import React from "react";
import { LeafLogoSvg } from "sonobello.utilities.react.mui";

import ViewWrapper from "./ViewWrapper";

/** The web page shown when the application internally threw an unhandled exception. */
const SomethingWentWrongView: React.FC = () => {
  const theme = useTheme();
  return (
    <ViewWrapper
      header="Sorry, Something Went Wrong!"
      subheader="We aren't able to continue your session."
      icon={
        <LeafLogoSvg
          fill={theme.palette.primary.main}
          style={{ height: theme.spacing(10), width: theme.spacing(10) }}
        />
      }
    />
  );
};

export default SomethingWentWrongView;
