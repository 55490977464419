import { useContext } from "react";

import AppContext from "../../../../AppContext";
import useReleaseHybridReservation, { IUseReleaseHybridReservationConfig } from "../Hooks/UseReleaseHybridReservation";

const AppContextUseReleaseReservation = (props: IUseReleaseHybridReservationConfig) => {
  const { leadIdCState, customerCState } = useContext(AppContext);
  return useReleaseHybridReservation({ ...props, customerId: customerCState.id, leadId: leadIdCState });
};

export default AppContextUseReleaseReservation;
