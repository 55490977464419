import { DateTime, IANAZone } from "luxon";
import { Nullable } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../constants/EnvironmentConfiguration";
import { Customer, EnhancedCustomer } from "../../dtos/Customer";
import { CustomerNote } from "../../dtos/CustomerNote";
import { DisqualifyingReason } from "../../dtos/DisqualifyingReason";
import { LeadResult } from "../../dtos/LeadResult";
import { EnhancedToken, Token } from "../../dtos/Token";
import { MedicalForm } from "../../types/MedicalForm";
import { LocalStorageConfigs, loadFromLocal, loadFromLocalRaw, saveToLocal } from "../../utils/LocalStorage";
import { ICached } from "./ICached";
import ICenter, { Center } from "./ICenter";
import IReservation from "./IReservation";
import { CachedLegacyReservation, ICachedLegacyReservation } from "./LegacyReservation";
import { CachedOpsReservation, ICachedOpsReservation } from "./OpsReservation";

/** A representation of all of the values necessary to run an instance of the application. */
interface ISession {
  /** The customer associated with the current OBX session. */
  readonly customer: EnhancedCustomer;
  /** The center associated with the current OBX session. */
  readonly defaultCenter: DefaultCenter;
  /** The reasons why a user can be considered a non-candidate. */
  readonly disqualifyingReasons?: DisqualifyingReason[];
  /** The key for the current OBX session taken from the unique OBX url. */
  readonly key: string;
  /** The name of the Flow which the user should experience in this session. */
  readonly flowName: string;
  /** Flag indicating if the device for this local session has been recorded. */
  readonly isDeviceLogged: boolean;
  /** The id of the currently active lead for this OBX session. */
  readonly leadId: string;
  /** The lead result for the OBX session. */
  readonly leadResult: Nullable<LeadResult>;
  /** The id of the currently active flow for this OBX session. */
  readonly flowId: string;
  /** The state of the medical form for this OBX session. */
  readonly medicalForm: Nullable<MedicalForm>;
  /** The appointment reservation currently held by the session. */
  readonly reservation: Nullable<IReservation>;
  /** The bearer authorization token used to make requests for this OBX session. */
  readonly token: EnhancedToken;
  /** The version number of the software that is compatible with this session. */
  readonly version?: string;
}

/** {@inheritdoc ISession} */
export class Session implements ISession {
  readonly defaultCenter: DefaultCenter;
  readonly customer: EnhancedCustomer;
  readonly disqualifyingReasons?: DisqualifyingReason[];
  readonly key: string;
  readonly flowName: string;
  readonly isDeviceLogged: boolean;
  readonly leadId: string;
  readonly leadResult: Nullable<LeadResult>;
  readonly flowId: string;
  readonly medicalForm: Nullable<MedicalForm>;
  readonly reservation: Nullable<IReservation>;
  readonly token: EnhancedToken;
  readonly version?: string;

  constructor(
    customer: EnhancedCustomer,
    defaultCenter: DefaultCenter,
    key: string,
    flowName: string,
    isDeviceLogged: boolean,
    leadId: string,
    flowId: string,
    token: EnhancedToken,
    version: string,
    leadResult: Nullable<LeadResult>,
    medicalForm: Nullable<MedicalForm>,
    reservation: Nullable<IReservation>,
    disqualifyingReasons?: DisqualifyingReason[]
  ) {
    this.defaultCenter = defaultCenter;
    this.customer = customer;
    this.disqualifyingReasons = disqualifyingReasons;
    this.flowName = flowName;
    this.isDeviceLogged = isDeviceLogged;
    this.key = key;
    this.leadId = leadId;
    this.leadResult = leadResult;
    this.flowId = flowId;
    this.medicalForm = medicalForm;
    this.reservation = reservation;
    this.token = token;
    this.version = version;
  }
}

/** The default center to be used for the session. */
export class DefaultCenter extends Center implements ICached {
  constructor(
    id: string,
    distance: Nullable<number>,
    name: string,
    address: string,
    phoneNumber: string,
    timeZone: IANAZone
  ) {
    super(id, distance, name, address, phoneNumber, timeZone);
  }
  /** {@inheritdoc ICached.cache} */
  readonly cache = () => saveToLocal(this, LocalStorageConfigs.center.key);
}

/** A default center that has been restored from the browser cache. */
class CachedDefaultCenter extends DefaultCenter {
  constructor(center: ICenter) {
    super(center.id, center.distance, center.name, center.address, center.phoneNumber, center.timeZone);
  }
}

/** A session constructed the content of the browser cache. */
export class CachedSession extends Session {
  /**
   * @throws if the session cannot be built from the cache.
   */
  constructor() {
    const defaultCenter = loadFromLocal<ICenter>(LocalStorageConfigs.center.key);
    const customer = loadFromLocal<Customer>(LocalStorageConfigs.customer.key);
    const customerNote = loadFromLocal<CustomerNote>(LocalStorageConfigs.customerNote.key);

    const token = loadFromLocal<Token>(LocalStorageConfigs.token.key);

    // build an unvalidated session
    const session = {
      customer: customer && new EnhancedCustomer(customer, customerNote),
      defaultCenter: defaultCenter && new CachedDefaultCenter(defaultCenter),
      disqualifyingReasons: loadFromLocal<DisqualifyingReason[]>(LocalStorageConfigs.disqualifyingReasons.key),
      isDeviceLogged: Boolean(loadFromLocalRaw(LocalStorageConfigs.isDeviceLogged.key)) || false,
      leadId: loadFromLocalRaw(LocalStorageConfigs.leadId.key),
      leadResult: loadFromLocal<LeadResult>(LocalStorageConfigs.leadResult.key),
      flowId: loadFromLocalRaw(LocalStorageConfigs.flowId.key),
      flowName: loadFromLocalRaw(LocalStorageConfigs.flow.key),
      key: loadFromLocalRaw(LocalStorageConfigs.sessionKey.key),
      medicalForm: loadFromLocal<MedicalForm>(LocalStorageConfigs.medicalForm.key),
      token: token && new EnhancedToken(token),
      version: loadFromLocalRaw(LocalStorageConfigs.version.key)
    };

    // return undefined if we cannot validate the session
    if (
      !session.customer ||
      !session.defaultCenter ||
      !session.key ||
      !session.flowName ||
      !session.leadId ||
      !session.flowId ||
      !session.token
    )
      throw "The session does not exist or is missing required properties.";

    // load optional properties
    let reservation: Nullable<IReservation> = null;
    const cachedOpsReservation = loadFromLocal<ICachedOpsReservation>(LocalStorageConfigs.opsReservation.key);
    if (cachedOpsReservation) reservation = new CachedOpsReservation(cachedOpsReservation);
    else {
      const cachedReservation = loadFromLocal<ICachedLegacyReservation>(LocalStorageConfigs.legacyReservation.key);
      if (cachedReservation) reservation = new CachedLegacyReservation(cachedReservation);
    }
    if (reservation && reservation.expirationUtc < DateTime.utc()) reservation = null;
    super(
      session.customer,
      session.defaultCenter,
      session.key,
      session.flowName,
      session.isDeviceLogged,
      session.leadId,
      session.flowId,
      session.token,
      session.version || EnvironmentConfiguration.version,
      session.leadResult || null,
      session.medicalForm || null,
      reservation,
      session.disqualifyingReasons
    );
  }
}

export default ISession;
