import { ICalendarService } from "../Calendar/Types/ICalendar";
import ICenter, { Center } from "./ICenter";

/** A record of a center including its list of bookable services. */
interface IBookableCenter extends ICenter {
  /** The services which the center currently supports booking appointments for. */
  readonly services: ICalendarService[];
}

/** {@inheritdoc IBookableCenter} */
export class BookableCenter extends Center implements IBookableCenter {
  readonly services: ICalendarService[];

  /**
   * @param center - the basic properties of the bookable center.
   * @param services - the services which the center currently supports booking appointments for.
   */
  constructor(center: ICenter, services: ICalendarService[]) {
    super(center.id, center.distance, center.name, center.address, center.phoneNumber, center.timeZone);
    this.services = services;
  }
}

export default IBookableCenter;
