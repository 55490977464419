import { MenuItem, Select } from "@mui/material";
import React from "react";

import { IServiceSelectorProps } from "../../Scheduling/Components/SchedulingStep";

/** A selector which alows the user to elect a new service. */
const ServiceSelector: React.FC<IServiceSelectorProps> = ({ value, options, disabled, onChange }) => {
  return (
    <Select
      disabled={disabled}
      value={value.id}
      onChange={({ target: { value } }) => onChange(options.find(o => o.id === value)!)}
    >
      {options.map(({ id, name }) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ServiceSelector;
