import { Optional } from "sonobello.utilities.react.mui";

export interface NoteTagConfiguration<TNoteTagProps> {
  key?: string;
  template: (noteTagProps: TNoteTagProps) => Optional<string>;
}

export function buildNote<TProps>(noteTagsConfiguration: NoteTagConfiguration<TProps>[], noteTagProps: TProps): string {
  const tags = noteTagsConfiguration.reduce((collector, config) => {
    const value = config.template(noteTagProps);
    if (value) collector.push(value);
    return collector;
  }, [] as string[]);
  return tags.join(" / ");
}
