import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import AppContext from "../../../AppContext";
import { Customer, EnhancedCustomer } from "../../../dtos/Customer";
import { CustomerNote, CustomerNoteProps } from "../../../dtos/CustomerNote";
import { DisqualifyingReason, DisqualifyingReasonsType } from "../../../dtos/DisqualifyingReason";
import { LeadResult, LeadResultType } from "../../../dtos/LeadResult";
import { IFlowStepProps } from "../../../types/Flow";
import { StepProps } from "../../../types/Step";
import StepWrapper from "../wrapper/StepWrapper";
import QualifyingInfoForm from "./QualifyStep.QualifyingInfoForm.v12";
import usePostCustomer from "./QualifyStep.UsePostCustomer";

/** Component which contains the content for selecting the consult center for the here. */
const QualifyStep: React.FC<StepProps> = () => {
  const { setStep, ...rest } = useOutletContext<IFlowStepProps>();
  const {
    bookingController,
    customerCState,
    defaultCenter,
    disqualifyingReasonsCState,
    leadResultCState,
    medicalFormCState,
    setCustomerCState
  } = useContext(AppContext);
  const { loading, updateCustomer, updateCustomerAndLeadResult } = usePostCustomer({ setStep });

  const onClickNext = () => {
    const noteProps: CustomerNoteProps = {
      customer: customerCState,
      center: bookingController?.selected?.center || defaultCenter,
      disqualifyingReasons: disqualifyingReasonsCState,
      leadResult: leadResultCState,
      medicalForm: medicalFormCState,
      service: null
    };
    setCustomerCState(c => ({
      ...c,
      note: c?.note ? { ...c.note, text: CustomerNote.getText(noteProps) } : new CustomerNote(noteProps)
    }));
    const customerUpdate = EnhancedCustomer.toBase(customerCState);
    const disqualifyingReasonType = Customer.getDisqualifyingReason(customerUpdate);
    if (disqualifyingReasonType) {
      if (!disqualifyingReasonsCState) throw "Disqualifying reasons was not populated.";
      const disqualifyingReason = DisqualifyingReason.GetReason(disqualifyingReasonsCState, disqualifyingReasonType);
      const leadResultType =
        disqualifyingReasonType === DisqualifyingReasonsType.ObxAge
          ? LeadResultType.ObxNonCandidateMedical
          : LeadResultType.ObxNonCandidateBmi;
      const leadResultUpdate = LeadResult.AddDisqualifyingReason(
        new LeadResult(customerCState.id, customerCState.opportunity.id, leadResultType),
        disqualifyingReason
      );
      updateCustomerAndLeadResult(customerUpdate, leadResultUpdate);
    } else updateCustomer(customerUpdate);
  };

  return (
    <StepWrapper
      setStep={setStep}
      {...rest}
      nextButtonLoading={loading || !disqualifyingReasonsCState}
      nextButtonOnClick={onClickNext}
    >
      <Stack spacing={2}>
        <Typography variant="body1">
          Please answer a few medical screening questions so we may determine if you are a candidate for our procedures.
        </Typography>
        <Typography variant="body1">First, we&apos;ll need your height, weight, and date of birth:</Typography>
        <QualifyingInfoForm isLoading={loading} />
      </Stack>
    </StepWrapper>
  );
};

export default QualifyStep;
