import { useCallback, useEffect } from "react";
import { TriggeredHook } from "sonobello.utilities.react";
import { UseApiErrorResponse } from "sonobello.utilities.react.axios";

import { ObxError } from "../../../../dtos/ObxError";
import { ErrorReport } from "../../../../types/ErrorReport";
import useObx, { UseObxCustom } from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";

export interface IUseGetCalendarEventRequest {
  /** The id of the lead associated with the appointment. */
  leadId: string;
  /** The id of the customer associated with the appointment. */
  customerId: string;
  /** The id of the appointment to obtain the calendar event url for. */
  appointmentId: string;
}

const calendarEventError = (
  useObxError: UseApiErrorResponse<undefined, UseObxCustom<Record<string, unknown>>, ObxError>
) =>
  `Failed to obtain the calendar event url with status code '${useObxError.error.code}': ${useObxError.error.message}`;

/** A hook which obtains the calendar event url for the given request params.
 * Reports failure to the error report endpoint.
 * @remarks The hook value is `null` until the request is complete, after which the value is either the url or
 * `undefined` if an error occured.
 */
const useGetCalendarEventUrl: TriggeredHook<string, IUseGetCalendarEventRequest> = () => {
  const {
    res: calendarResult,
    err: calendarError,
    loading: calendarLoading,
    setReq: setCalendarEventReq
  } = useObx<string>("GET CalendarEvent");
  const { loading: errorReportLoading, setReq: setErrorReportReq } = useObx<void, ErrorReport>("POST ErrorReport", {
    method: "post",
    url: ApiRequestPaths.errorReport
  });

  const execute = useCallback(
    ({ leadId, customerId, appointmentId }: IUseGetCalendarEventRequest) =>
      setCalendarEventReq({
        url: ApiRequestPaths.getCalendarEventUrl(leadId, customerId, appointmentId),
        custom: {}
      }),
    []
  );

  useEffect(() => {
    if (calendarError)
      setErrorReportReq(
        r => r && { ...r, payload: new ErrorReport(new Error(calendarEventError(calendarError)), "no stack") }
      );
  }, [calendarError]);

  return {
    result: (!errorReportLoading && calendarResult?.payload) || null,
    execute,
    isLoading: calendarLoading || errorReportLoading,
    error: !errorReportLoading && Boolean(calendarError)
  };
};

export default useGetCalendarEventUrl;
