import { DateTime, IANAZone } from "luxon";
import { Nullable } from "sonobello.utilities.react";

import {
  ICalendarSchedule,
  ICalendarScheduleDay,
  ICalendarService,
  ICalendarServiceSchedule,
  IScheduleSlot
} from "./ICalendar";

class CalendarServiceSchedule implements ICalendarServiceSchedule {
  readonly days: ICalendarSchedule;
  readonly isAnySlotAvailable: boolean;
  readonly lastScheduleDateLocal: DateTime;
  readonly service: ICalendarService;
  readonly timeZone: IANAZone;

  constructor(service: ICalendarService, days: ICalendarSchedule, timeZone: IANAZone) {
    this.service = service;
    this.days = days;
    this.timeZone = timeZone;
    this.isAnySlotAvailable = false;
    let earliestIsoDate = DateTime.utc().toISODate();
    let earliestDay: ICalendarScheduleDay | null = null;
    for (const [date, day] of Object.entries(days)) {
      this.isAnySlotAvailable = this.isAnySlotAvailable || day.isAnySlotAvailable;
      if (!earliestDay || date > earliestIsoDate) {
        earliestIsoDate = date;
        earliestDay = day;
      }
    }
    this.lastScheduleDateLocal = DateTime.fromISO(earliestIsoDate, { zone: timeZone });
  }

  readonly getFirstAvailableSlot = (slotSelectionMaskedMinutes?: number): Nullable<IScheduleSlot> => {
    const maskedStartTimeUtc =
      slotSelectionMaskedMinutes && DateTime.utc().plus({ minutes: slotSelectionMaskedMinutes });
    const orderedScheduleDays = Object.entries(this.days).sort(([key1], [key2]) => (key1 > key2 ? 1 : -1));
    for (const [, calendarScheduleDay] of orderedScheduleDays) {
      if (!calendarScheduleDay.isAnySlotAvailable) continue;
      const firstSlot = calendarScheduleDay.slots.find(
        slot => !slot.isOccupied && (!maskedStartTimeUtc || slot.startTimeUtc > maskedStartTimeUtc)
      );
      if (firstSlot) return firstSlot;
    }
    return null;
  };
}

export default CalendarServiceSchedule;
