import { Grid, Stack } from "@mui/material";
import { DateTime, IANAZone } from "luxon";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { IControl, IDisable, IMutator, IOptions, Nullable } from "sonobello.utilities.react";
import { UserEvent, UserEventType } from "sonobello.utilities.react.mui";

import { isMobileView } from "../../../../utils/Constants";
import useInterval from "../../../../utils/UseInterval";
import CalendarScheduleDay, { ITimeLocaleSlotGroups } from "../../../Calendar/Types/CalendarScheduleDay";
import { ICalendarSchedule, ICalendarServiceSchedule, IScheduleSlot } from "../../../Calendar/Types/ICalendar";
import IUserEventSource from "../../Types/IUserEventSource";
import { IScheduleSlotSelectorProps as ISlotSelectorProps } from "./SchedulingStep";

interface ILocaleSelectionCutOffs {
  /** The hour of the day at which slots should no longer be categorized as 'morning' slots (exclusive). */
  readonly morning: number;
  /** The hour of the day at which slots should no longer be categorized as 'afternoon' slots (exclusive). */
  readonly afternoon: number;
}

class ScheduleSlotSelectorState {
  /** The current calendar date (no-time) active for slot selector. */
  readonly date: Nullable<DateTime>;
  /** The slots available for render for the currently active date that are not masked. */
  readonly dateSlotsGroupedByTimeLocale: Nullable<ITimeLocaleSlotGroups>;

  constructor(
    date: Nullable<DateTime>,
    schedule: ICalendarSchedule,
    cutOffs: ILocaleSelectionCutOffs,
    maskTimesLeadMinutes: number,
    currentlySelectedSlotTime: Nullable<DateTime>
  ) {
    this.date = date;

    if (date == null) this.dateSlotsGroupedByTimeLocale = null;
    else {
      const maskedSlotTimeEndsUtc = DateTime.utc().plus({ minutes: maskTimesLeadMinutes });
      const maskedSlots = schedule[date.toISODate()].slots.filter(
        slot =>
          slot.startTimeUtc > maskedSlotTimeEndsUtc ||
          (currentlySelectedSlotTime && slot.startTimeUtc.equals(currentlySelectedSlotTime))
      );
      this.dateSlotsGroupedByTimeLocale = new CalendarScheduleDay(maskedSlots).getGroupedByTimeLocale(
        cutOffs.morning,
        cutOffs.afternoon
      );
    }
  }
}

const isCalendarDayOpen = (schedule: ICalendarSchedule, targetDate: DateTime, maskedStartTime: number): boolean => {
  const maskedSlotTimeEndsUtc = DateTime.utc().plus({ minutes: maskedStartTime });
  const daySchedule = schedule[targetDate.toISODate()];
  return (
    daySchedule?.isAnySlotAvailable &&
    daySchedule.slots.some(s => !s.isOccupied && s.startTimeUtc > maskedSlotTimeEndsUtc)
  );
};

export interface IScheduleSlotSelectorProps extends IUserEventSource, ISlotSelectorProps, IDisable {
  /** The schedule to be rendered to the user for slot selection. */
  schedule: ICalendarServiceSchedule;
}

export interface IDateSelectorProps extends IControl<Nullable<DateTime>>, IMutator<DateTime>, IUserEventSource {
  /** Callback which determines if a date should be disabled for selection. */
  shouldDisableDate: (date: DateTime) => boolean;
  /** The last date that should be selectable for the date selector (inclusive). */
  maxDate: DateTime;
  /** The first date that should be selectable for the date selector (inclusive). */
  minDate: DateTime;
  /** The time zone in which selected dates should be located. */
  timezone: IANAZone;
}

export interface ITimeLocaleSelectorProps
  extends IControl<Nullable<IScheduleSlot>>,
    IMutator<IScheduleSlot>,
    IOptions<IScheduleSlot>,
    IDisable {
  /** The form label for this selector, ex: Morning, Afternoon, Evening, etc. */
  label: string;
  /** The value which is currently attempting to be set. */
  loadingValue: Nullable<Pick<IScheduleSlot, "startTimeUtc">>;
}

export interface IScheduleSlotSelectorConfig {
  /** The number of milliseconds to wait before forcibly refreshing the available schedule selection. */
  refreshIntervalMs: number;
  /** The configuration which controls how times are grouped into morning, afternoon, and evening groupings. */
  timeSelectorCutOffs: ILocaleSelectionCutOffs;
  /** The date selector component to be used when the application is in desktop mode. */
  DesktopDateSelector: React.FC<IDateSelectorProps>;
  /** The date selector component to be used when the application is in mobile mode. */
  MobileDateSelector: React.FC<IDateSelectorProps>;
  /** The time selector component to be used. */
  TimeLocaleSelector: React.FC<ITimeLocaleSelectorProps>;
  /** The number of minutes into the future that slots starting within this interval should be concealed from the user
   * for booking.
   */
  slotSelectionMaskedMinutes: number;
}

/** A form element allowing the user to select an appointment slot in the given schedule. */
const ScheduleSlotSelector: React.FC<IScheduleSlotSelectorProps & IScheduleSlotSelectorConfig> = ({
  disabled = false,
  schedule,
  refreshIntervalMs,
  timeSelectorCutOffs,
  value,
  onChange,
  onEvent,
  DesktopDateSelector,
  MobileDateSelector,
  TimeLocaleSelector,
  slotSelectionMaskedMinutes
}) => {
  const showMobile = isMobileView();
  const scheduleRef = useRef(schedule);
  const valueRef = useRef(value);

  useEffect(() => {
    scheduleRef.current = schedule;
  }, [schedule]);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const [datePickerValue, setDatePickerValue] = useState(
    new ScheduleSlotSelectorState(
      null,
      schedule.days,
      timeSelectorCutOffs,
      slotSelectionMaskedMinutes,
      value?.startTimeUtc ?? null
    )
  );

  useInterval(
    () =>
      setDatePickerValue(
        new ScheduleSlotSelectorState(
          datePickerValue.date,
          scheduleRef.current.days,
          timeSelectorCutOffs,
          slotSelectionMaskedMinutes,
          valueRef.current?.startTimeUtc ?? null
        )
      ),
    refreshIntervalMs
  );

  const [loadingValue, setLoadingValue] = useState<Nullable<IScheduleSlot>>(null);

  useEffect(() => {
    if (value) setLoadingValue(null);
  }, [value]);

  useEffect(() => {
    const selectedDate = (value || schedule.getFirstAvailableSlot(slotSelectionMaskedMinutes))?.startTimeLocal.startOf(
      "day"
    );
    if (selectedDate)
      setDatePickerValue(
        new ScheduleSlotSelectorState(
          selectedDate,
          schedule.days,
          timeSelectorCutOffs,
          slotSelectionMaskedMinutes,
          value?.startTimeUtc ?? null
        )
      );
  }, []);

  const handleTimeChange = (slot: IScheduleSlot) => {
    onEvent(new UserEvent(UserEventType.Change, `time-button`, slot.startTimeUtc.toISO()));
    onChange(slot);
    setLoadingValue(slot);
  };

  const DateSelector = showMobile ? MobileDateSelector : DesktopDateSelector;
  return (
    <Grid container direction={showMobile ? "column" : "row"} spacing={1} wrap="nowrap" flexGrow={1}>
      <Grid item>
        <DateSelector
          value={datePickerValue.date}
          onChange={v =>
            setDatePickerValue(
              new ScheduleSlotSelectorState(
                v,
                schedule.days,
                timeSelectorCutOffs,
                slotSelectionMaskedMinutes,
                value?.startTimeUtc ?? null
              )
            )
          }
          shouldDisableDate={date => !isCalendarDayOpen(schedule.days, date, slotSelectionMaskedMinutes)}
          minDate={DateTime.now().setZone(schedule.timeZone).startOf("day")}
          maxDate={schedule.lastScheduleDateLocal}
          timezone={schedule.timeZone}
          onEvent={onEvent}
        />
      </Grid>
      <Grid item sm={6} xs>
        <Grid container direction="column" spacing={1} wrap="nowrap" sx={{ height: "100%" }}>
          <Grid item xs pb={4} pr={1.25}>
            <Stack spacing={2}>
              <TimeLocaleSelector
                disabled={disabled || Boolean(loadingValue)}
                label="Morning"
                loadingValue={loadingValue}
                value={value}
                options={datePickerValue.dateSlotsGroupedByTimeLocale?.morning || []}
                onChange={handleTimeChange}
              />
              <TimeLocaleSelector
                disabled={disabled || Boolean(loadingValue)}
                label="Afternoon"
                loadingValue={loadingValue}
                value={value}
                options={datePickerValue.dateSlotsGroupedByTimeLocale?.afternoon || []}
                onChange={handleTimeChange}
              />
              <TimeLocaleSelector
                disabled={disabled || Boolean(loadingValue)}
                label="Evening"
                loadingValue={loadingValue}
                value={value}
                options={datePickerValue.dateSlotsGroupedByTimeLocale?.evening || []}
                onChange={handleTimeChange}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleSlotSelector;
