import { DisqualifyingReason } from "./DisqualifyingReason";

/** An object representing the final result of a lead. */
export class LeadResult {
  /** The unique identifier of the result. */
  id?: string;
  /** The appointment id that was created for the lead (if any). */
  appointmentId?: string;
  /** The id of the customer associated with the lead. */
  customerId: string;
  /** The id of the opportunity associated with the lead. */
  opportunityId: string;
  /** The type enum indicating the disposition/circumstances of the result. */
  type: LeadResultType;
  /**
   * An object with keys of {@link DisqualifyingReason}.id and a value flag indicating if the reason is applied.
   * @see DisqualifyingReason
   */
  disqualifyingReasons: Record<string, boolean>;

  constructor(customerId: string, opportunityId: string, type: LeadResultType, appointmentId?: string) {
    this.appointmentId = appointmentId;
    this.customerId = customerId;
    this.opportunityId = opportunityId;
    this.type = type;
    this.disqualifyingReasons = {};
  }

  /** Modifies the provided leadResult by adding the provided disqualifying reason before returning the same object. */
  static AddDisqualifyingReason = (leadResult: LeadResult, disqualifyingReason: DisqualifyingReason): LeadResult => {
    leadResult.disqualifyingReasons = { ...leadResult.disqualifyingReasons, [disqualifyingReason.id]: true };
    return leadResult;
  };
}

export enum LeadResultType {
  Booked = 2,
  ObxNonCandidateMedical = 33,
  ObxNonCandidateBmi = 34
}
