import { IANAZone } from "luxon";
import { Nullable } from "sonobello.utilities.react";

import { DefaultCenter } from "./ISession";

/** A mapping of a time zone name in the 'TZ' format to the 'Zone Id' (aka IANA) format supported by javascript. */
const TzTimezoneToZoneIdMap = {
  "Pacific Daylight Time": "America/Los_Angeles",
  "Pacific Standard Time": "America/Los_Angeles",
  "Mountain Daylight Time": "America/Denver",
  "Mountain Standard Time": "America/Denver",
  "US Mountain Standard Time": "America/Phoenix",
  "Central Daylight Time": "America/Chicago",
  "Central Standard Time": "America/Chicago",
  "Eastern Daylight Time": "America/New_York",
  "Eastern Standard Time": "America/New_York"
};

/** The name of a time zone in the 'TZ' format used by .NET.*/
export type TimeZoneStandardName = keyof typeof TzTimezoneToZoneIdMap;

/** The interface for a center returned from an API request. */
interface ICenterResponse {
  /** The unique identifier of the center. */
  id: string;
  /** The estimated distance from the customer to the center. */
  distance: Nullable<number>;
  /** The plain text name of the center. */
  name: string;
  /** The plain text information of the center's address. */
  address: string;
  /** The phone number used to contact the center regarding appointment details or concerns. */
  phoneNumber: string;
  /** The center's time zone. */
  timeZone: {
    /** The name of the center's time zone in the 'TZ' format. */
    currentName: TimeZoneStandardName;
  };
}

/** Builds a {@link DefaultCenter} from a {@link ICenterResponse}. */
export class ResponseDefaultCenter extends DefaultCenter {
  constructor(props: ICenterResponse) {
    const ianaZone = new IANAZone(TzTimezoneToZoneIdMap[props.timeZone.currentName]);
    if (!ianaZone.isValid)
      throw `Failed to convert the 'TZ' time zone name '${props.timeZone.currentName}' to an IANA time zone.`;
    super(
      props.id,
      props.distance === null || props.distance === undefined ? null : Number(props.distance!.toFixed(2)),
      props.name,
      props.address,
      props.phoneNumber,
      ianaZone
    );
  }
}

export default ICenterResponse;
