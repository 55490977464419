import { BrowserInfo } from "detect-browser";

/** A record for the device and browser used to interact with OBX. */
export class ObxLeadDevice {
  /** The lead id associated with the obx session. */
  leadId: string;
  /** The name of the device operating system associated with this local session. */
  device?: string;
  /** The browser which is being used in this local session. */
  browser?: string;
  /** The browser version which is being used in this local session. */
  browserVersion?: string;

  constructor(leadId: string, browserInfo: BrowserInfo) {
    this.leadId = leadId;
    this.device = browserInfo?.os || undefined;
    this.browser = browserInfo?.name;
    this.browserVersion = browserInfo?.version;
  }
}
