import { DateTime, IANAZone } from "luxon";

import { LocalStorageConfigs, saveToLocal } from "../../utils/LocalStorage";
import CalendarSlotStatus from "../Calendar/Types/CalendarSlotStatus";
import { ICachedScheduleSlot, ICalendarService } from "../Calendar/Types/ICalendar";
import { OpsScheduleSlot } from "../Calendar/Types/OpsCalendar";
import ICenter, { Center } from "./ICenter";
import { BaseReservation, ICachedReservationCenter } from "./IReservation";

interface ICachedOpsReservationSlot extends ICachedScheduleSlot {
  /** The unique identifier of the slot in the OPS system. */
  readonly id: number;
}

/** A primitive representation of a booking reservation in the OPS system cached to the browser. */
export interface ICachedOpsReservation {
  /** {@inheritdoc ICachedReservationCenter} */
  center: ICachedReservationCenter;
  /** The cached slot of the reservation for the OPS process. */
  slot: ICachedOpsReservationSlot;
  /** {@inheritdoc IReservation.expirationUtc} */
  expirationUtc: string;
  /** {@inheritdoc IReservation.service} */
  service: ICalendarService;
}

/** An {@link OpsScheduleSlot} restored from the browser cache. */
export class CachedOpsScheduleSlot extends OpsScheduleSlot {
  constructor(cachedSlot: ICachedOpsReservationSlot) {
    const zone = new IANAZone(cachedSlot.timeZoneName);
    if (!zone.isValid) throw new Error("Invalid IANA zone name.");
    const startDateTime = DateTime.fromISO(cachedSlot.startTimeUtcIso, { zone });
    const endDateTime = DateTime.fromISO(cachedSlot.endTimeUtcIso, { zone });
    super(
      {
        id: cachedSlot.id,
        startTime: startDateTime.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
        endTime: endDateTime.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
        type: cachedSlot.isOccupied ? CalendarSlotStatus.Blocked : CalendarSlotStatus.Open
      },
      startDateTime.toISODate(),
      zone
    );
  }
}

/** A {@link BaseReservation} with additional OPS specific context data. */
class OpsReservation extends BaseReservation {
  /** {@inheritdoc Reservation.slot} */
  readonly slot: OpsScheduleSlot;

  constructor(center: ICenter, service: ICalendarService, slot: OpsScheduleSlot, expiresUtc?: DateTime) {
    super(center, slot, service, expiresUtc);
    this.slot = slot;
  }

  /** {@inheritdoc ICached} */
  override cache = () => {
    const cachedReservation: ICachedOpsReservation = {
      center: this.center.toPrimitive(),
      slot: {
        id: this.slot.id,
        startTimeUtcIso: this.slot.startTimeUtc.toISO(),
        endTimeUtcIso: this.slot.endTimeUtc.toISO(),
        timeZoneName: this.slot.startTimeLocal.zone.name,
        isOccupied: this.slot.isOccupied
      },
      expirationUtc: this.expirationUtc.toISO(),
      service: this.service
    };
    saveToLocal(cachedReservation, LocalStorageConfigs.opsReservation.key);
  };

  /** {@inheritdoc IVolatileCache.unsetCache} */
  override unsetCache = () => localStorage.removeItem(LocalStorageConfigs.opsReservation.key);
}

export class CachedOpsReservation extends OpsReservation {
  constructor(cachedReservation: ICachedOpsReservation) {
    const zone = new IANAZone(cachedReservation.center.timeZoneName);
    if (!zone.isValid) throw new Error("Invalid IANA zone name.");
    super(
      new Center(
        cachedReservation.center.id,
        cachedReservation.center.distance,
        cachedReservation.center.name,
        cachedReservation.center.address,
        cachedReservation.center.phoneNumber,
        zone
      ),
      cachedReservation.service,
      new CachedOpsScheduleSlot(cachedReservation.slot),
      DateTime.fromISO(cachedReservation.expirationUtc).toUTC()
    );
  }
}

export default OpsReservation;
