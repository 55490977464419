import { DateTime } from "luxon";

/** An object representing the authorization information necessary to transact with the server. */
export class Token {
  /** The bearer token to be used to authorize all transactions with the OBX client server. */
  token!: string;
  /** The ISO DateTime that the token will expire. */
  tokenExpires!: string;
  /** The refresh token value used to refresh the auth token. */
  refresh!: string;
  /** The ISO DateTime that the refresh token will expire. */
  refreshExpires!: string;
}

export class EnhancedToken extends Token {
  /** The Luxon DateTime that the token will expire at. */
  tokenExpiresDateTime: DateTime;
  /** THe Luxon DateTime that the refresh token will expire at. */
  refreshExpiresDateTime: DateTime;

  constructor(token: Token) {
    super();
    this.token = token.token;
    this.tokenExpires = token.tokenExpires;
    this.refresh = token.refresh;
    this.refreshExpires = token.refreshExpires;

    this.tokenExpiresDateTime = DateTime.fromISO(token.tokenExpires);
    this.refreshExpiresDateTime = DateTime.fromISO(token.refreshExpires);
  }

  /** Convert the Enhanced Token back to its base class. */
  static ToBase = (eht: EnhancedToken): Token => {
    const { token, tokenExpires, refresh, refreshExpires } = eht;
    return { token, tokenExpires, refresh, refreshExpires };
  };
}
