import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { SBSizes } from "sonobello.utilities.react.mui";

import ICenter from "../../Types/ICenter";

export const classNames = {
  centerName: "consultInfoCenterName",
  centerAddress1: "consultInfoCenterAddress1",
  centerAddress2: "consultInfoCenterAddress2",
  centerPhoneNumber: "consultInfoCenterPhoneNumber",
  appointmentDate: "consultInfoAppointmentDate",
  startTime: "consultInfoStartTime",
  endTime: "consultInfoEndTime"
};

export interface IConsultInfoProps {
  /** The details of the center in which the appointment will occur. */
  center: Pick<ICenter, "addressParts" | "name" | "phoneNumber">;
  /** The time at which the appointment is scheduled to begin. */
  startDateTime: DateTime;
  /** The time at which the appointment is scheduled to end. */
  endDateTime: DateTime;
}

/** Displays the location and time and details for a reservation. */
const ConsultInfo: React.FC<IConsultInfoProps> = ({ center, endDateTime, startDateTime }) => {
  const { streetAddress, cityStateZip } = center.addressParts;
  return (
    <Stack spacing={2}>
      <div>
        <Stack direction="row" spacing={2}>
          <Box display="flex" alignItems="center">
            <LocationOnIcon sx={{ fontSize: SBSizes.icon }} />
          </Box>
          <Stack>
            <Typography variant="h6">
              My Center: <span className={classNames.centerName}>{center.name}</span>
            </Typography>
            <div>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <Typography className={classNames.centerAddress1} variant="body2">
                    {streetAddress}
                  </Typography>
                </Grid>
                {cityStateZip && (
                  <Grid item>
                    <Typography className={classNames.centerAddress2} variant="body2">
                      {cityStateZip}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </div>
            <Typography className={classNames.centerPhoneNumber} variant="body2">
              {center.phoneNumber}
            </Typography>
          </Stack>
        </Stack>
      </div>
      <div>
        <Stack direction="row" spacing={2}>
          <Box display="flex" alignItems="center">
            <EventIcon sx={{ fontSize: SBSizes.icon }} />
          </Box>
          <Stack>
            <Typography variant="h6">Consultation Information</Typography>
            <Typography className={classNames.appointmentDate} variant="body2">
              {startDateTime.toLocaleString(DateTime.DATE_FULL)}
            </Typography>
            <Typography variant="body2">
              Start Time:{" "}
              <span className={classNames.startTime}>{startDateTime.toLocaleString(DateTime.TIME_SIMPLE)}</span>
            </Typography>
            <Typography variant="body2">
              End Time: <span className={classNames.endTime}>{endDateTime.toLocaleString(DateTime.TIME_SIMPLE)}</span>
            </Typography>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
};

export default ConsultInfo;
