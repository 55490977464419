import { DateTime } from "luxon";

import { buildNote } from "../../../../types/Notes";
import { ICalendarService } from "../../../Calendar/Types/ICalendar";
import { IAppointmentNoteProps, appointmentNoteTagsConfig } from "./IAppointment";

/** An appointment request for the OPS booking process. */
export interface IOpsAppointmentRequest {
  /** The id of the center where the appointment is scheduled. */
  readonly centerId: string;
  /** The id of the customer for which the appointment is scheduled. */
  readonly customerId: string;
  /** The id of the lead which resulted in the appointment. */
  readonly leadId: string;
  /** The services which will be conducted during the appointment. */
  readonly services: ICalendarService[];
  /** The ISO start time of the appointment in UTC. */
  readonly startTimeUtc: string;
  /** The ISO end time of the appointment in UTC. */
  readonly endTimeUtc: string;
  /** Patient specific notes for the appointment. */
  readonly note: string;
  /** The unique id of the slot in the OPS system. */
  readonly slotId: number;
}

/** {@inheritdoc IOpsAppointmentRequest} */
class OpsAppointment implements IOpsAppointmentRequest {
  readonly centerId: string;
  readonly customerId: string;
  readonly leadId: string;
  readonly services: ICalendarService[];
  readonly startTimeUtc: string;
  readonly endTimeUtc: string;
  readonly note: string;
  readonly slotId: number;

  constructor(
    centerId: string,
    customerId: string,
    leadId: string,
    service: ICalendarService,
    startTimeUtc: DateTime,
    endTimeUtc: DateTime,
    noteTagProps: IAppointmentNoteProps,
    slotId: number
  ) {
    this.centerId = centerId;
    this.customerId = customerId;
    this.leadId = leadId;
    this.services = [service];
    this.startTimeUtc = startTimeUtc.toISO();
    this.endTimeUtc = endTimeUtc.toISO();
    this.note = buildNote(appointmentNoteTagsConfig, noteTagProps);
    this.slotId = slotId;
  }
}

export default OpsAppointment;
