/** The type of a calendar request slot. */
export enum CalendarRequestSlotType {
  /** This block is available for booking a new appointment. */
  Open = 0,
  /** The slot is occupied and cannot accept a new appointment. */
  Blocked = 3
}

/** The service which will be conducted for an appointment booked on an the associated
 * {@link ILegacyCalendarRequestSchedule}.
 */
export interface ICalendarRequestService {
  /** The id of the service. */
  id: string;
  /** The id of the service in the external CRM. */
  externalId: string;
  /** The plain text name of the service. */
  name: string;
  /** The name of the service's sub-category. */
  serviceSubCategoryName: string;
  /** The business unit to which the service belongs. */
  businessUnit: {
    /** The id of the business unit to which the service belongs. */
    id: string;
    /** The plain text name of the business unit to which the service belongs. */
    name: string;
  };
}

/** The definition of a slot on the service schedule for which an appointment might be booked in the legacy booking process. */
export interface ILegacyCalendarRequestSlot {
  /** The start time of the block in utc. */
  startTimeUtc: string;
  /** The end time of the block in utc. */
  endTimeUtc: string;
  /** The state of block being described by this object. */
  type: CalendarRequestSlotType;
}

/** The schedule for a specific calendar service for the legacy booking process.
 * This is a dictionary of time slots keyed by the slots ISO UTC date.
 */
export type ILegacyCalendarRequestSchedule = Record<string, ILegacyCalendarRequestSlot[] | undefined>;

/** A legacy calendar's service paired with the scheudule of slots which can be booked for it. */
export interface ILegacyCalendarRequestServiceSchedule {
  /** The service for this schedule. */
  service: ICalendarRequestService;
  /** The slot schedule for the paired service. */
  days: ILegacyCalendarRequestSchedule;
}

/** The response from the OBX API to a request for a calendar. */
interface ILegacyCalendarResponse {
  /** The list of service schedules for the calendar. */
  schedules: ILegacyCalendarRequestServiceSchedule[];
}

export default ILegacyCalendarResponse;
