import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { BearerTokenProvider } from "sonobello.utilities.react.axios";
import { Nullable, Optional } from "sonobello.utilities.react.mui";

import { EnhancedCustomer } from "./dtos/Customer";
import { DisqualifyingReason } from "./dtos/DisqualifyingReason";
import { LeadResult } from "./dtos/LeadResult";
import { EnhancedToken, Token } from "./dtos/Token";
import { Flow } from "./types/Flow";
import { MedicalForm } from "./types/MedicalForm";
import { ValidationProgress } from "./types/ValidationProgress";
import { ViewConnector } from "./types/ViewConnector";
import { LocalStorageConfigs, saveToLocal } from "./utils/LocalStorage";
import SupportedFlows from "./V2/App/Compositions/SupportedFlows";
import IBookingController from "./V2/Calendar/Types/IBookingController";
import useGetFlow from "./V2/Experiment/Hooks/UseGetFlow";
import IFlowResolver from "./V2/Experiment/Types/IFlowResolver";
import NameMatchFlowResolver from "./V2/Experiment/Types/NameMatchFlowResolver";
import IPathObserver from "./V2/Routing/Types/IPathObserver";
import ICenter from "./V2/Types/ICenter";
import IReservation from "./V2/Types/IReservation";
import ISession from "./V2/Types/ISession";

export interface AppContextProps extends IPathObserver {
  /** The token provider to be used for all authorized useApi calls. */
  bearerTokenProvider: BearerTokenProvider;
  /** {@inheritdoc IBookingController} */
  bookingController: Nullable<IBookingController>;
  /** Personal information about the current customer. */
  customerCState: EnhancedCustomer;
  /** The current contact validation state held by the user. */
  contactInfoValidatedCState: ValidationProgress;
  /** The default center to be used to book appointments. */
  defaultCenter: ICenter;
  /** The reasons for which the current user may be considered a non-candidate. */
  disqualifyingReasonsCState?: DisqualifyingReason[];
  /** The unique identifier of the lead associated with the current OBX session. */
  leadIdCState: string;
  /** The lead result for the session. */
  leadResultCState: Nullable<LeadResult>;
  /** The current flow that is assigned to the session. */
  flowCState: Flow;
  /** The unique identifier of the flow associated with the current OBX session. */
  flowIdCState: string;
  /** A flag indicating if the device information for this local instance has been recorded. */
  isDeviceLoggedCState: boolean;
  /** The statue of the medical form answers given by the user. */
  medicalFormCState: MedicalForm;
  /** The authorization token that should be refreshed. */
  refreshTokenCState?: Token;
  /** The current appointment reservation held by the client. */
  reservation: Nullable<IReservation>;
  /** The router which allows child components to change the route of the application. */
  router: ViewConnector;
  /** The url link key value which identifies the OBX session. */
  sessionKeyCState: string;
  /** The security token containing keys used to authorize requests to the backend. */
  tokenCState?: EnhancedToken;
  /** State setter for the currently selected center's calendar. */
  setBookingController: Dispatch<SetStateAction<Nullable<IBookingController>>>;
  /** State setter for the application customer. */
  setCustomerCState: Dispatch<SetStateAction<EnhancedCustomer>>;
  /** State setter for the contact validation state. */
  setContactValidationCState: Dispatch<SetStateAction<ValidationProgress>>;
  /** State setter for the disqualifying reasons state. */
  setDisqualifyingReasonsCState: Dispatch<SetStateAction<Optional<DisqualifyingReason[]>>>;
  /** State setter for the device info recorded flag. */
  setIsDeviceLoggedCState: Dispatch<SetStateAction<boolean>>;
  /** State setter for the session's lead id. */
  setLeadIdCState: Dispatch<SetStateAction<string>>;
  /** State setter for the lead result. */
  setLeadResultCState: Dispatch<LeadResult>;
  /** State setter for the session's flow id. */
  setFlowIdCState: Dispatch<SetStateAction<string>>;
  /** State setter for the medical form. */
  setMedicalFormCState: Dispatch<SetStateAction<MedicalForm>>;
  /** State setter for the session's appointment reservation. */
  setReservation: Dispatch<SetStateAction<Nullable<IReservation>>>;
  /** State setter for the session's authorization token. */
  setTokenCState: Dispatch<SetStateAction<Optional<EnhancedToken>>>;
}

interface IAppContextProviderProps extends IPathObserver {
  /** The optional list of resolvers to use to identify the flow experienced by the user. */
  flowResolvers?: IFlowResolver[];
  /** The initial state of the session data that should be provided to the children of the provider. */
  session: ISession;
  /** The connector which allows for the alteration of the user's route. */
  viewConnector: ViewConnector;
}
const ProtectedAppContextProvider = ({
  flowResolvers,
  pathName,
  session,
  viewConnector,
  children
}: PropsWithChildren<IAppContextProviderProps>): JSX.Element => {
  // Center
  const [defaultCenter] = useState(session.defaultCenter);
  useEffect(() => defaultCenter.cache(), [defaultCenter]);

  // Contact Info Validated
  const [contactInfoValidatedCState, setContactValidationCState] = useState(ValidationProgress.Unvalidated);

  // Booking Schedule
  const [bookingController, setBookingController] = useState<Nullable<IBookingController>>(null);

  // Customer
  const [customerCState, setCustomerCState] = useState(session.customer);
  useEffect(() => {
    if (customerCState.note) saveToLocal(customerCState.note, LocalStorageConfigs.customerNote.key);
    else localStorage.removeItem(LocalStorageConfigs.customerNote.key);
    saveToLocal(customerCState, LocalStorageConfigs.customer.key);
  }, [customerCState]);

  // Device Info Recorded Flag
  const [isDeviceLoggedCState, setIsDeviceLoggedCState] = useState(session.isDeviceLogged);
  useEffect(() => saveToLocal(isDeviceLoggedCState, LocalStorageConfigs.isDeviceLogged.key), [customerCState]);

  const [disqualifyingReasonsCState, setDisqualifyingReasonsCState] = useState(session.disqualifyingReasons);
  useEffect(
    () => saveToLocal(disqualifyingReasonsCState, LocalStorageConfigs.disqualifyingReasons.key),
    [disqualifyingReasonsCState]
  );

  // Lead Id
  const [leadIdCState, setLeadIdCState] = useState(session.leadId);
  useEffect(() => localStorage.setItem(LocalStorageConfigs.leadId.key, leadIdCState), [leadIdCState]);

  // Lead Result
  const [leadResultCState, setLeadResultCState] = useState(session.leadResult);
  useEffect(() => saveToLocal(leadResultCState, LocalStorageConfigs.leadResult.key), [leadResultCState]);

  // Flow Id
  const [flowIdCState, setFlowIdCState] = useState(session.flowId);
  useEffect(() => localStorage.setItem(LocalStorageConfigs.flowId.key, flowIdCState), [flowIdCState]);

  // Flow
  const { result: flowCState } = useGetFlow({
    flowResolvers: flowResolvers || [new NameMatchFlowResolver(SupportedFlows)],
    sessionFlowName: session.flowName
  });

  if (!flowCState) throw new Error("Flow not found.");

  // Medical Form
  const [medicalFormCState, setMedicalFormCState] = useState(session.medicalForm || new MedicalForm());
  useEffect(() => saveToLocal(medicalFormCState, LocalStorageConfigs.medicalForm.key), [medicalFormCState]);

  // Authorization Token
  const [tokenCState, setTokenCState] = useState<Optional<EnhancedToken>>(session.token);
  const tokenRef = useRef(tokenCState);
  const [refreshTokenCState, setRefreshTokenCState] = useState<Token>();
  const refreshToken = useCallback(
    () => setRefreshTokenCState(t => (t?.token !== tokenRef.current?.token ? tokenRef.current : t)),
    []
  );
  const [bearerTokenProvider] = useState(new BearerTokenProvider(tokenCState?.token || "", refreshToken));
  useEffect(() => {
    tokenRef.current = tokenCState;
    if (tokenCState) {
      saveToLocal(EnhancedToken.ToBase(tokenCState), LocalStorageConfigs.token.key);
      viewConnector.setToken(tokenCState.token);
      bearerTokenProvider.updateTokenAndRetryUnauthorizedRequests(tokenCState.token);
    }
  }, [tokenCState]);

  const [reservation, setReservation] = useState(session.reservation);
  const reservationRef = useRef(reservation);
  useEffect(() => {
    if (reservation) reservation.cache();
    else if (reservationRef.current) reservationRef.current.unsetCache();
    reservationRef.current = reservation;
  }, [reservation]);

  // Url Session Key
  const [sessionKeyCState] = useState(session.key);
  useEffect(() => {
    if (sessionKeyCState) localStorage.setItem(LocalStorageConfigs.sessionKey.key, sessionKeyCState);
  }, [sessionKeyCState]);

  useEffect(() => {
    localStorage.setItem(LocalStorageConfigs.flow.key, flowCState.name);
    viewConnector.setFlowId(flowIdCState);
    viewConnector.setLeadId(leadIdCState);
  }, []);

  useEffect(() => localStorage.setItem(LocalStorageConfigs.version.key, session.version!), [session.version]);

  return (
    <AppContext.Provider
      value={{
        bearerTokenProvider,
        bookingController,
        contactInfoValidatedCState,
        customerCState,
        defaultCenter,
        disqualifyingReasonsCState,
        flowCState,
        flowIdCState,
        isDeviceLoggedCState,
        leadIdCState,
        leadResultCState,
        medicalFormCState,
        pathName,
        refreshTokenCState,
        reservation,
        router: viewConnector,
        sessionKeyCState,
        tokenCState,
        setIsDeviceLoggedCState,
        setBookingController,
        setContactValidationCState,
        setCustomerCState,
        setDisqualifyingReasonsCState,
        setLeadIdCState,
        setLeadResultCState,
        setFlowIdCState,
        setMedicalFormCState,
        setReservation,
        setTokenCState
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const AppContextProvider = ({
  session,
  viewConnector,
  ...rest
}: PropsWithChildren<IAppContextProviderProps>): JSX.Element => {
  if (!session) viewConnector.goToInvalidLinkView();
  return session ? <ProtectedAppContextProvider session={session} viewConnector={viewConnector} {...rest} /> : <></>;
};

const AppContext = createContext({} as AppContextProps);

export default AppContext;
