import { Customer } from "../../../../dtos/Customer";
import { Token } from "../../../../dtos/Token";
import ICenterResponse from "../../../Types/ICenterResponse";

/** The object payload used to make a POST token request. */
export class TokenRequest {
  /** The key of the obx session from the unique OBX uri. */
  obxLinkValue: string;

  constructor(sessionKey: string) {
    this.obxLinkValue = sessionKey;
  }
}

/** The object payload on a POST token request response. */
export interface TokenResponse {
  /** The customer associated with the token request. */
  customer: Omit<Customer, "height" | "weight" | "dateOfBirth">;
  /** The center associated with the token request. */
  center: ICenterResponse;
  /** The lead id associated with the token request. */
  leadId: string;
  /** The flow id associated with the token request. */
  flow: {
    /** The id of the flow associated with the token request. */
    id: string;
    /** The flow name associated with the token request. */
    name: string;
  };
  /** The token authorizing future requests for this session. */
  token: Token;
}
