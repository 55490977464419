import { Box, StandardTextFieldProps, styled } from "@mui/material";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { Optional, UserEvent, UserEventContext, ValidatedTextField } from "sonobello.utilities.react.mui";
import { UserEventType } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEvent";

import AppContext from "../../../AppContext";
import { Customer } from "../../../dtos/Customer";
import { isMobileView } from "../../../utils/Constants";
import DateOfBirthPicker from "../../../V2/Steps/Qualify/Components/DateOfBirthPicker";

const minDatePickerValue = DateTime.now().minus({ years: 120 });

class FormData {
  weight: Customer["weight"];
  height: Partial<Customer["height"]>;
  dateOfBirth?: DateTime;

  constructor(customer?: Customer) {
    this.height = customer?.height || { feet: undefined, inches: undefined };
    this.weight = customer?.weight;
    this.dateOfBirth = customer?.dateOfBirth ? DateTime.fromISO(customer.dateOfBirth) : undefined;
  }

  static getValidHeight = (formData: FormData): Customer["height"] => {
    if (!formData.height) return undefined;
    if (!formData.height.feet || formData.height.inches === undefined) return undefined;
    if (isNaN(formData.height.feet) || isNaN(formData.height.inches)) return undefined;
    return { ...formData.height } as Required<Customer["height"]>;
  };
  static getValidWeight = (formData: FormData): Customer["weight"] => {
    if (formData.weight === undefined || isNaN(formData.weight)) return undefined;
    return formData.weight;
  };
  static getValidDob = (formData: FormData): Customer["dateOfBirth"] => {
    if (!formData.dateOfBirth?.isValid || formData.dateOfBirth < minDatePickerValue) return undefined;
    return formData.dateOfBirth.toISODate();
  };
}

interface QualifyingInfoFormProps {
  isLoading: boolean;
}

const getAsNumber = (value?: string): Optional<number> => (isNaN(Number(value)) ? undefined : Number(value));

/** Form for modifying customer qualifying information. */
const QualifyingInfoForm: React.FC<QualifyingInfoFormProps> = ({ isLoading }) => {
  const showMobile = isMobileView();
  const { customerCState, setCustomerCState } = useContext(AppContext);
  const { onEvent } = useContext(UserEventContext);
  const [formData, setFormData] = useState(new FormData(customerCState));
  const getFormDataSetter =
    (fieldId: string, updater: (value?: string) => void) =>
    (value?: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onEvent(new UserEvent(UserEventType.Change, fieldId, value as string));
      updater(value as string);
    };
  const handleDateChange = (value: DateTime | null) => {
    onEvent(
      new UserEvent(
        UserEventType.Change,
        "qualifyStep_weightField",
        value ? (value.isValid ? value.toISO() : "invalid value") : value
      )
    );
    setFormData(d => ({ ...d, dateOfBirth: value || undefined }));
  };
  useEffect(
    () =>
      setCustomerCState(c => ({
        ...c,
        dateOfBirth: FormData.getValidDob(formData),
        height: FormData.getValidHeight(formData),
        weight: FormData.getValidWeight(formData)
      })),
    [formData]
  );
  return (
    <>
      <QualifyField
        inputProps={{
          type: "tel"
        }}
        id="qualifyStep_heightFeetField"
        label="Height Feet"
        variant="standard"
        value={formData.height?.feet ?? ""}
        onChange={getFormDataSetter("qualifyStep_heightFeetField", value =>
          setFormData(c => ({ ...c, height: { ...c.height, feet: getAsNumber(value as string) } }))
        )}
        rejectInput={input => !/^\d{0,1}$/.test(input) || Number(input) > 8 || input === "0"}
        disabled={isLoading}
        isMobile={showMobile}
      />
      <QualifyField
        inputProps={{
          type: "tel"
        }}
        id="qualifyStep_heightInchesField"
        label="Height Inches"
        variant="standard"
        value={formData.height?.inches ?? ""}
        onChange={getFormDataSetter("qualifyStep_heightInchesField", value =>
          setFormData(c => ({
            ...c,
            height: { ...c.height, inches: getAsNumber(value as string) }
          }))
        )}
        rejectInput={input => !/^\d{0,2}$/.test(input) || Number(input) > 11}
        disabled={isLoading}
        isMobile={showMobile}
      />
      <QualifyField
        inputProps={{
          type: "tel"
        }}
        id="qualifyStep_weightField"
        label="Weight (lbs)"
        variant="standard"
        value={formData.weight ?? ""}
        onChange={getFormDataSetter("qualifyStep_weightField", value =>
          setFormData(c => ({ ...c, weight: getAsNumber(value as string) }))
        )}
        isMobile={showMobile}
        rejectInput={input => !/^\d{0,3}$/.test(input)}
        disabled={isLoading}
        fullWidth={false}
      />
      <Box pt={1}>
        <DateOfBirthPicker
          startDate={minDatePickerValue}
          value={formData.dateOfBirth || null}
          onChange={handleDateChange}
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

interface QualifyFieldProps extends StandardTextFieldProps {
  isMobile: boolean;
}

const QualifyField = styled(ValidatedTextField, { shouldForwardProp: prop => prop !== "isMobile" })<QualifyFieldProps>(
  ({ isMobile }) => ({
    maxWidth: isMobile ? undefined : "10rem"
  })
);

export default QualifyingInfoForm;
