import { useCallback, useContext, useEffect, useState } from "react";

import AppContext from "../../../AppContext";
import { Customer } from "../../../dtos/Customer";
import { LeadResult, LeadResultType } from "../../../dtos/LeadResult";
import { Step } from "../../../types/Step";
import useObx from "../../../utils/UseObx";
import { StepSetter } from "../../../V2/App/Components/StepProvider";
import ApiRequestPaths from "../../../V2/Constants/ApiRequestPaths";
import OpsUtilities from "../../../V2/Constants/OpsUtility";

interface UsePostCustomerProps {
  setStep: StepSetter;
}

/** Component which contains the content for selecting the consult center for the here. */
const usePostCustomer = ({ setStep }: UsePostCustomerProps) => {
  const { customerCState, leadIdCState, setLeadResultCState } = useContext(AppContext);
  const [postLeadResultPayload, setPostLeadResultPayload] = useState<LeadResult>();

  const {
    res: leadResultResponse,
    err: leadResultError,
    loading: leadResultLoading,
    setReq: setLeadResultRequest
  } = useObx<LeadResult, LeadResult>("Post LeadResult", {
    method: "post",
    url: `leads/${leadIdCState}/result`
  });

  const {
    res: customerResponse,
    err: customerError,
    loading: customerLoading,
    setReq: setCustomerRequest
  } = useObx<Customer, Customer>("Put Customer", {
    method: "put",
    url: OpsUtilities.isCenterSupported(customerCState.centerId)
      ? ApiRequestPaths.putOpsCustomerUrl(leadIdCState, customerCState.id)
      : ApiRequestPaths.putLegacyCustomerUrl(leadIdCState, customerCState.id)
  });

  useEffect(() => {
    if (!customerResponse && !customerError) return;
    if (postLeadResultPayload) setLeadResultRequest(r => r && { ...r, payload: postLeadResultPayload });
    else setStep(Step.medicalPartOne);
  }, [customerResponse, customerError, postLeadResultPayload]);

  useEffect(() => {
    if (!leadResultResponse && !leadResultError) return;
    setLeadResultCState(
      leadResultResponse?.payload ||
        new LeadResult(customerCState.id, customerCState.opportunity.id, LeadResultType.ObxNonCandidateBmi)
    );
  }, [leadResultResponse, leadResultError, customerCState]);

  const updateCustomerAndLeadResult = useCallback((putCustomerPayload: Customer, postLeadResultPayload: LeadResult) => {
    setCustomerRequest(
      r =>
        r && {
          ...r,
          payload: {
            ...putCustomerPayload,
            bmi: Customer.getBmi(putCustomerPayload)
          }
        }
    );
    setPostLeadResultPayload(postLeadResultPayload);
  }, []);

  const updateCustomer = useCallback(
    (putCustomerPayload: Customer) =>
      setCustomerRequest(
        r =>
          r && {
            ...r,
            payload: {
              ...putCustomerPayload,
              bmi: Customer.getBmi(putCustomerPayload)
            }
          }
      ),
    []
  );

  return {
    updateCustomerAndLeadResult,
    updateCustomer,
    loading: leadResultLoading || customerLoading
  };
};

export default usePostCustomer;
