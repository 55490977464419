import { ComponentProps } from "react";
import { CachedStateProvider, IControlledMutator } from "sonobello.utilities.react";

import { LocalStorageConfigs } from "../../../utils/LocalStorage";
import AppContextValueProvider from "../../App/Compositions/AppContextValueProvider";
import OpsCalendarController, {
  ICalendarPrerequisitesRecord,
  IOpsCalendarControllerProps
} from "../Components/OpsCalendarController";

/** A {@link OpsCalendarController} which reads and writes its control to the cache. */
const CachedOpsCalendarController: React.FC<
  Omit<ComponentProps<typeof OpsCalendarController>, keyof IControlledMutator<unknown>>
> = props =>
  CachedStateProvider<ICalendarPrerequisitesRecord, ComponentProps<typeof OpsCalendarController>>({
    ...props,
    Component: OpsCalendarController,
    localStoreKey: LocalStorageConfigs.calendarPrerequisitesMet.key
  });

/** A fully provisioned {@link OpsCalendarController}, except for its `children`. */
const AppContextOpsCalendarController = AppContextValueProvider<
  Pick<IOpsCalendarControllerProps, "customer">,
  ComponentProps<typeof CachedOpsCalendarController>
>({
  Component: CachedOpsCalendarController,
  map: ({ customerCState }) => ({ customer: customerCState })
});

export default AppContextOpsCalendarController;
