import React, { PropsWithChildren, useEffect } from "react";
import { IControlledMutator, Nullable } from "sonobello.utilities.react";

import { Customer } from "../../../dtos/Customer";
import { GetCurrentStep, Step } from "../../../types/Step";
import IPathObserver from "../../Routing/Types/IPathObserver";

export interface ICalendarPrerequisitesRecord {
  /** Flag indicating if the prerequisites to obtain a booking calendar are met. */
  isCalendarPrerequisiteMet: boolean;
}

export interface IOpsCalendarControllerProps
  extends IControlledMutator<Nullable<ICalendarPrerequisitesRecord>>,
    IPathObserver {
  /** The props of {@link Customer} that are required to determine if the app can allow booking. */
  customer: Pick<Customer, "id" | "isPreviouslyQualified">;
}

/** Suspends rendering children until the prerequisites for the calendar are met.
 * @remarks The prerequisites for accurate OPS calendar acquisition are knowing that a guest exists and has submitted
 * their medical information. We use the 'current step' as a alternate means of asserting this is complete.
 */
const OpsCalendarController: React.FC<PropsWithChildren<IOpsCalendarControllerProps>> = ({
  customer,
  pathName,
  value,
  onChange,
  children
}) => {
  useEffect(() => {
    const currentStep = GetCurrentStep(pathName);
    if (!value?.isCalendarPrerequisiteMet) {
      onChange({
        isCalendarPrerequisiteMet: Boolean(
          (customer.id && (currentStep === Step.scheduling || currentStep === Step.medicalPartOne)) ||
            customer.isPreviouslyQualified
        )
      });
    }
  }, [customer.id, customer.isPreviouslyQualified, pathName, value]);

  return value?.isCalendarPrerequisiteMet ? <>{children}</> : null;
};

export default OpsCalendarController;
