import React, { DispatchWithoutAction, useState } from "react";
import { IControlledMutator, IDisable, IOptions } from "sonobello.utilities.react";

import { IFlowStepProps } from "../../../../types/Flow";
import { Step } from "../../../../types/Step";
import { ICalendarService } from "../../../Calendar/Types/ICalendar";
import IBookableCenter from "../../../Types/IBookableCenter";
import StepWrapper, { IStepWrapperProps } from "../../Components/StepWrapper";

export interface ICenterServiceSelectorProps
  extends IControlledMutator<ICalendarService>,
    IOptions<ICalendarService>,
    IDisable {
  /** Callback to allow the selector to self-set itself as disabled. */
  setDisabled: DispatchWithoutAction;
}

export interface ICenterSelectValue {
  /** The center which the user is enganged in booking into. */
  center: IBookableCenter;
  /** The service which the user is engaged in booking. */
  service: ICalendarService;
}

export interface ICenterSelectStepWrapperConfig
  extends Pick<IStepWrapperProps, "NextButtonDesktop" | "NextButtonMobile"> {
  /** The component which allows the user to select a center. */
  CenterSelectStep: React.FC<IControlledMutator<IBookableCenter>>;
  /** The component which allows the user to select a service. */
  ServiceSelector: React.FC<ICenterServiceSelectorProps>;
}

export interface ICenterSelectStepWrapperProps extends IControlledMutator<ICenterSelectValue>, IFlowStepProps {
  /** Callback to clear any reservations they user may be holding. */
  clearReservation: DispatchWithoutAction;
}

/** Component which contains the content for selecting the consult center for the here. */
const CenterSelectStepWrapper: React.FC<ICenterSelectStepWrapperProps & ICenterSelectStepWrapperConfig> = ({
  value,
  clearReservation,
  onChange,
  setStep,
  CenterSelectStep,
  ServiceSelector,
  NextButtonDesktop,
  NextButtonMobile,
  ...restFlowStepProps
}) => {
  const [isServiceSelectorEnabled, setIsServiceSelectorEnabled] = useState(false);
  const [newCenter, setNewCenter] = useState(value.center);

  const changeCenter = (updatedService?: ICalendarService) => {
    onChange({ center: newCenter, service: updatedService || value.service });
    clearReservation();
    setTimeout(() => setStep(Step.scheduling));
  };

  const setService = (service: ICalendarService) => {
    setIsServiceSelectorEnabled(false);
    changeCenter(service);
  };

  const confirmCenter = () => {
    const matchingService = newCenter.services.find(s => s.name === value.service.name);
    if (!matchingService) return setIsServiceSelectorEnabled(true);
    if (newCenter.id !== value.center.id)
      changeCenter(matchingService.id !== value.service.id ? matchingService : undefined);
    else setStep(Step.scheduling);
  };

  return (
    <StepWrapper
      {...restFlowStepProps}
      onNext={confirmCenter}
      nextButtonLabel="Book this Center"
      NextButtonDesktop={NextButtonDesktop}
      NextButtonMobile={NextButtonMobile}
    >
      <CenterSelectStep value={newCenter} onChange={setNewCenter} />
      <ServiceSelector
        disabled={!isServiceSelectorEnabled}
        value={value.service}
        options={newCenter.services}
        onChange={setService}
        setDisabled={() => setIsServiceSelectorEnabled(false)}
      />
    </StepWrapper>
  );
};

export default CenterSelectStepWrapper;
