import { useContext, useEffect, useRef } from "react";
import { Optional } from "sonobello.utilities.react.mui";

import AppContext from "./AppContext";
import { Customer, CustomerQualification } from "./dtos/Customer";
import { DisqualifyingReasonsType } from "./dtos/DisqualifyingReason";
import { MedicalForm, MedicalFormState } from "./types/MedicalForm";
import { bodyMassIndex } from "./utils/Constants";
import { loadFromLocal, saveToLocal } from "./utils/LocalStorage";
import useObx from "./utils/UseObx";

class LeadCustomerQualificationRequest {
  /** The id of the lead with which the qualification is associated. */
  leadId: string;
  /** The id of the customer whom has been assessed for qualification. */
  customerId: string;
  /** The results of the qualification assessment. */
  payload: CustomerQualification;

  constructor(leadId: string, customerId: string, payload: CustomerQualification) {
    this.leadId = leadId;
    this.customerId = customerId;
    this.payload = payload;
  }

  /** Determines whether two instances of LeadCustomerQualification are equal. */
  static equals = (a: LeadCustomerQualificationRequest, b: LeadCustomerQualificationRequest): boolean => {
    return (
      a.leadId === b.leadId &&
      a.customerId === b.customerId &&
      a.payload.dateOfBirth === b.payload.dateOfBirth &&
      a.payload.heightInches === b.payload.heightInches &&
      a.payload.weight === b.payload.weight &&
      a.payload.isMedicallyQualified === b.payload.isMedicallyQualified
    );
  };
}

const localStorageKey = "qualificationRecord";

const useLeadCustomerQualification = () => {
  const { leadIdCState, medicalFormCState, customerCState } = useContext(AppContext);

  const lastUpdatedQualificationRef = useRef(loadFromLocal<LeadCustomerQualificationRequest>(localStorageKey));

  const { res: postResponse, setReq: setPostRequest } = useObx<
    CustomerQualification,
    CustomerQualification,
    Pick<LeadCustomerQualificationRequest, "leadId" | "customerId">
  >();

  useEffect(() => {
    if (!customerCState?.id) return;

    const medicalFormResult = MedicalForm.getResult(medicalFormCState);

    let disqualifyingReason: Optional<DisqualifyingReasonsType> = undefined;

    try {
      disqualifyingReason = Customer.getDisqualifyingReason(customerCState);
    } catch {
      // NOOP
    }

    if (!disqualifyingReason && medicalFormResult === MedicalFormState.Incomplete) return;

    const qualificationPayload: CustomerQualification = {
      heightInches: undefined,
      weight: customerCState.weight || undefined,
      bodyMassIndex: undefined,
      dateOfBirth: customerCState.dateOfBirth || undefined,
      isMedicallyQualified: !disqualifyingReason && medicalFormResult === MedicalFormState.Valid ? true : false
    };

    if (customerCState.height) {
      qualificationPayload.heightInches = customerCState.height.feet * 12 + customerCState.height.inches;
    }

    if (qualificationPayload.heightInches && customerCState.weight) {
      qualificationPayload.bodyMassIndex = bodyMassIndex(customerCState.weight, qualificationPayload.heightInches);
    }

    const qualificationRequest = new LeadCustomerQualificationRequest(
      leadIdCState,
      customerCState.id,
      qualificationPayload
    );

    if (
      lastUpdatedQualificationRef.current &&
      LeadCustomerQualificationRequest.equals(lastUpdatedQualificationRef.current, qualificationRequest)
    )
      return;

    setPostRequest({
      url: `leads/${qualificationRequest.leadId}/customers/${qualificationRequest.customerId}/qualifications`,
      method: "post",
      payload: qualificationRequest.payload,
      custom: { leadId: qualificationRequest.leadId, customerId: qualificationRequest.customerId }
    });
  }, [customerCState, leadIdCState, medicalFormCState]);

  useEffect(() => {
    if (!postResponse || !postResponse.request.custom) return;
    const newRequest = new LeadCustomerQualificationRequest(
      postResponse.request.custom.leadId,
      postResponse.request.custom.customerId,
      postResponse.payload
    );
    lastUpdatedQualificationRef.current = newRequest;
    saveToLocal(newRequest, localStorageKey);
  }, [postResponse]);
};

export default useLeadCustomerQualification;
