import { Chip } from "@mui/material";
import React from "react";

import { isMobileView } from "../utils/Constants";

const VersionWatermark: React.FC = () => {
  const isMobile = isMobileView();
  return process.env.REACT_APP_ENVNAME !== "Master" ? (
    <Chip
      size={isMobile ? "small" : undefined}
      label={`v${process.env.REACT_APP_VERSION_NO}`}
      sx={{
        position: "fixed",
        bottom: 8,
        right: 8,
        opacity: isMobile ? 0.5 : 1,
        fontSize: isMobile ? 10 : 14,
        pointerEvents: "none"
      }}
    />
  ) : (
    <></>
  );
};

export default VersionWatermark;
