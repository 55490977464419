import { Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";

import AppContext from "../../../AppContext";

/** The Welcome Step when the flow belongs to the New Patient type. */
const NewPatientWelcomeStep: React.FC = () => {
  const { customerCState } = useContext(AppContext);
  return (
    <Stack spacing={2}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="h4" component="span" color="primary">
            Hi
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            id="welcomeStep_firstName"
            variant="h4"
            component="span"
            color="primary"
          >{` ${customerCState.displayFirstName}`}</Typography>
        </Grid>
      </Grid>
      <Typography variant="body1">Welcome to the Sono Bello Online Booking Experience!</Typography>
      <Typography variant="body1">Lets get started with your amazing transformation journey.</Typography>
    </Stack>
  );
};

export default NewPatientWelcomeStep;
