import { IComponentParent } from "sonobello.utilities.react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import ConfirmationStep, { IConfirmationStepProps } from "../Components/ConfirmationStep.v12";

const AppContextConfirmationStep: React.FC<IComponentParent> = AppContextValueProvider<
  Omit<IConfirmationStepProps, keyof IComponentParent>,
  IConfirmationStepProps
>({
  Component: ConfirmationStep,
  map: ({ reservation }) => {
    if (!reservation) throw "Must have a booking center and a reservation.";
    return {
      center: reservation.center,
      startDateTime: reservation.slot.startTimeLocal,
      endDateTime: reservation.slot.endTimeLocal
    };
  }
});

export default AppContextConfirmationStep;
