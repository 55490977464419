import AppContextValueProvider from "../../App/Compositions/AppContextValueProvider";
import RedirectToFirstStep from "../Components/RedirectToFirstStep";

/** A routing redirect to the first step of the user's current flow. */
const AppContextRedirectToFirstStep: React.FC = AppContextValueProvider({
  Component: RedirectToFirstStep,
  map: appContextProps => ({ appContextProps })
});

export default AppContextRedirectToFirstStep;
