import { ComponentProps } from "react";
import { CachedStateProvider, IControlledMutator } from "sonobello.utilities.react";

import { LocalStorageConfigs } from "../../../utils/LocalStorage";
import SignalRConnection, { ISignalRConnectionConfiguration } from "../Components/SignalRConnection";

/** A {@link SignalRConnection} which caches and restores its {@link ISignalRConnectionConfiguration} from the browser cache. */
const CachedSignalRConnection: React.FC<
  Omit<ComponentProps<typeof SignalRConnection>, keyof IControlledMutator<unknown>>
> = props =>
  CachedStateProvider<ISignalRConnectionConfiguration, ComponentProps<typeof SignalRConnection>>({
    ...props,
    Component: SignalRConnection,
    localStoreKey: LocalStorageConfigs.signalRConnectionInfo.key
  });

export default CachedSignalRConnection;
