import { DateTime, IANAZone } from "luxon";
import { Nullable } from "sonobello.utilities.react";

import { ITimeLocaleSlotGroups } from "./CalendarScheduleDay";

/** The service which will be conducted for an appointment booked on an the associated
 * {@link ICalendarSchedule}.
 */
export interface ICalendarService {
  /** The id of the service. */
  readonly id: string;
  /** The id of the service in the external CRM. */
  readonly externalId: string;
  /** The plain text name of the service. */
  readonly name: string;
  /** The name of the service's sub-category. */
  readonly serviceSubCategoryName: string;
  /** The business unit to which the service belongs. */
  readonly businessUnit: {
    /** The id of the business unit to which the service belongs. */
    readonly id: string;
    /** The plain text name of the business unit to which the service belongs. */
    readonly name: string;
  };
}

/** The definition of a slot on the service schedule for which an appointment might be booked. */
export interface IScheduleSlot {
  /** The start time of the time slot in the time zone in which the slot's center is located. */
  readonly startTimeLocal: DateTime;
  /** The start time of the slot in utc. */
  readonly startTimeUtc: DateTime;
  /** The end time of the slot in utc. */
  readonly endTimeUtc: DateTime;
  /** The end time of the time slot in the time zone in which the slot's center is located. */
  readonly endTimeLocal: DateTime;
  /** The flag indicating if the slot is occupied by an appointment or reservation. */
  readonly isOccupied: boolean;
  /** Gets this slot in a primitive format that can be written to and restored from the browser cache. */
  readonly getCachedPrimitive: () => unknown;
}

/** The primitive slot which is written to and restored from the browser cache. */
export interface ICachedScheduleSlot {
  readonly startTimeUtcIso: string;
  readonly endTimeUtcIso: string;
  readonly isOccupied: boolean;
  readonly timeZoneName: string;
}

/** {@inheritdoc IScheduleSlot} */
export abstract class ScheduleSlot implements IScheduleSlot {
  readonly startTimeLocal: DateTime;
  readonly startTimeUtc: DateTime;
  readonly endTimeUtc: DateTime;
  readonly endTimeLocal: DateTime;
  readonly isOccupied: boolean;

  constructor(startTimeUtc: string, endTimeUtc: string, isOccupied: boolean, zone: IANAZone) {
    this.startTimeLocal = DateTime.fromISO(startTimeUtc, { zone });
    this.startTimeUtc = DateTime.fromISO(startTimeUtc).toUTC();
    this.endTimeLocal = DateTime.fromISO(endTimeUtc, { zone });
    this.endTimeUtc = DateTime.fromISO(endTimeUtc).toUTC();
    this.isOccupied = isOccupied;
    if (
      this.startTimeLocal.invalidReason ||
      this.endTimeLocal.invalidReason ||
      this.startTimeUtc.invalidReason ||
      this.endTimeUtc.invalidReason
    ) {
      throw new Error("Failed to parse iso strings to a DateTime.");
    }
  }

  abstract readonly getCachedPrimitive: () => unknown;
}

export interface ICalendarScheduleDay {
  /** The slots which are potentially bookable by the application. */
  readonly slots: IScheduleSlot[];
  /** A flag indicating if any slot in the day where {@link IScheduleSlot.isOccupied} is false.
   * @remarks This flag cannot be used to identify if the slot is allowed to be booked by the user, such as being in
   * the future or not being masked be some other behavior.
   */
  readonly isAnySlotAvailable: boolean;

  /** Returns the day's slots grouped by time locale.
   * @param morningEndHour - The hour at which the morning grouping ends (non-inclusive).
   * @param afternoonEndHour - The hour at which the afternoon grouping ends (non-inclusive).
   */
  readonly getGroupedByTimeLocale: (morningEndHour: number, afternoonEndHour: number) => ITimeLocaleSlotGroups;
}

/** The schedule for a specific calendar service, this is a dictionary of time slots keyed by the slots ISO UTC date. */
export type ICalendarSchedule = Record<string, ICalendarScheduleDay>;

/** A calendar's service paired with the schedule of slots which can be booked for it. */
export interface ICalendarServiceSchedule {
  /** The slot schedule for the paired service. */
  readonly days: ICalendarSchedule;
  /** A flag indicating that at least one unoccupied slot exists on the schedule. */
  readonly isAnySlotAvailable: boolean;
  /** The service for this schedule. */
  readonly service: ICalendarService;
  /** The time zone in which appointments on the schedule will occur. */
  readonly timeZone: IANAZone;
  /** The last schedule date in the schedule's local timezone. */
  readonly lastScheduleDateLocal: DateTime;

  /** Returns the first non-occupied slot on the schedule that does not fall within the optionally provided mask.
   * @param slotSelectionMaskedMinutes - The number of minutes in the future that the result should not be selected from.
   */
  readonly getFirstAvailableSlot: (slotSelectionMaskedMinutes?: number) => Nullable<IScheduleSlot>;
}

/** A representation of all booking availability for a given center. */
interface ICalendar {
  /** The calendar's booking schedules, one for each bookable service. */
  readonly schedules: ICalendarServiceSchedule[];
}

export default ICalendar;
