import { useCallback, useEffect, useState } from "react";
import { Nullable, TriggeredHook } from "sonobello.utilities.react";

import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import { ILegacyCalendarTopicPayload } from "../../SignalR/Types/CalendarTopicPayload";
import { CalendarFailure } from "../Components/CalendarManager";
import { ICalendarHookProps } from "../Components/IdentityCalendarWorker";
import CalendarFailureStatusCodeMap from "../Types/CalendarFailureStatusCodeMap";
import ICalendar from "../Types/ICalendar";
import ILegacyCalendarResponse from "../Types/ILegacyCalendarResponse";
import LegacyCalendar from "../Types/LegacyCalendar";

/** Hook which obtains the calendar for the given id and center id. */
const useGetLegacyCalendar: TriggeredHook<
  ICalendar,
  Pick<ILegacyCalendarTopicPayload, "calendarId">,
  CalendarFailure,
  ICalendarHookProps
> = ({ centerId, centerTimeZone }) => {
  const [calendar, setCalendar] = useState<Nullable<ICalendar>>(null);
  const { res, err, loading, setReq } = useObx<ILegacyCalendarResponse>("Get Calendar By Id", {
    config: { params: { numberOfDays: process.env.REACT_APP_CALENDAR_MAXIMUM_DAYS } },
    preventOnUnauthorizedRecovery: true
  });

  useEffect(() => {
    if (res?.payload) setCalendar(new LegacyCalendar(res.payload, centerTimeZone));
  }, [res]);

  const execute = useCallback(
    ({ calendarId }: Pick<ILegacyCalendarTopicPayload, "calendarId">) =>
      setReq(r => r && { ...r, url: ApiRequestPaths.getLegacyCalendar(centerId, calendarId) }),
    []
  );

  return {
    result: calendar,
    error: err?.error.response
      ? CalendarFailureStatusCodeMap[err.error.response.status] || CalendarFailure.NotFound
      : null,
    isLoading: loading,
    execute
  };
};

export default useGetLegacyCalendar;
