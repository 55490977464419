import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import { UserEvent } from "sonobello.utilities.react.mui";
import { UserEventType } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEvent";

import classNames from "../../Constants/ClassNames";
import INextButtonProps from "../Types/INextButtonProps";

/** The step's 'next' button when in desktop mode. */
const NextButtonDesktop: React.FC<INextButtonProps> = ({ disabled, label, isLoading, onClick, onEvent }) => {
  const handleClick = () => {
    onEvent(new UserEvent(UserEventType.Click, classNames.stepNextButtonDesktop));
    onClick();
  };
  return (
    <Box width="100%" display="flex" justifyContent="center" mt="2rem">
      <Button
        className={`${classNames.stepNextButton} ${classNames.stepNextButtonDesktop}`}
        disabled={disabled || isLoading}
        onClick={handleClick}
        endIcon={isLoading ? <CircularProgress color="inherit" sx={{ ml: 1 }} size="1.5rem" /> : <NavigateNextIcon />}
      >
        {label}
      </Button>
    </Box>
  );
};

export default NextButtonDesktop;
