/** A designation assigned to a slot on a calendar service schedule that indicates its current usage status. */
enum CalendarSlotStatus {
  /** This slot is available to receive reservations and bookings. */
  Open = 0,
  /** This slot has been tentatively reserved by a client for booking. It is not fully occupied by a confirmed appointment. */
  Reserved = 1,
  /** The slot is occupied by a confirmed appointment. */
  Booked = 2,
  /** The slot is occupied by an non-appointment entity such as a building closure. */
  Blocked = 3
}

export default CalendarSlotStatus;
