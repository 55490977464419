import { Typography } from "@mui/material";
import React, { useMemo } from "react";

interface EscapePhoneProps {
  phoneNumber?: string;
}

/* Unifies formatting of the PAC escape number */
const EscapePhone: React.FC<EscapePhoneProps> = ({ phoneNumber }) => {
  if (!phoneNumber) throw "Phone number not provided.";
  const escapePhoneHref = useMemo(() => {
    return `tel:+1${phoneNumber?.replaceAll(/-|[(]|[)]|[ ]/g, "")}`;
  }, [phoneNumber]);
  return (
    <a href={escapePhoneHref}>
      <Typography variant="body1" color="secondary" fontWeight="bold" component="span">
        {phoneNumber}
      </Typography>
    </a>
  );
};

export default EscapePhone;
