import { AppContextProps } from "../../../../AppContext";
import WelcomeStep from "../../../../components/steps/welcome/WelcomeStep";
import { NewPatientFlow } from "../../../../flows/NewPatientFlow";
import { NoBookFlow } from "../../../../flows/NoBookFlow";
import { RebookFlow } from "../../../../flows/RebookFlow";
import { FlowType } from "../../../../types/Flow";
import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";

const welcomeStepFlowMap: Record<string, FlowType> = {
  [NewPatientFlow.name]: FlowType.newPatient,
  [NoBookFlow.name]: FlowType.noBook,
  [RebookFlow.name]: FlowType.rebook
};

type IFlowTypedWelcomeStep = Pick<AppContextProps, "flowCState">;

/** A welcome step which binds the step variant to the flow being experienced. */
const FlowTypedWelcomeStep: React.FC<IFlowTypedWelcomeStep> = ({ flowCState, ...rest }) =>
  WelcomeStep({ ...rest, flowType: welcomeStepFlowMap[flowCState.name] ?? FlowType.newPatient });

const AppContextWelcomeStep: React.FC = AppContextValueProvider<
  Pick<AppContextProps, "flowCState">,
  IFlowTypedWelcomeStep
>({
  Component: FlowTypedWelcomeStep,
  map: ({ flowCState }) => ({ flowCState })
});

export default AppContextWelcomeStep;
