import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Typography } from "@mui/material";
import React from "react";

import ViewWrapper, { ViewWrapperIconProps } from "./ViewWrapper";

export interface EndedSessionViewProps {
  /** The name of the customer whom is associated with this OBX session. */
  customerName: string;
  /** The key of the OBX session which should be restarted. */
  sessionKey: string;
}

/** The web page shown when the user has a valid session but the authorization token cannot be renewed. */
const EndedSessionView: React.FC<EndedSessionViewProps> = ({ customerName, sessionKey }) => {
  return (
    <ViewWrapper
      header="Session Has Ended"
      subheader={`Sorry ${customerName}, but your session has ended.`}
      icon={<RestartAltIcon {...ViewWrapperIconProps} />}
      nextButtonProps={{
        label: "New Session",
        onClick: () => (location.href = `${window.location.origin}/${sessionKey}`)
      }}
    >
      <Typography variant="body1">You can start a new session by clicking on the button below.</Typography>
    </ViewWrapper>
  );
};

export default EndedSessionView;
