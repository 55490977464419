import { ComponentProps } from "react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import SchedulingLoader, { ISchedulingLoaderProps } from "../Components/SchedulingLoader";

const AppContextSchedulingLoader = AppContextValueProvider<
  ISchedulingLoaderProps,
  ComponentProps<typeof SchedulingLoader>
>({
  Component: SchedulingLoader,
  map: ({ bookingController, customerCState, reservation, router }) => ({
    bookingController,
    customerFirstName: customerCState.displayFirstName,
    reservation,
    router
  })
});

export default AppContextSchedulingLoader;
