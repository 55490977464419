import { DateTime, IANAZone } from "luxon";

import { LocalStorageConfigs, saveToLocal } from "../../utils/LocalStorage";
import { ICalendarService, IScheduleSlot } from "../Calendar/Types/ICalendar";
import ICenter, { Center } from "./ICenter";
import { BaseReservation, ICachedReservationCenter } from "./IReservation";

/** A primitive reserved slot in the form that it is persisted and retrieved from the browser cache. */
interface ICachedReservationSlot {
  /** The start time of the slot as a UTC ISO. */
  readonly startTimeUtcIso: string;
  /** The end time of the slot in UTC ISO. */
  readonly endTimeUtcIso: string;
  /** The IANA zone name in which the slot's center is located. */
  readonly timeZoneName: string;
  /** The flag indicating the time slot's occupancy status. */
  readonly isOccupied: boolean;
}

/** A schedule slot built from a cached reservation slot. */
export class CachedSlot implements IScheduleSlot {
  /** {@inheritdoc IScheduleSlot.startTimeUtc} */
  readonly startTimeUtc: DateTime;
  /** {@inheritdoc IScheduleSlot.endTimeUtc} */
  readonly endTimeUtc: DateTime;
  /** {@inheritdoc IScheduleSlot.startTimeLocal} */
  readonly startTimeLocal: DateTime;
  /** {@inheritdoc IScheduleSlot.endTimeLocal} */
  readonly endTimeLocal: DateTime;
  /** {@inheritdoc IScheduleSlot.isOccupied} */
  readonly isOccupied: boolean;

  constructor(cachedReservationSlot: ICachedReservationSlot) {
    const zone = new IANAZone(cachedReservationSlot.timeZoneName);
    if (!zone.isValid) throw new Error("Invalid IANA zone name.");
    this.startTimeUtc = DateTime.fromISO(cachedReservationSlot.startTimeUtcIso).toUTC();
    this.endTimeUtc = DateTime.fromISO(cachedReservationSlot.endTimeUtcIso).toUTC();
    this.startTimeLocal = DateTime.fromISO(cachedReservationSlot.startTimeUtcIso, { zone });
    this.endTimeLocal = DateTime.fromISO(cachedReservationSlot.endTimeUtcIso, { zone });
    this.isOccupied = cachedReservationSlot.isOccupied;
  }

  /** {@inheritdoc IScheduleSlot.getCachedPrimitive} */
  getCachedPrimitive = (): ICachedReservationSlot => ({
    startTimeUtcIso: this.startTimeUtc.toISO(),
    endTimeUtcIso: this.endTimeUtc.toISO(),
    timeZoneName: this.startTimeLocal.zone.name,
    isOccupied: this.isOccupied
  });
}
/** A primitive representation of a {@link LegacyReservation} in the form used when it lives in the browser cache. */
export interface ICachedLegacyReservation {
  /** {@inheritdoc IReservation.centerId} */
  center: ICachedReservationCenter;
  /** {@inheritdoc IReservation.slot} */
  slot: ICachedReservationSlot;
  /** {@inheritdoc IReservation.expirationUtc} */
  expirationUtc: string;
  /** {@inheritdoc IReservation.service} */
  service: ICalendarService;
}

/** A {@link BaseReservation} made with the legacy calendar process. */
class LegacyReservation extends BaseReservation {
  constructor(center: ICenter, slot: IScheduleSlot, service: ICalendarService, expiresUtc?: DateTime) {
    super(center, slot, service, expiresUtc);
  }

  /** {@inheritdoc ICached.cache} */
  override cache = () => {
    const cachedReservation: ICachedLegacyReservation = {
      center: this.center.toPrimitive(),
      slot: {
        startTimeUtcIso: this.slot.startTimeUtc.toISO(),
        endTimeUtcIso: this.slot.endTimeUtc.toISO(),
        timeZoneName: this.slot.startTimeLocal.zone.name,
        isOccupied: this.slot.isOccupied
      },
      expirationUtc: this.expirationUtc.toISO(),
      service: this.service
    };
    saveToLocal(cachedReservation, LocalStorageConfigs.legacyReservation.key);
  };

  /** {@inheritdoc IVolatileCached.unsetCache} */
  override unsetCache = () => localStorage.removeItem(LocalStorageConfigs.legacyReservation.key);
}

/** A {@link LegacyReservation} restored from the browser cache. */
export class CachedLegacyReservation extends LegacyReservation {
  constructor(reservation: ICachedLegacyReservation) {
    const zone = new IANAZone(reservation.slot.timeZoneName);
    if (!zone.isValid) throw new Error("Invalid IANA zone name.");
    super(
      new Center(
        reservation.center.id,
        reservation.center.distance,
        reservation.center.name,
        reservation.center.address,
        reservation.center.phoneNumber,
        zone
      ),
      new CachedSlot(reservation.slot),
      reservation.service,
      DateTime.fromISO(reservation.expirationUtc).toUTC()
    );
  }
}

export default LegacyReservation;
