import { LeadResultType } from "./LeadResult";

/** A categorization descriptor linked to a lead result which further specifies the nature of the result. */
export class DisqualifyingReason {
  /** The unique identifier for the reason. */
  id!: string;
  /** The plain text name of the reason. */
  name!: string;
  /** The id of the lead result type to which this reason can be applied. */
  leadResultTypeId!: LeadResultType;

  /** Get the matched reason from a list of reasons by its enum.
   * @throws if the list does not have a matching reason for the provided reason enum.
   */
  static GetReason = (
    disqualifyingReasons: DisqualifyingReason[],
    queryReasonEnum: DisqualifyingReasonsType
  ): DisqualifyingReason => {
    const reason = disqualifyingReasons.find(r => r.name === queryReasonEnum);
    if (!reason) throw "Failed to find a reason in the provided list matching the provided enum.";
    return reason;
  };
}

export enum DisqualifyingReasonsType {
  ObxMedicalNoncandidate = "OBX Medical Noncandidate",
  ObxBmi = "OBX BMI",
  ObxAge = "OBX Age"
}
