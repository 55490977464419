import { useContext } from "react";
import { Hook } from "sonobello.utilities.react";

import AppContext from "../../../AppContext";
import ICenter from "../../Types/ICenter";
import useGetClosestCenters from "../Hooks/UseGetClosestCenters";

/** A hook which obtains the booking candidate centers using the state of the session App Context. */
const AppContextUseGetClosestCenters: Hook<ICenter[]> = () => {
  const { customerCState, flowCState, defaultCenter } = useContext(AppContext);
  return useGetClosestCenters({
    centerId: defaultCenter.id,
    postalCode: customerCState.zipCode,
    flow: flowCState,
    defaultCenter
  });
};

export default AppContextUseGetClosestCenters;
