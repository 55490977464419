import {
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useTheme
} from "@mui/material";
import React, { useContext } from "react";
import { LeafLogoSvg, SBPalette, UserEvent, UserEventContext } from "sonobello.utilities.react.mui";
import { UserEventType } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEvent";

import { Question } from "../../../types/MedicalForm";

type YesOrNo = "yes" | "no";
interface FormQuestionProps {
  /** The html id for the question. */
  id?: string;
  /** Flag indicating if the view is currently in the mobile mode. */
  isMobile: boolean;
  /** The id of this component when it triggers user events. */
  eventId: string;
  /** The question that should be rendered. */
  question: Question;
  /** The handler to be executed when the yes/no toggle is clicked. */
  onChange: (value: boolean) => void;
}
const FormQuestion: React.FC<FormQuestionProps> = ({ eventId, id, isMobile, question: { text, answer }, onChange }) => {
  const { onEvent } = useContext(UserEventContext);
  const theme = useTheme();
  const handleChange = (_: unknown, value: YesOrNo) => {
    onEvent(new UserEvent(UserEventType.Change, `medical-question-toggle-${eventId}`, value));
    onChange(value === "yes");
  };
  return (
    <ListItem id={id} sx={{ px: isMobile ? 0 : undefined, display: "flex", justifyContent: "space-between" }}>
      <Stack direction="row" spacing={isMobile ? 4 : 0} alignItems="center">
        <FormQuestionIcon isMobile={isMobile}>
          <LeafLogoSvg
            fill={SBPalette.dark.watermarkGray.main}
            style={{ height: theme.spacing(3), width: theme.spacing(3) }}
          />
        </FormQuestionIcon>
        <ListItemText primary={text} sx={{ pr: 2 }} />
      </Stack>
      <ListItemIcon>
        <ToggleButtonGroup
          className="medicalStep-answerToggle"
          color="primary"
          value={(answer === undefined ? undefined : answer ? "yes" : "no") as YesOrNo}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton className="medicalStep-yesButton" value="yes">
            Yes
          </ToggleButton>
          <ToggleButton className="medicalStep-noButton" value="no">
            No
          </ToggleButton>
        </ToggleButtonGroup>
      </ListItemIcon>
    </ListItem>
  );
};

interface FormQuestionIconProps extends ListItemIconProps {
  isMobile: boolean;
}

const FormQuestionIcon = styled(ListItemIcon, {
  shouldForwardProp: propName => propName !== "isMobile"
})<FormQuestionIconProps>(({ isMobile, theme }) => ({
  minWidth: isMobile ? theme.spacing(4) : undefined
}));

export default FormQuestion;
