import { NewPatientFlow } from "../../../flows/NewPatientFlow";
import { Flow } from "../../../types/Flow";
import { NewPatientNoWelcomeFlow } from "../Compositions/NewPatientNoWelcomeFlow";
import ExperimentKey from "./ExperimentKey";
import IExperimentContextProps from "./IExperimentContextProps";
import IFlowResolver from "./IFlowResolver";

export const flowNotResolvedMessage = "Flow name not supported.";

/**
 * An {@link IFlowResolver} which returns the experimental {@link NewPatientNoWelcomeFlow} if the
 * experiment context calls for it, otherwise the default assigned flow. */
class NewPatientNoWelcomeFlowResolver implements IFlowResolver {
  /** {@inheritdoc} */
  invoke = (experimentContext: IExperimentContextProps, sessionFlowName: string): Flow => {
    if (sessionFlowName === NewPatientNoWelcomeFlow.name) return NewPatientNoWelcomeFlow;
    else if (sessionFlowName == NewPatientFlow.name) {
      try {
        if (experimentContext.getExperimentConfiguration(ExperimentKey.NewPatientNoWelcomeStep).isActive)
          return NewPatientNoWelcomeFlow;
      } catch (e) {
        console.error(e, e);
        console.warn(`Failed to retrieve feature flags for experiment ${ExperimentKey.NewPatientNoWelcomeStep}.`);
      }
      return NewPatientFlow;
    }
    throw new Error(flowNotResolvedMessage);
  };
}

export default NewPatientNoWelcomeFlowResolver;
