import { Flow } from "../types/Flow";
import { MedicalForm, MedicalFormState } from "../types/MedicalForm";
import { Step } from "../types/Step";
import StepperId from "../V2/App/Types/StepperId";

/** The flow that a new user should experience when booking an appointment. */
export const NewPatientFlow = new Flow(
  "newPatient",
  {
    [Step.welcome]: {
      stepperId: StepperId.Welcome,
      key: Step.welcome,
      nextStep: ({ customerCState }) => (customerCState.isPreviouslyQualified ? Step.scheduling : Step.qualify)
    },
    [Step.qualify]: {
      stepperId: StepperId.Screening,
      key: Step.qualify,
      nextStep: ({ customerCState: { height, weight, dateOfBirth }, disqualifyingReasonsCState }) =>
        height && weight && dateOfBirth && disqualifyingReasonsCState ? Step.medicalPartOne : undefined,
      shouldSkip: ({ customerCState }) => customerCState.isPreviouslyQualified
    },
    [Step.medicalPartOne]: {
      stepperId: StepperId.Screening,
      key: Step.medicalPartOne,
      nextStep: ({ medicalFormCState, disqualifyingReasonsCState }) => {
        const formResult = MedicalForm.getResultPart(medicalFormCState.partOneQuestions);
        if (disqualifyingReasonsCState && formResult !== MedicalFormState.Incomplete) return Step.medicalPartTwo;
        return undefined;
      },
      shouldSkip: ({ customerCState }) => customerCState.isPreviouslyQualified
    },
    [Step.medicalPartTwo]: {
      stepperId: StepperId.Screening,
      key: Step.medicalPartTwo,
      nextStep: ({ medicalFormCState, disqualifyingReasonsCState }) => {
        const formResult = MedicalForm.getResultPart(medicalFormCState.partTwoQuestions);
        if (disqualifyingReasonsCState && formResult !== MedicalFormState.Incomplete) return Step.medicalA1C;
        return undefined;
      },
      shouldSkip: ({ customerCState }) => customerCState.isPreviouslyQualified
    },
    [Step.medicalA1C]: {
      stepperId: StepperId.Screening,
      key: Step.medicalA1C,
      nextStep: ({ medicalFormCState, disqualifyingReasonsCState }) => {
        const formResult = MedicalForm.getResult(medicalFormCState);
        if (disqualifyingReasonsCState && formResult !== MedicalFormState.Incomplete) return Step.scheduling;
        return undefined;
      },
      shouldSkip: ({ customerCState }) => customerCState.isPreviouslyQualified
    },
    [Step.centerSelection]: {
      stepperId: StepperId.Scheduling,
      key: Step.centerSelection,
      nextStep: () => Step.scheduling,
      shouldSkip: () => true
    },
    [Step.scheduling]: {
      stepperId: StepperId.Scheduling,
      key: Step.scheduling,
      // allow progress to confirmation if we have a confirmed reservation
      nextStep: ({ reservation }) => (reservation && Step.confirmation) || undefined
    },
    [Step.confirmation]: {
      stepperId: StepperId.Confirmation,
      key: Step.confirmation,
      nextStep: undefined
    }
  },
  [
    { getStepKey: () => Step.welcome, stepperId: StepperId.Welcome, isComplete: s => s > Step.welcome },
    {
      getStepKey: () => Step.qualify,
      stepperId: StepperId.Screening,
      isComplete: s => s > Step.medicalA1C,
      shouldSkip: ({ customerCState }) => customerCState.isPreviouslyQualified
    },
    {
      getStepKey: ({ medicalFormCState }) =>
        MedicalForm.getResult(medicalFormCState) === MedicalFormState.Valid ? Step.scheduling : undefined,
      stepperId: StepperId.Scheduling,
      isComplete: s => s > Step.scheduling
    },
    {
      getStepKey: ({ reservation }) => (reservation && Step.confirmation) || undefined,
      stepperId: StepperId.Confirmation,
      isComplete: s => s > Step.confirmation
    }
  ]
);
