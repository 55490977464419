import { ComponentProps } from "react";
import { IControlledMutator } from "sonobello.utilities.react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import IBookableCenter from "../../../Types/IBookableCenter";
import CenterSelectStep, { ICenterSelectStepConfig, ICenterSelectStepProps } from "../Components/CenterSelectStep";

/** A CenterSelect Step with all props provisioned other than the controlled mutator. */
const AppContextCenterSelectStep: React.FC<ICenterSelectStepConfig & IControlledMutator<IBookableCenter>> =
  AppContextValueProvider<
    Omit<ICenterSelectStepProps, keyof IControlledMutator<IBookableCenter>>,
    ComponentProps<typeof CenterSelectStep>
  >({
    Component: CenterSelectStep,
    map: ({ bookingController }) => {
      if (!bookingController?.selected) throw "A service must be selected.";
      return {
        options: bookingController.bookableCenters,
        service: bookingController.selected.schedule.service
      };
    }
  });

export default AppContextCenterSelectStep;
