import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { Dispatch, DispatchWithoutAction, SetStateAction } from "react";
import {
  Defined,
  IControl,
  IControlledMutator,
  IDisable,
  IMutator,
  IOptions,
  Nullable,
  TriggeredHook
} from "sonobello.utilities.react";
import { SBSizes } from "sonobello.utilities.react.mui";

import { isMobileView } from "../../../../utils/Constants";
import IBookingController from "../../../Calendar/Types/IBookingController";
import { ICalendarService, IScheduleSlot } from "../../../Calendar/Types/ICalendar";
import { Center } from "../../../Types/ICenter";
import IReservation from "../../../Types/IReservation";
import { ISchedulerProps } from "./SchedulingStepWrapper";

export const classNames = {
  centerChangeButton: "schedulingStep-center-change-button",
  centerAddress2: "schedulingStep-center-address2"
};

export type IScheduleSlotSelectorProps = IControl<Nullable<IScheduleSlot>> & IMutator<IScheduleSlot>;

export type IServiceSelectorProps = IControlledMutator<ICalendarService> & IOptions<ICalendarService> & IDisable;

export interface ISchedulingStepProps {
  /** The current schedule being considered for booking. */
  bookingController: Defined<Pick<IBookingController, "selected" | "bookableCenters">>;
  /** The currently held reservation. */
  reservation: Nullable<IReservation>;
  /** Callback to clear the current reservation state. */
  clearReservation: DispatchWithoutAction;
  /** Callback to change the current service being booked. */
  setBookingSchedule: Dispatch<SetStateAction<IBookingController>>;
}

export interface ISchedulingStepConfig {
  /** Hook that releases the currently reserved slot. */
  useReleaseReservation: TriggeredHook<void, IReservation>;
  /** The form component which allows the user to designate a block in which the appointment should be booked. */
  SlotSelector: React.FC<IScheduleSlotSelectorProps>;
  /** The form component which allows the user to change the currently selected service to book. */
  ServiceSelector: React.FC<IServiceSelectorProps>;
  /** The component which displays the current lifespan of the held reservation. */
  ReservationCounter: React.FC;
}

/** Component which contains the content for the Consult Schedule Selection step. */
const SchedulingStep: React.FC<ISchedulingStepProps & ISchedulerProps & ISchedulingStepConfig> = ({
  bookingController,
  reservation,
  clearReservation,
  goToCenterSelection,
  useReleaseReservation,
  setBookingSchedule,
  SlotSelector,
  ServiceSelector,
  ReservationCounter,
  ...rest
}) => {
  const showMobile = isMobileView();

  const { execute } = useReleaseReservation();

  const handleChangeService = (service: ICalendarService) => {
    if (reservation) {
      clearReservation();
      execute(reservation);
    }
    setBookingSchedule(s => s.selectService(service.id));
  };

  const showChangeCenterButton = bookingController.bookableCenters.length > 1;
  const showChangeServiceSelector = bookingController.bookableCenters.some(c => c.services.length > 1);
  const centerSupportsSingleService = bookingController.selected.center.services.length === 1;
  const servicePickerMessage = centerSupportsSingleService
    ? `Center only supports ${bookingController.selected.center.services[0].name}.`
    : "Please select the service you wish to book:";

  return (
    <Stack justifyContent="center" alignItems="center" spacing={showMobile ? 1.25 : 2.5} flexGrow={1}>
      <CenterInfo
        center={bookingController.selected.center}
        onClick={showChangeCenterButton ? goToCenterSelection : undefined}
        showMobile={showMobile}
      />
      <ReservationCounter />
      {showChangeServiceSelector && (
        // Min-height prevents overlapping UI on small screens
        <Stack spacing={1} sx={{ minHeight: "70px" }}>
          <Typography variant="body1" sx={{ opacity: centerSupportsSingleService ? 0.5 : 1 }}>
            {servicePickerMessage}
          </Typography>
          <ServiceSelector
            value={bookingController.selected.schedule.service}
            onChange={handleChangeService}
            options={bookingController.selected.center.services}
            disabled={centerSupportsSingleService}
          />
        </Stack>
      )}
      <Box display="flex" flexGrow={1} flexDirection="column">
        <SlotSelector {...rest} />
      </Box>
    </Stack>
  );
};

interface CenterInfoProps {
  center: Center;
  showMobile: boolean;
  onClick?: DispatchWithoutAction;
}
const CenterInfo: React.FC<CenterInfoProps> = ({ center, showMobile, onClick }) => {
  const { streetAddress, cityStateZip } = center.addressParts;
  const centerInfo = showMobile ? (
    <Stack direction="row" alignContent="center" alignItems="center" spacing={1}>
      <LocationOnIcon sx={{ fontSize: "2.2rem" }} />
      <Typography variant="h6">{center.name}</Typography>
      {onClick && <Button sx={{ fontSize: "0.7rem", p: 0 }}>Change</Button>}
    </Stack>
  ) : (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <LocationOnIcon sx={{ fontSize: SBSizes.icon }} />
      </Box>
      <Stack>
        <Typography variant="h6">{center.name}</Typography>
        <Typography variant="body2">{streetAddress}</Typography>
        {cityStateZip && (
          <Typography className={classNames.centerAddress2} variant="body2">
            {cityStateZip}
          </Typography>
        )}
        <Typography variant="body2">{center.phoneNumber}</Typography>
      </Stack>
      {onClick && <Button sx={{ fontSize: "0.7rem", p: 0 }}>Change</Button>}
    </Stack>
  );
  return onClick ? (
    <Box className={classNames.centerChangeButton} onClick={onClick} sx={{ cursor: "pointer" }}>
      {centerInfo}
    </Box>
  ) : (
    centerInfo
  );
};

export default SchedulingStep;
