import { Card, CardContent, CardHeader, CardProps, Grid, Stack, Typography, styled } from "@mui/material";
import React, { DispatchWithoutAction } from "react";
import { UserEvent, UserEventType } from "sonobello.utilities.react.mui";

import IBookableCenter from "../../../Types/IBookableCenter";
import { Center } from "../../../Types/ICenter";
import IUserEventSource from "../../Types/IUserEventSource";
import { ICenterSelectorProps } from "./CenterSelectStep";

export const classNames = {
  card: "centerCard",
  cardActive: "centerCardActive",
  header: "centerCardheader",
  unsupportedService: "centerCardUnsupportedService",
  address1: "centerCardAddress1",
  address2: "centerCardAddress2",
  phoneNumber: "centerCardPhoneNumber"
};

export interface ICenterCardProps extends Omit<CardProps, "onClick"> {
  /** Flag indicating if this selector is active. */
  active: boolean;
  /** The center that this selector represents. */
  center: Pick<Center, "name" | "addressParts" | "phoneNumber" | "distance">;
  /** The name of the unsupported service that should be rendered to the user, if any. */
  unsupportedServiceName: string | null;
  /** Callback to be executed when this selector is clicked. */
  onClick: DispatchWithoutAction;
}

/** A selector element which allows for selection of the active center. */
const CenterCard: React.FC<ICenterCardProps> = ({
  active,
  center,
  className,
  unsupportedServiceName,
  onClick,
  ...rest
}) => {
  const { streetAddress, cityStateZip } = center.addressParts;
  let cardClassNames = `${className} ${classNames.card}`;
  if (active) cardClassNames += ` ${classNames.cardActive}`;
  return (
    <Card className={cardClassNames} onClick={onClick} color={active ? "primary" : undefined} {...rest} elevation={4}>
      <CardHeader
        className={classNames.header}
        title={center.name}
        subheader={`${Math.round(center.distance!)} Miles`}
      />
      <CardContent>
        <Stack>
          {unsupportedServiceName && (
            <Typography className={classNames.unsupportedService} variant="body2" color="error">
              <strong>{`Does not support ${unsupportedServiceName}.`}</strong>
            </Typography>
          )}
          <Typography className={classNames.address1} variant="body2">
            {streetAddress}
          </Typography>
          {cityStateZip && (
            <Typography className={classNames.address2} variant="body2">
              {cityStateZip}
            </Typography>
          )}
          <Typography className={classNames.phoneNumber} variant="body2">
            {center.phoneNumber}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const StyledCenterCard = styled(CenterCard)<ICenterCardProps>(({ active, theme }) => ({
  width: "100%",
  "& .MuiCardContent-root": { paddingTop: "unset" },
  ...(active ? { outline: "solid 3px", outlineColor: theme.palette.primary.main } : { cursor: "pointer" })
}));

export type IEventfulCenterSelectorProps = ICenterSelectorProps & IUserEventSource;

/** Selector allowing the user to designate a new center for booking. */
const CenterSelector: React.FC<IEventfulCenterSelectorProps> = ({ options, service, value, onChange, onEvent }) => {
  const handleClick = (bookableCenter: IBookableCenter) => {
    onEvent(new UserEvent(UserEventType.Click, `center-option-button-${bookableCenter.name}`));
    onChange(bookableCenter);
  };
  return (
    <Grid container rowSpacing={2} justifyContent="center" alignItems="center" sx={{ px: 1 }}>
      {options.map((c, index) => (
        <Grid item md={8} sm={10} xs={12} key={index}>
          <StyledCenterCard
            active={c.id === value.id}
            center={c}
            onClick={() => handleClick(c)}
            unsupportedServiceName={c.services.some(s => s.name === service.name) ? null : service.name}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CenterSelector;
