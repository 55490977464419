import { useCallback, useState } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import OpsUtilities from "../../../Constants/OpsUtility";
import IReservation from "../../../Types/IReservation";
import OpsReservation from "../../../Types/OpsReservation";

export interface IUseReleaseOpsReservationProps extends IUseReleaseReservationProps {
  /** The id of the customer associated with the reservation to be released. */
  customerId: string;
}

export interface IUseReleaseReservationProps {
  /** The id of the curren't session's lead. */
  leadId: string;
}

export interface IUseReleaseHybridReservationConfig {
  /** The post reservation hook for use with the OPS process. */
  useReleaseOpsReservation: TriggeredHook<void, OpsReservation, boolean, IUseReleaseOpsReservationProps>;
  /** The post reservation hook for use with the legacy process. */
  useReleaseLegacyReservation: TriggeredHook<void, IReservation, boolean, IUseReleaseReservationProps>;
}

/** A hook which releases the reservation using the appropriate calendar process for the trigger. */
const useReleaseHybridReservation: TriggeredHook<
  void,
  IReservation,
  boolean,
  IUseReleaseHybridReservationConfig & IUseReleaseOpsReservationProps
> = ({ useReleaseLegacyReservation, useReleaseOpsReservation, ...rest }) => {
  const [isLegacy, setIsLegacy] = useState<boolean>(true);
  const {
    result: legacyResult,
    error: legacyError,
    isLoading: legacyIsLoading,
    execute: legacyExecute
  } = useReleaseLegacyReservation(rest);
  const {
    result: opsResult,
    error: opsError,
    isLoading: opsIsLoading,
    execute: opsExecute
  } = useReleaseOpsReservation(rest);

  const execute = useCallback((reservation: IReservation) => {
    if (OpsUtilities.isCenterSupported(reservation.center.id)) {
      setIsLegacy(false);
      if (!(reservation instanceof OpsReservation)) throw "Expected OpsScheduleSlot type, but could not resolve to it.";
      return opsExecute(reservation as OpsReservation);
    } else {
      setIsLegacy(true);
      return legacyExecute(reservation);
    }
  }, []);

  return isLegacy
    ? { result: legacyResult, error: legacyError, isLoading: legacyIsLoading, execute }
    : { result: opsResult, error: opsError, isLoading: opsIsLoading, execute };
};

export default useReleaseHybridReservation;
