import { Box, BoxProps } from "@mui/material";
import React from "react";
import { SBPalette } from "sonobello.utilities.react.mui";

/** The container for the full content background beneath the header. */
const MainBackgroundContainer: React.FC<BoxProps> = ({ children, sx, ...rest }) => {
  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      overflow="hidden"
      alignItems="center"
      sx={{ backgroundColor: SBPalette.light.paleGray.main, ...sx }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default MainBackgroundContainer;
