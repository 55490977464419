import { useContext } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import AppContext from "../../../../AppContext";
import IReservation from "../../../Types/IReservation";
import { IConfirmedAppointmentResponse } from "../Components/ConfirmationStepWrapper";
import useConfirmHybridAppointment, {
  IUseConfirmAppointmentProps,
  IUseConfirmHybridAppointmentConfig
} from "../Hooks/UseConfirmHybridAppointment";
import { AppointmentNoteProps } from "../Types/IAppointment";

/** A {@link useConfirmHybridAppointment} with its props provided from the {@link AppContext}. */
const AppContextUseConfirmAppointment: TriggeredHook<
  IConfirmedAppointmentResponse,
  IReservation,
  boolean,
  IUseConfirmHybridAppointmentConfig & Pick<IUseConfirmAppointmentProps, "useCreateLeadResult">
> = props => {
  const appContextProps = useContext(AppContext);
  return useConfirmHybridAppointment({
    ...props,
    appointmentNoteProps: new AppointmentNoteProps(appContextProps),
    customer: appContextProps.customerCState,
    leadId: appContextProps.leadIdCState
  });
};

export default AppContextUseConfirmAppointment;
