import { IANAZone } from "luxon";
import React, { useEffect } from "react";
import { Hook, TriggeredHook } from "sonobello.utilities.react";

import { CalendarFailure, ICalendarWorkerProps as ICalendarManagerWorkerProps } from "./CalendarManager";

/** The config props available to the calendar hooks. */
export interface ICalendarHookProps {
  /** The if of the center to which the requested calendar belongs. */
  centerId: string;
  /** The time zone of the center to which the requested calendar belongs. */
  centerTimeZone: IANAZone;
}

/** The properties which define the calendar acquisition behaviors for an identity calendar worker.
 * @typeParam TControl - The type of the control which triggers the calendar acquisition.
 */
export interface IIdentityCalendarWorkerProps<TControl, TResponse> {
  /** The hook which obtains the calendar for a center with the given id. */
  useGetCenterCalendar: Hook<TResponse, CalendarFailure, ICalendarHookProps>;
  /** The hook which obtains the calendar with a given id. */
  useGetCalendar: TriggeredHook<TResponse, TControl, CalendarFailure, ICalendarHookProps>;
}

/** A Worker which gets the calendar for its assigned center on mount, and the calendar for its control on update. */
function IdentityCalendarWorker<TControl, TResponse>({
  value,
  onChange,
  onError,
  useGetCalendar,
  useGetCenterCalendar
}: ICalendarManagerWorkerProps<TControl, TResponse> & IIdentityCalendarWorkerProps<TControl, TResponse>): ReturnType<
  React.FC<unknown>
> {
  const { result: centerCalendarResult, error: centerCalendarError } = useGetCenterCalendar({
    centerId: value.center.id,
    centerTimeZone: value.center.timeZone
  });
  const {
    result: calendarResult,
    error: calendarError,
    execute
  } = useGetCalendar({ centerId: value.center.id, centerTimeZone: value.center.timeZone });

  useEffect(() => {
    const error = centerCalendarError || calendarError;
    if (error) onError(error);
  }, [calendarError, centerCalendarError]);

  useEffect(() => {
    if (value.update) execute(value.update);
  }, [value.update]);

  useEffect(() => {
    const calendarResponse = calendarResult || centerCalendarResult;
    if (calendarResponse) onChange(calendarResponse);
  }, [centerCalendarResult, calendarResult]);

  return null;
}

export default IdentityCalendarWorker;
