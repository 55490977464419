import { useContext } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import AppContext from "../../../../AppContext";
import { IScheduleSlot } from "../../../Calendar/Types/ICalendar";
import IReservation from "../../../Types/IReservation";
import useCreateHybridReservation, { IUseCreateHybridReservationConfig } from "../Hooks/UseCreateHybridReservation";

/**
 * A hook which creates a reservation for the given {@link IScheduleSlot} using the appropriate calendar process for
 * the input, and is controlled by the {@link AppContext}.
 */
const AppContextUseCreateReservation: TriggeredHook<
  IReservation,
  IScheduleSlot,
  boolean,
  IUseCreateHybridReservationConfig
> = props => {
  const { bookingController, customerCState, leadIdCState } = useContext(AppContext);
  if (!bookingController?.selected) throw "Must have a service to release a block.";
  return useCreateHybridReservation({
    ...props,
    leadId: leadIdCState,
    service: bookingController.selected.schedule.service,
    customerId: customerCState.id,
    center: bookingController.selected.center
  });
};

export default AppContextUseCreateReservation;
