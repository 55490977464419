import React, { DispatchWithoutAction, useEffect } from "react";
import { IControl, IMutator, Nullable, TriggeredHook } from "sonobello.utilities.react";
import { UserEvent, UserEventType } from "sonobello.utilities.react.mui";

import { IFlowStepProps } from "../../../../types/Flow";
import { Step } from "../../../../types/Step";
import { IScheduleSlot } from "../../../Calendar/Types/ICalendar";
import { ISomethingWentWrongRouter } from "../../../Routing/Types/IRouter";
import IReservation from "../../../Types/IReservation";
import StepWrapper, { IStepWrapperButtonProps } from "../../Components/StepWrapper";
import IUserEventSource from "../../Types/IUserEventSource";

/** The props interface for a component which can select a calendar slot to book. */
export interface ISchedulerProps extends IControl<Nullable<IScheduleSlot>>, IMutator<IScheduleSlot> {
  /** A callback which sends the user view to the center selection step. */
  goToCenterSelection: DispatchWithoutAction;
}

export interface ISchedulingStepWrapperProps
  extends IControl<Nullable<IScheduleSlot>>,
    IMutator<IReservation>,
    Pick<IFlowStepProps, "setStep">,
    IUserEventSource {
  /** A flag which indicates if all centers are unbookable. */
  allCentersUnbookable: boolean;
  /** Router which allows the step to send the user to a view. */
  router: ISomethingWentWrongRouter;
}

export interface ISchedulingStepWrapperConfig extends IStepWrapperButtonProps {
  /** The hook which allows the user to request a reservation for a specified block. */
  useCreateReservation: TriggeredHook<IReservation, IScheduleSlot>;
  /** The step component which identifies the block to reserve for an appointment. */
  Scheduler: React.FC<ISchedulerProps>;
}

/** Component which contains the content for the Consult Schedule Selection step. */
const SchedulingStepWrapper: React.FC<ISchedulingStepWrapperProps & ISchedulingStepWrapperConfig> = ({
  router,
  value,
  onChange,
  onEvent,
  setStep,
  useCreateReservation,
  Scheduler,
  ...rest
}) => {
  const { execute: postReservation, error, result } = useCreateReservation();
  useEffect(() => {
    if (error) router.goToSomethingWentWrongView();
  }, [error]);
  useEffect(() => {
    if (result) onChange(result);
  }, [result]);

  const goToCenterSelection = () => {
    onEvent(new UserEvent(UserEventType.Click, "schedulingStep_changeCenterButton"));
    setStep(Step.centerSelection);
  };

  return (
    <StepWrapper {...rest} disabled={!value} onNext={() => setStep(Step.confirmation)}>
      <Scheduler value={value} onChange={postReservation} goToCenterSelection={goToCenterSelection} />
    </StepWrapper>
  );
};

export default SchedulingStepWrapper;
