import { useEffect, useState } from "react";
import { Hook, Nullable } from "sonobello.utilities.react";

import useObx from "../../../utils/UseObx";
import { CalendarFailure } from "../Components/CalendarManager";
import { ICalendarHookProps } from "../Components/IdentityCalendarWorker";
import CalendarFailureStatusCodeMap from "../Types/CalendarFailureStatusCodeMap";
import ICalendar from "../Types/ICalendar";
import ILegacyCalendarResponse from "../Types/ILegacyCalendarResponse";
import LegacyCalendar from "../Types/LegacyCalendar";

/** Hook which obtains the calendar for the given center id. */
const useGetLegacyCenterCalendar: Hook<ICalendar, CalendarFailure, ICalendarHookProps> = ({
  centerId,
  centerTimeZone
}) => {
  const [calendar, setCalendar] = useState<Nullable<ICalendar>>(null);
  const { res, err, loading } = useObx<ILegacyCalendarResponse>("Get Calendar", {
    url: `v2/centers/${centerId}/calendars`,
    config: { params: { numberOfDays: process.env.REACT_APP_CALENDAR_MAXIMUM_DAYS } },
    preventOnUnauthorizedRecovery: true
  });
  useEffect(() => setCalendar(res?.payload ? new LegacyCalendar(res.payload, centerTimeZone) : null), [res]);

  return {
    result: calendar,
    error: err?.error.response
      ? CalendarFailureStatusCodeMap[err.error.response.status] || CalendarFailure.NotFound
      : null,
    isLoading: loading
  };
};

export default useGetLegacyCenterCalendar;
