import React, { PropsWithChildren, createContext, useCallback, useRef } from "react";

import IExperimentContextProps, { DefaultExperimentContextProps } from "../Types/IExperimentContextProps";

export interface ExperimentContextProviderProps {
  /** The list of experiment contexts which can be used to resolve an experiment context operation. */
  experimentContexts: IExperimentContextProps[];
}

/** Provides the {@link ExperimentContext} properties to all child components via the `useContext()` hook.
 * @remarks Attempts to resolve all requests for each provided experiment context.
 */
export const ExperimentContextProvider = ({
  experimentContexts,
  children
}: PropsWithChildren<ExperimentContextProviderProps>): JSX.Element => {
  const experimentContextsRef = useRef([...experimentContexts, new DefaultExperimentContextProps()]);
  const getExperimentConfiguration: IExperimentContextProps["getExperimentConfiguration"] = useCallback(key => {
    for (const context of experimentContextsRef.current) {
      try {
        return context.getExperimentConfiguration(key);
      } catch {
        continue;
      }
    }
    throw new Error(`Experiment configuration for ${key} not found.`);
  }, []);
  const recordBookedAppointment = useCallback(() => {
    for (const context of experimentContextsRef.current) context.recordBookedAppointment();
  }, []);

  return (
    <ExperimentContext.Provider value={{ getExperimentConfiguration, recordBookedAppointment }}>
      {children}
    </ExperimentContext.Provider>
  );
};

const ExperimentContext = createContext({} as IExperimentContextProps);

export default ExperimentContext;
