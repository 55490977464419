import { DateTime } from "luxon";
import { Nullable, Optional } from "sonobello.utilities.react.mui";

import { MedicalForm, MedicalFormState } from "../types/MedicalForm";
import { NoteTagConfiguration, buildNote } from "../types/Notes";
import { ICalendarService } from "../V2/Calendar/Types/ICalendar";
import ICenter from "../V2/Types/ICenter";
import { Customer, EnhancedCustomer } from "./Customer";
import { DisqualifyingReason, DisqualifyingReasonsType } from "./DisqualifyingReason";
import { LeadResult } from "./LeadResult";

/** The properties necessary to build a customer note. */
export type CustomerNoteProps = {
  customer: EnhancedCustomer;
  center: ICenter;
  disqualifyingReasons?: DisqualifyingReason[];
  medicalForm?: MedicalForm;
  leadResult: Nullable<LeadResult>;
  service: Nullable<ICalendarService>;
};

// CONFIGURE THE CUSTOMER NOTE TAGS HERE
// If a value isn't valid, return undefined, else return the string to be inserted.
// Tags will be organized in the same order as they are configured.
const noteTagsConfig: NoteTagConfiguration<CustomerNoteProps>[] = [
  {
    template: ({ service }) => service?.serviceSubCategoryName
  },
  {
    template: () => "OBX"
  },
  {
    template: ({ service }) => service?.businessUnit.name
  },
  {
    template: ({ customer }) => {
      const tokens: string[] = [];
      if (customer.height) tokens.push(`${customer.height.feet}ft${customer.height.inches}in`);
      if (customer.weight) tokens.push(`${customer.weight}lbs`);
      const bmi = Customer.getBmi(customer);
      if (bmi) tokens.push(`${bmi}BMI`);
      return tokens.join(", ") || undefined;
    }
  },
  {
    template: ({ customer }) =>
      customer.dateOfBirth ? DateTime.fromISO(customer.dateOfBirth).toLocaleString(DateTime.DATE_MED) : undefined
  },
  {
    template: ({ customer, medicalForm }) => {
      if (!medicalForm) return undefined;
      const medicalFormResult = MedicalForm.getResult(medicalForm);

      let disqualifyingReason: Optional<DisqualifyingReasonsType> = undefined;

      try {
        disqualifyingReason = Customer.getDisqualifyingReason(customer);
      } catch {
        // NOOP
      }

      if (!disqualifyingReason && medicalFormResult === MedicalFormState.Incomplete) return undefined;

      return !disqualifyingReason && medicalFormResult === MedicalFormState.Valid ? "MQ Pass" : "MQ Fail";
    }
  },
  {
    template: ({ center, customer }) => {
      const distance = center.distance ?? customer?.distanceToCenter;
      return distance !== undefined ? `${distance.toFixed(2)} mi` : distance;
    }
  },
  {
    template: ({ center }) => center.name
  },
  {
    template: ({ customer, disqualifyingReasons }) => {
      if (!customer.height || !customer.weight || !customer.dateOfBirth) return undefined;
      const customerDisqualifyingReason = Customer.getDisqualifyingReason(customer);
      if (!customerDisqualifyingReason || !disqualifyingReasons) return undefined;
      const disqualifyingReason = DisqualifyingReason.GetReason(disqualifyingReasons, customerDisqualifyingReason);
      return `Non-Can: ${disqualifyingReason.name}`;
    }
  }
];

export class CustomerNote {
  id?: string;
  text: string;
  centerId: string;

  constructor(tagProps: CustomerNoteProps) {
    this.text = CustomerNote.getText(tagProps);
    this.centerId = tagProps.customer.centerId;
  }

  /** Get the text content of the customer note based on the required tag properties. */
  static getText = (tagProps: CustomerNoteProps): string => buildNote(noteTagsConfig, tagProps);
}
