import { Global, css } from "@emotion/react";
import React from "react";

import { isMobileView } from "../../../utils/Constants";

export class GlobalStyle {
  selector: string;
  rule: { [key: string]: string | number };

  constructor(selector: string, rule: { [key: string]: string | number }) {
    this.selector = selector;
    this.rule = rule;
  }

  getStyle = () => {
    return css({
      [`.${this.selector}`]: {
        ...this.rule
      }
    });
  };
}

/** The interface which defines the types for the props passed into the component */
export interface GlobalStylesViewControllerProps {
  /** The styles for mobile view */
  mobileStyles: GlobalStyle[];
  /** The styles for desktop view */
  desktopStyles: GlobalStyle[];
}

const GlobalStylesViewController: React.FC<GlobalStylesViewControllerProps> = ({ mobileStyles, desktopStyles }) => {
  const stylesToApply = isMobileView() ? mobileStyles : desktopStyles;
  const styles = css(stylesToApply.map(style => style.getStyle()));

  return <Global styles={styles} />;
};

export default GlobalStylesViewController;
