import { Nullable } from "sonobello.utilities.react";

import IBookableCenter from "../../Types/IBookableCenter";
import { Center } from "../../Types/ICenter";
import CalendarHub from "./CalendarHub";
import { ICalendarServiceSchedule } from "./ICalendar";

interface IActiveBookingSelections {
  /** The center currently being considered for booking. */
  center: IBookableCenter;
  /** The schedule currently being considered for booking. */
  schedule: ICalendarServiceSchedule;
}

/** The authority for the center/calendar options available for booking, the currently selected option. */
interface IBookingController {
  /** A flag indicating if booking is possible for the session.
   * @remarks Booking is not possible if all tracked centers have either confirmed 0 availability, or failed to
   * load their calendars.
   */
  readonly isBookingPossible: boolean;
  /** The center and schedule currently being considered for booking. */
  readonly selected: Nullable<IActiveBookingSelections>;
  /** The list of centers available for booking selection. */
  readonly bookableCenters: IBookableCenter[];
  /** Returns a new booking schedule for the current center and specified service. */
  readonly selectService: (serviceId: string) => IBookingController;
  /** Returns a new booking schedule for the specified center and service. */
  readonly selectCenter: (centerId: string, serviceId: string) => IBookingController;
  /** Returns a new controller for the updated state of the bookable center calendars.
   * @remarks will change the active selections if they are not possible with the new calendars.
   */
  readonly updateCalendars: (calendars: CalendarHub) => IBookingController;
}

/** {@inheritdoc IBookingController} */
export class BookingController implements IBookingController {
  private readonly calendarHub: CalendarHub;
  private readonly businessUnitId: string;

  readonly bookableCenters: IBookableCenter[];
  readonly isBookingPossible: boolean;
  readonly selected: Nullable<IActiveBookingSelections>;

  /**
   * @param calendar - The calendar for the center.
   * @param businessUnitId - The id of the business unit to be used as the default when resolving selected service changes.
   * @param centerId - The id of the center to be set as currently active.
   * @param serviceId - The id of the service to be set as currently active.
   * @remarks The service id used is selected with the following order of precedence:
   * 1. The service matching the passed service id.
   * 2. The first service matching the passed business unit id.
   * 3. The first service.
   */
  constructor(calendarHub: CalendarHub, businessUnitId: string, centerId?: string, serviceId?: string) {
    this.bookableCenters = calendarHub.bookableCenters ?? [];

    // if no center is selected, attempt to set the closest center as active.
    if (!centerId) {
      if (!calendarHub.bookableCenters?.length) this.selected = null;
      else {
        let center = calendarHub.bookableCenters.find(c => c.services.some(s => s.businessUnit.id === businessUnitId));
        if (!center) {
          center = calendarHub.bookableCenters[0];
        }

        const calendar = calendarHub.getCalendar(center.id);
        if (!calendar) this.selected = null;
        else {
          this.selected = {
            center: center,
            schedule: this.resolveServiceSchedule(calendar.schedules, businessUnitId, serviceId)
          };
        }
      }
    }
    // if a center is requested, attempt to set it as selected.
    else {
      const bookableCenter = calendarHub.bookableCenters?.find(c => c.id === centerId);
      const calendar = calendarHub.getCalendar(centerId);
      // if a bookable center or calendar cannot be resolved for the center id, then set up as unselected.
      if (!bookableCenter || !calendar || !calendarHub.bookableCenters) {
        this.selected = null;
      }
      // otherwise set up the selections.
      else {
        this.selected = {
          center: bookableCenter,
          schedule: this.resolveServiceSchedule(calendar.schedules, businessUnitId, serviceId)
        };
      }
    }
    this.businessUnitId = businessUnitId;
    this.calendarHub = calendarHub;
    this.isBookingPossible = calendarHub.isBookingPossible;
  }

  /** Resolves the service schedule for the given business unit id and service id.
   * @remarks The service id used is selected with the following order of precedence:
   * 1. The service matching the passed service id.
   * 2. The first service matching the passed business unit id.
   * 3. The first service.
   */
  private readonly resolveServiceSchedule = (
    schedules: ICalendarServiceSchedule[],
    businessUnitId: string,
    serviceId?: string
  ): ICalendarServiceSchedule =>
    (serviceId && schedules.find(s => s.service.id === serviceId && s.isAnySlotAvailable)) ||
    schedules.find(s => s.service.businessUnit.id === businessUnitId && s.isAnySlotAvailable) ||
    schedules[0];

  private readonly updateSchedule = (serviceId: string, centerId?: string): BookingController => {
    const newCenterId = centerId || this.selected?.center.id;
    const newServiceId = serviceId || this.selected?.schedule.service.id;
    const newBusinessUnitId =
      (newCenterId &&
        this.calendarHub.getCalendar(newCenterId)?.schedules.find(s => s.service.id === serviceId)?.service.businessUnit
          .id) ||
      this.businessUnitId;
    return new BookingController(this.calendarHub, newBusinessUnitId, newCenterId, newServiceId);
  };

  /** Gets the selection for the closest center matching the given service params. */
  private readonly getUpdatedSelection = (
    calendarHub: CalendarHub,
    businessUnitId: string,
    serviceId?: string
  ): { centerId: string; serviceId: string } => {
    const sortedBookableCenters = calendarHub.bookableCenters!.sort(Center.shortestDistanceComparator);

    for (const center of sortedBookableCenters) {
      const centerCalendar = calendarHub.getCalendar(center.id);
      if (!centerCalendar) continue;
      const schedule = this.resolveServiceSchedule(centerCalendar.schedules, businessUnitId, serviceId);
      return { centerId: center.id, serviceId: schedule.service.id };
    }
    throw "Failed to resolve a valid center and service selection.";
  };

  /** {@inheritdoc IBookingController.getScheduleForService} */
  readonly selectService = (serviceId: string): IBookingController => this.updateSchedule(serviceId);

  /** {@inheritdoc IBookingController.getScheduleForCenter} */
  readonly selectCenter = (centerId: string, serviceId: string): IBookingController =>
    this.updateSchedule(serviceId, centerId);

  /** {@inheritdoc IBookingController.updateCalendars} */
  readonly updateCalendars = (calendarHub: CalendarHub): IBookingController => {
    // if there is no possible selection, then simply update the calendar hub
    if (!calendarHub.isBookingPossible || !calendarHub.bookableCenters)
      return new BookingController(calendarHub, this.businessUnitId);

    // if there is no current selection, then try to select the closest center
    if (!this.selected)
      return new BookingController(
        calendarHub,
        this.businessUnitId,
        calendarHub.bookableCenters?.length ? calendarHub.bookableCenters[0].id : undefined
      );

    // attempt to preserve the currently selected center
    if (calendarHub.bookableCenters.some(c => c.id === this.selected!.center.id)) {
      const schedules = calendarHub.getCalendar(this.selected.center.id)?.schedules;
      if (!schedules) throw "Failed to locate a known schedule for the already selected center.";
      const serviceId = this.resolveServiceSchedule(schedules, this.businessUnitId, this.selected!.schedule.service.id)
        .service.id;
      return new BookingController(calendarHub, this.businessUnitId, this.selected!.center.id, serviceId);
    }

    // finally, try to select the next closest center and matching service
    const { centerId, serviceId } = this.getUpdatedSelection(
      calendarHub,
      this.businessUnitId,
      this.selected.schedule.service.id
    );
    return new BookingController(calendarHub, this.businessUnitId, centerId, serviceId);
  };
}

export default IBookingController;
