import { ICalendarScheduleDay, IScheduleSlot } from "./ICalendar";

/** A series of groupings of {@link IScheduleSlot} into locale time categorizations for morning, afternoon, and
 * evening.
 */
export interface ITimeLocaleSlotGroups {
  /** Items which fall into into the morning classification. */
  morning: IScheduleSlot[];
  /** Items which fall into into the afternoon classification. */
  afternoon: IScheduleSlot[];
  /** Items which fall into into the evening classification. */
  evening: IScheduleSlot[];
}

class CalendarScheduleDay implements ICalendarScheduleDay {
  slots: IScheduleSlot[];
  isAnySlotAvailable: boolean;

  constructor(slots: IScheduleSlot[]) {
    this.slots = slots;
    this.isAnySlotAvailable = slots.some(s => !s.isOccupied);
  }

  getGroupedByTimeLocale = (morningEndHour: number, afternoonEndHour: number): ITimeLocaleSlotGroups => {
    return {
      morning: this.slots.filter(s => s.startTimeLocal.hour < morningEndHour),
      afternoon: this.slots.filter(
        s => s.startTimeLocal.hour >= morningEndHour && s.startTimeLocal.hour < afternoonEndHour
      ),
      evening: this.slots.filter(s => s.startTimeLocal.hour >= afternoonEndHour)
    };
  };
}

export default CalendarScheduleDay;
