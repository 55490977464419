import { IANAZone } from "luxon";
import { Comparator, Nullable } from "sonobello.utilities.react";

import IAddressParts, { AddressParts } from "./IAddressParts";

/** A location in which an appointment can be booked. */
interface ICenter {
  /** The unique identifier of the center. */
  readonly id: string;
  /** The estimated distance from the customer to the center. */
  readonly distance: Nullable<number>;
  /** The plain text name of the center. */
  readonly name: string;
  /** The plain text information of the center's address. */
  readonly address: string;
  /** The consumable parts of the center's address */
  readonly addressParts: IAddressParts;
  /** The phone number used to contact the center regarding appointment details or concerns. */
  readonly phoneNumber: string;
  /** The IANA name of the center's time zone. */
  readonly timeZone: IANAZone;
}

/** {@inheritdoc ICenter} */
export class Center implements ICenter {
  readonly id: string;
  readonly distance: Nullable<number>;
  readonly name: string;
  readonly address: string;
  readonly addressParts: IAddressParts;
  readonly phoneNumber: string;
  readonly timeZone: IANAZone;

  constructor(
    id: string,
    distance: Nullable<number>,
    name: string,
    address: string,
    phoneNumber: string,
    timeZone: IANAZone
  ) {
    this.id = id;
    this.distance = distance;
    this.name = name;
    this.address = address;
    this.addressParts = new AddressParts(address);
    this.phoneNumber = phoneNumber;
    this.timeZone = timeZone;
  }

  /** A JS comparison function that returns the center with the smaller {@link Center.distance} value.
   * @remarks If a single center has an null distance value, the other center distance is considered smaller.
   */
  static shortestDistanceComparator: Comparator<Pick<ICenter, "distance">> = (a, b) => {
    if (a.distance === null) return 1;
    if (b.distance === null) return -1;
    return a.distance > b.distance ? 1 : -1;
  };
}

export default ICenter;
