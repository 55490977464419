import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { LocalStorageConfigs, loadFromLocalRaw } from "../../utils/LocalStorage";
import ViewWrapper, { ViewWrapperIconProps } from "./ViewWrapper";

export interface IExpiredReservationViewProps {
  sessionKey?: string;
}

/** The web page shown when the reservation has expired. */
const ExpiredReservationView: React.FC<IExpiredReservationViewProps> = ({ sessionKey: key }) => {
  const [sessionKey] = useState(key || loadFromLocalRaw(LocalStorageConfigs.sessionKey.key));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ViewWrapper
      header="Your Reservation has expired!"
      icon={<EventBusyIcon {...ViewWrapperIconProps} />}
      nextButtonProps={{
        label: "Book a Consultation",
        onClick: () => (location.href = `${location.origin}/${sessionKey}`)
      }}
      hideNeedHelpDialog
    >
      <Typography variant="body1">You can resume your session by clicking the button below.</Typography>
    </ViewWrapper>
  );
};

export default ExpiredReservationView;
