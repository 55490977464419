import {
  Step as MuiStep,
  StepButton as MuiStepButton,
  StepProps as MuiStepProps,
  Stepper as MuiStepper,
  styled
} from "@mui/material";
import React, { useContext } from "react";
import { Optional, UserEvent, UserEventContext } from "sonobello.utilities.react.mui";
import { UserEventType } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEvent";

import AppContext, { AppContextProps } from "../../AppContext";
import { Flow } from "../../types/Flow";
import { FlowStep } from "../../types/Flow";
import { Step } from "../../types/Step";
import StepperId from "../../V2/App/Types/StepperId";

interface StepperProps {
  configuration: Flow["stepperConfiguration"];
  flowStep: FlowStep;
  setStep: (s: Step) => void;
}

/** Renders the stepper for the OBX session. */
const Stepper: React.FC<StepperProps> = ({ configuration, flowStep, setStep }) => {
  const contextProps = useContext(AppContext);
  return (
    <MuiStepper id="main_stepper" nonLinear activeStep={flowStep.key} alternativeLabel>
      {configuration.map(({ stepperId, isComplete, shouldSkip, ...rest }, index) => {
        if (shouldSkip && shouldSkip(contextProps)) return undefined;
        return (
          <FlowStepperItem
            key={index}
            completed={isComplete(flowStep.key)}
            setStep={setStep}
            stepperId={stepperId}
            active={stepperId === flowStep.stepperId}
            {...rest}
          />
        );
      })}
    </MuiStepper>
  );
};

/** Props which define the behaviors of the stepper when this step is active. */
export interface FlowStepperItemProps extends Omit<MuiStepProps, "disabled"> {
  /** The id of the stepper item to be used. */
  stepperId: StepperId;
  /**
   * When the step is active, clicking the stepper label specified by {@link label} will move the view to the step
   * returned by this function.
   * @remarks If the function returns `undefined`, then the stepper item button is disabled. If this prop is `undefined`
   * then the stepper item will have no action when clicked.
   */
  getStepKey?: (props: AppContextProps) => Optional<Step>;
  /** Callback to be executed when the stepper item is clicked on. */
  setStep: (s: Step) => void;
}

const stepperLabelMap: Record<StepperId, string> = {
  [StepperId.Welcome]: "Welcome",
  [StepperId.Screening]: "Screening",
  [StepperId.Scheduling]: "Scheduling",
  [StepperId.Confirmation]: "Confirmation"
};

const FlowStepperItem: React.FC<FlowStepperItemProps> = ({ stepperId, getStepKey, setStep, ...rest }) => {
  const { onEvent } = useContext(UserEventContext);
  const contextProps = useContext(AppContext);
  const stepLinkValue = getStepKey && getStepKey(contextProps);
  const handleClick = () => {
    if (!stepLinkValue) return;
    onEvent(new UserEvent(UserEventType.Click, `mui-step-button-${stepperLabelMap[stepperId]}`));
    setStep(stepLinkValue);
  };
  return (
    <StyledMuiStep disabled={getStepKey && !stepLinkValue ? true : false} {...rest}>
      <MuiStepButton onClick={stepLinkValue && handleClick}>{stepperLabelMap[stepperId]}</MuiStepButton>
    </StyledMuiStep>
  );
};

/** A step with custom formatting and margins. */
const StyledMuiStep = styled(MuiStep)<MuiStepProps>(({ theme }) => ({
  "& .MuiStepLabel-label": { marginTop: `${theme.spacing(0.66)} !important` }
}));

export default Stepper;
