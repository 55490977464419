import { ComponentProps } from "react";
import { Defined } from "sonobello.utilities.react";

import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import IBookingController from "../../../Calendar/Types/IBookingController";
import SchedulingStep, { ISchedulingStepConfig, ISchedulingStepProps } from "../Components/SchedulingStep";
import { ISchedulerProps } from "../Components/SchedulingStepWrapper";

const AppContextSchedulingStep: React.FC<ISchedulingStepConfig & ISchedulerProps> = AppContextValueProvider<
  ISchedulingStepProps,
  ComponentProps<typeof SchedulingStep>
>({
  Component: SchedulingStep,
  map: ({ bookingController, reservation, setBookingController, setReservation }) => {
    if (!bookingController?.selected) throw new Error("Booking schedule is required.");
    return {
      bookingController: bookingController as Defined<IBookingController>,
      reservation,
      clearReservation: () => setReservation(null),
      setBookingSchedule: setBookingController as ISchedulingStepProps["setBookingSchedule"]
    };
  }
});

export default AppContextSchedulingStep;
