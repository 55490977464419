import { useCallback } from "react";
import { ITriggeredHookResponse, TriggeredHook } from "sonobello.utilities.react";
import { useApi } from "sonobello.utilities.react.axios";

import { ObxAxiosInstance } from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";

interface ApiPayload {
  /** The message of the api payload */
  message: string;
  /** The service associated with the api request */
  service: string;
}

const useReportConnectionError: TriggeredHook<unknown, string> = (): ITriggeredHookResponse<unknown, string> => {
  const {
    res: apiResponse,
    err: apiError,
    loading,
    setReq
  } = useApi<unknown, ApiPayload, undefined, unknown>({
    request: {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/${ApiRequestPaths.errorReport}`,
      custom: undefined
    },
    config: { axiosInstance: ObxAxiosInstance }
  });

  const execute = useCallback(
    (service: string) => {
      const payload: ApiPayload = {
        message: `Connection error with ${service}`,
        service
      };
      setReq(r => {
        return { ...r, payload, custom: undefined };
      });
    },
    [setReq]
  );

  return {
    result: apiResponse || null,
    error: Boolean(apiError),
    isLoading: loading,
    execute
  };
};

export default useReportConnectionError;
