import { useCallback, useState } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import { Customer } from "../../../../dtos/Customer";
import { LeadResult } from "../../../../dtos/LeadResult";
import OpsUtilities from "../../../Constants/OpsUtility";
import IReservation from "../../../Types/IReservation";
import OpsReservation from "../../../Types/OpsReservation";
import { IConfirmedAppointmentResponse } from "../Components/ConfirmationStepWrapper";
import { IAppointmentNoteProps } from "../Types/IAppointment";
import { IUseCreateLeadResultProps } from "./UseCreateLeadResult";

export interface IUseConfirmAppointmentProps {
  /** {@inheritdoc IAppointmentNoteProps} */
  appointmentNoteProps: IAppointmentNoteProps;
  /** The properties of the customer who will attend the confirmed appointment. */
  customer: Pick<Customer, "id" | "opportunity" | "dateOfBirth" | "email" | "phoneNumber" | "zipCode">;
  /** The identifier of the session's lead. */
  leadId: string;
  /** The hook which creates the result for the session's lead. */
  useCreateLeadResult: TriggeredHook<LeadResult, LeadResult, boolean, IUseCreateLeadResultProps>;
}

/** The configuration properties for {@link useConfirmHybridAppointment}. */
export interface IUseConfirmHybridAppointmentConfig {
  /** The hook which creates the appointment for the OPS process. */
  useCreateOpsAppointment: TriggeredHook<
    IConfirmedAppointmentResponse,
    OpsReservation,
    boolean,
    IUseConfirmAppointmentProps
  >;
  /** The hook which creates the appointment for the legacy process. */
  useCreateLegacyAppointment: TriggeredHook<
    IConfirmedAppointmentResponse,
    IReservation,
    boolean,
    IUseConfirmAppointmentProps
  >;
}

/** A hook which can be configured to dispatch the appointment to either the OPS or legacy process. */
const useConfirmHybridAppointment: TriggeredHook<
  IConfirmedAppointmentResponse,
  IReservation,
  boolean,
  IUseConfirmHybridAppointmentConfig & IUseConfirmAppointmentProps
> = ({ useCreateOpsAppointment, useCreateLegacyAppointment, ...rest }) => {
  const [isLegacy, setIsLegacy] = useState<boolean>(true);

  const {
    result: legacyResult,
    error: legacyError,
    isLoading: legacyIsLoading,
    execute: legacyExecute
  } = useCreateLegacyAppointment(rest);

  const {
    result: opsResult,
    error: opsError,
    isLoading: opsIsLoading,
    execute: opsExecute
  } = useCreateOpsAppointment(rest);

  const execute = useCallback((reservation: IReservation) => {
    if (OpsUtilities.isCenterSupported(reservation.center.id)) {
      setIsLegacy(false);
      if (!(reservation instanceof OpsReservation)) throw "Expected OpsReservation type, but could not resolve to it.";
      return opsExecute(reservation as OpsReservation);
    } else {
      setIsLegacy(true);
      return legacyExecute(reservation);
    }
  }, []);

  return {
    result: isLegacy ? legacyResult : opsResult,
    error: isLegacy ? legacyError : opsError,
    isLoading: isLegacy ? legacyIsLoading : opsIsLoading,
    execute
  };
};

export default useConfirmHybridAppointment;
