import { useCallback, useEffect, useState } from "react";
import { Nullable, TriggeredHook } from "sonobello.utilities.react";

import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import { CalendarFailure } from "../Components/CalendarManager";
import { ICalendarHookProps } from "../Components/IdentityCalendarWorker";
import CalendarFailureStatusCodeMap from "../Types/CalendarFailureStatusCodeMap";
import ICalendar from "../Types/ICalendar";
import { IOpsCalendarResponse } from "../Types/IOpsCalendarResponse";
import OpsCalendar from "../Types/OpsCalendar";

export interface IOpsCalendarHookProps {
  /** The id of the session's lead. */
  leadId: string;
  /** The id of the session's customer. */
  customerId: string;
}

/** Hook which obtains the OPS calendar for the given center id. */
export const useGetOpsCenterCalendar: TriggeredHook<
  ICalendar,
  undefined,
  CalendarFailure,
  IOpsCalendarHookProps & ICalendarHookProps
> = ({ centerId, centerTimeZone, leadId, customerId }) => {
  const [calendar, setCalendar] = useState<Nullable<ICalendar>>(null);
  const { res, err, loading, setReq } = useObx<IOpsCalendarResponse>("Get Calendar", {
    config: { params: { numberOfDays: process.env.REACT_APP_CALENDAR_MAXIMUM_DAYS } },
    preventOnUnauthorizedRecovery: true
  });
  useEffect(() => setCalendar(res?.payload ? new OpsCalendar(res.payload, centerTimeZone) : null), [res]);
  const execute = useCallback(
    () => setReq(r => r && { ...r, url: ApiRequestPaths.getOpsCenterCalendar(leadId, customerId, centerId) }),
    []
  );
  return {
    result: calendar,
    error: err?.error.response
      ? CalendarFailureStatusCodeMap[err.error.response.status] || CalendarFailure.NotFound
      : null,
    isLoading: loading,
    execute
  };
};
