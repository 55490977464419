import { Breakpoint, Theme, useMediaQuery, useTheme } from "@mui/material";

// A hook which gets the current screen breakpoint size from the MUI theme engine.
const UseWidth = () => {
  const theme: Theme = useTheme();
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

export default UseWidth;
