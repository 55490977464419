import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  BottomNavigation,
  BottomNavigationAction,
  BottomNavigationActionProps,
  CircularProgress,
  Paper,
  styled
} from "@mui/material";
import React from "react";
import { UserEvent } from "sonobello.utilities.react.mui";
import { UserEventType } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEvent";

import classNames from "../../Constants/ClassNames";
import INextButtonProps from "../Types/INextButtonProps";

/** The step's 'next' button when in mobile mode.
 * @remarks Anchors to the bottom of the screen using the MUI {@link BottomNavigation} component.
 */
const NextButtonMobile: React.FC<INextButtonProps> = ({ disabled, label, isLoading, onClick, onEvent }) => {
  const handleClick = () => {
    onEvent(new UserEvent(UserEventType.Click, classNames.stepNextButtonMobile));
    onClick();
  };
  return (
    <Paper
      className={`${classNames.stepNextButton} ${classNames.stepNextButtonMobile}`}
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation showLabels onChange={handleClick}>
        <StyledBottomNavigationAction
          label={label}
          icon={isLoading ? <CircularProgress color="inherit" sx={{ ml: 1 }} /> : <NavigateNextIcon />}
          disabled={disabled || isLoading}
          sx={{ maxWidth: "unset", fontSize: "12pt" }}
        />
      </BottomNavigation>
    </Paper>
  );
};

const StyledBottomNavigationAction = styled(BottomNavigationAction)<BottomNavigationActionProps>(
  ({ disabled, theme }) => ({
    backgroundColor: disabled ? theme.palette.action.disabled : undefined,
    "&": { flexDirection: "row-reverse" },
    "& .MuiSvgIcon-root": {
      fontSize: theme.typography.h5.fontSize,
      height: "2rem",
      width: "2rem",
      marginRight: "-1rem"
    },
    "& .MuiBottomNavigationAction-label": { fontSize: theme.typography.h5.fontSize }
  })
);

export default NextButtonMobile;
