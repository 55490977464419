import { IComponentParent } from "sonobello.utilities.react";

import AppContextValueProvider from "../../App/Compositions/AppContextValueProvider";
import AppRootRedirect from "../Components/AppRootRedirect";
import IPathObserver from "../Types/IPathObserver";

/** A {@link AppRootRedirect} which redirects to the flow specified by the app context. */
const AppContextAppRootRedirect: React.FC<IComponentParent & IPathObserver> = AppContextValueProvider({
  Component: AppRootRedirect,
  map: appContextProps => ({ appContextProps })
});

export default AppContextAppRootRedirect;
