import { useContext } from "react";
import { IContextValueProvider } from "sonobello.utilities.react";
import { UserEventContext } from "sonobello.utilities.react.mui";
import { UserEventContextProps } from "sonobello.utilities.react.mui/dist/components/UserEventContext/UserEventContext";

import IUserEventSource from "../../Steps/Types/IUserEventSource";

/** A Value Provider composed from the {@link UserEventContextProps}.
 * @typeParam TComponent - The component props which extend {@link IUserEventSource}.
 */
function UserEventContextValueProvider<TComponent extends IUserEventSource>({
  Component
}: Omit<IContextValueProvider<UserEventContextProps, IUserEventSource, TComponent>, "map">): React.FC<
  Omit<TComponent, keyof IUserEventSource>
> {
  return componentProps => {
    const { onEvent } = useContext(UserEventContext);
    return Component({ ...componentProps, onEvent } as unknown as TComponent);
  };
}

export default UserEventContextValueProvider;
