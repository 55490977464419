import { Nullable } from "sonobello.utilities.react";

/** A location's address broken into consumable parts. */
interface IAddressParts {
  /** The streed address portion of the address. */
  readonly streetAddress: string;
  /** The citcy, state, and zip portion of the address. */
  readonly cityStateZip: Nullable<string>;
}

export class AddressParts implements IAddressParts {
  streetAddress: string;
  cityStateZip: Nullable<string>;

  constructor(address: string) {
    const addressParts = address.split(",");

    // if there is only one part, then it is the street address
    if (addressParts.length === 1) {
      this.streetAddress = addressParts[0];
      this.cityStateZip = null;
      return;
    }

    // build the street address from all parts except for the last two (state and zip)
    this.streetAddress =
      addressParts.length > 3
        ? addressParts.reduce((collector, part, index) => {
            if (index < addressParts.length - 2) collector += collector ? `, ${part.trim()}` : part.trim();
            return collector;
          }, "")
        : addressParts[0].trim();

    // the state and zip are always the last two tokens
    this.cityStateZip = `${addressParts[addressParts.length - 2].trim()}, ${addressParts[
      addressParts.length - 1
    ].trim()}`.trim();
  }
}

export default IAddressParts;
