import { Box, BoxProps, Breakpoint } from "@mui/material";
import React from "react";

import UseWidth from "../../utils/UseWidth";

// map the MUI current breakpoint to the allowed width of the step content as a % of the total screen width.
const widthMap: Record<Breakpoint, number> = {
  xs: 100,
  sm: 100,
  md: 75,
  lg: 58,
  xl: 50
};

/** A container for the main content of the page which resizes its width according to screen size. */
const MainResizingContainer: React.FC<BoxProps> = ({ children, sx, ...rest }) => {
  const width = UseWidth();
  return (
    <Box
      {...rest}
      sx={{
        width: `${widthMap[width]}%`,
        height: "100%",
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

export default MainResizingContainer;
