import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { IControl } from "sonobello.utilities.react";

import { ICalendarTopicCustom } from "../../Calendar/Types/ICalendarTopicCustom";
import CalendarTopicHub from "../Types/CalendarTopicHub";
import { CalendarTopicPayload, RawCalendarTopicPayload } from "../Types/CalendarTopicPayload";
import { ITopicPayload, TopicSource } from "../Types/TopicSource";
import { ISignalRListenerProps } from "./SignalRConnection";

/** A {@link TopicSource} for signalR topics that issue calendar updates.*/
class CalendarTopicSource extends TopicSource<ICalendarTopicCustom> {
  constructor(centerId: string, custom: ICalendarTopicCustom) {
    super(`update_${centerId}`, custom);
  }
}

export interface ICalendarManagerProps extends IControl<ITopicPayload<ICalendarTopicCustom, CalendarTopicPayload>[]> {
  /** Callback to subscribe to the calendar topics for the specified center ids. */
  subscribe: Dispatch<ICalendarTopicCustom[]>;
  /** Callback to unsubscribe to the calendar topic for the specified center id. */
  unsubscribe: Dispatch<string>;
}

export type ICalendarSignalRListenerProps = ISignalRListenerProps<ICalendarTopicCustom, RawCalendarTopicPayload>;

export interface ICalendarSignalRListenerConfig {
  /** The component which is controlled by the SignalR calendar updates. */
  CalendarManager: React.FC<ICalendarManagerProps>;
}

/** Manages SignalR subsriptions and message payloads for Calendar topics.
 * @remarks Swallows message updates that have timestamps older than the current value.
 */
const CalendarSignalRListener: React.FC<ICalendarSignalRListenerProps & ICalendarSignalRListenerConfig> = ({
  value,
  subscribe,
  unsubscribe,
  CalendarManager
}) => {
  const [topicHub, setTopicHub] = useState(new CalendarTopicHub(value));

  useEffect(() => setTopicHub(hub => hub.update(value)), [value]);

  const handleSubscribe = useCallback(
    (topicSourcesCustom: ICalendarTopicCustom[]) => {
      subscribe(topicSourcesCustom.map(c => new CalendarTopicSource(c.id, c)));
    },
    [subscribe]
  );
  // return <> </>;
  return <CalendarManager unsubscribe={unsubscribe} subscribe={handleSubscribe} value={topicHub.topicPayloads} />;
};

export default CalendarSignalRListener;
