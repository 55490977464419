export enum AppPath {
  welcomeStep,
  qualifyStep,
  medicalPartOneStep,
  medicalPartTwoStep,
  medicalA1CStep,
  centerSelectionStep,
  schedulingStep,
  confirmationStep,
  thankYou,
  completedView,
  endedSessionView,
  noAvailabilityView,
  nonCandidateView,
  expiredReservationView,
  invalidLinkView,
  somethingWentWrongView
}

const stepPrefix = "/app";

/** A mapping of all of the application paths to their respective pages. */
const Routes = {
  /** The path prefix before all 'step' application pages. */
  stepPrefix
  // TODO: Move the paths from View and Step to this file in next clean code cleanup.
  // paths: {
  // } as Record<AppPath, string>,
};

export default Routes;
