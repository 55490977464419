import axios, { AxiosInstance } from "axios";
import { useContext } from "react";
import {
  BearerTokenProvider,
  UseApiConfig,
  UseApiRequest,
  UseApiResponse,
  useApi
} from "sonobello.utilities.react.axios";

import AppContext from "../AppContext";
import { ObxError } from "../dtos/ObxError";

export const ObxAxiosInstance: AxiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export type UseObxCustom<T extends Record<string, unknown> = Record<string, unknown>> = {
  /** The name of the process.
   * @remarks specify a value of `undefined` if you do not want to show error messages.
   */
  name?: string;
} & T;

export type UseObxConfig = Omit<UseApiConfig, "axiosInstance">;
export interface UseObxRequest<TRequestPayload = undefined, TCustom = undefined>
  extends Omit<UseApiRequest<TRequestPayload, TCustom>, "custom"> {
  custom?: TCustom;
}
export interface UseObxProps<TRequestPayload = undefined, TCustom = undefined> {
  request?: UseObxRequest<TRequestPayload, TCustom>;
  config?: UseObxConfig;
}

/** An overloaded useApi hook which directly supplies the Axios instance to the OBX API backend and fires notifications on
 * failed or recovered requests.
 * @typeParam TResponsePayload - the type expected on the response payload, if any.
 * @typeParam TRequestPayload - the type expected on the request payload, if any.
 * @typeParam TCustom - the type expected on the custom props object, which will be merged with {@link UseObxCustom}.
 * @typeParam TResponseError - the type expected on an error response payload, if any.
 * @param name - convenience param for the name of the hook, maps to {@link UseObxCustom.name}.
 * @param request - see {@link UseApiProps.request} sans header auth params.
 * @param config - the initial {@link UseApiProps.config} sans {@link UseApiConfig.axiosInstance}.
 * @param shouldRefreshToken - a flag indicating that this hook should automatically refresh the token when it expires. Defaults to `true`.
 */
const useObx = <
  TResponsePayload = undefined,
  TRequestPayload = undefined,
  TCustom extends Record<string, unknown> = Record<string, unknown>,
  TResponseError extends ObxError = ObxError
>(
  name?: string,
  request?: UseObxRequest<TRequestPayload, TCustom>,
  config?: UseObxConfig,
  requestBearerTokenProvider?: BearerTokenProvider
): UseApiResponse<TRequestPayload, UseObxCustom<TCustom>, TResponseError, TResponsePayload> => {
  const { bearerTokenProvider } = useContext(AppContext);
  const hook = useApi<TResponsePayload, TRequestPayload, UseObxCustom<TCustom>, TResponseError>({
    request: { ...request, custom: { name, ...request?.custom } as UseObxCustom<TCustom> },
    config: {
      axiosInstance: ObxAxiosInstance,
      authProvider: requestBearerTokenProvider || bearerTokenProvider,
      isCancellable: false,
      ...config
    }
  });
  return hook;
};

export default useObx;
