import { CalendarFailure } from "../Components/CalendarManager";

/** A mapping of an http request response code to a {@link CalendarFailure}. */
const CalendarFailureStatusCodeMap: Record<string, CalendarFailure> = {
  401: CalendarFailure.Unauthorized,
  404: CalendarFailure.NotFound,
  400: CalendarFailure.BadRequest
};

export default CalendarFailureStatusCodeMap;
