import { useContext } from "react";
import { IContextValueProvider } from "sonobello.utilities.react";

import AppContext, { AppContextProps } from "../../../AppContext";

/** A Value Provider composed from the {@link AppContextProps}. */
function AppContextValueProvider<TFulfilledProps, TComponent extends TFulfilledProps>({
  Component,
  map
}: IContextValueProvider<AppContextProps, TFulfilledProps, TComponent>): React.FC<
  Omit<TComponent, keyof TFulfilledProps>
> {
  return componentProps => {
    const contextProps = useContext(AppContext);
    return Component({ ...componentProps, ...map(contextProps) } as unknown as TComponent);
  };
}

export default AppContextValueProvider;
