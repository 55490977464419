import { Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";

import { ITimeLocaleSelectorProps } from "./ScheduleSlotSelector";

export const classNames = {
  loadingButton: "scheduleSlotTimeLocaleSelector-loadingButton"
};

/** A form selector which allows the user to elect an appointment time from the given options. */
const ScheduleSlotTimeLocaleSelector: React.FC<ITimeLocaleSelectorProps> = ({
  disabled,
  label,
  loadingValue,
  options,
  value,
  onChange
}) => {
  const bookingButtons = options.reduce((collector, scheduleSlot) => {
    const active = value?.startTimeUtc.equals(scheduleSlot.startTimeUtc);
    const label = scheduleSlot.startTimeLocal.toLocaleString(DateTime.TIME_SIMPLE);
    const loading = loadingValue?.startTimeUtc.equals(scheduleSlot.startTimeUtc);
    if (active || !scheduleSlot.isOccupied)
      collector.push(
        <Grid item key={label}>
          <Button
            className={loading ? classNames.loadingButton : undefined}
            color={active ? "primary" : "inherit"}
            disabled={!active && (disabled || scheduleSlot.isOccupied)}
            onClick={active ? undefined : () => onChange(scheduleSlot)}
            sx={{ minWidth: "6rem", minHeight: "2.2rem" }}
          >
            {loading ? <CircularProgress size="1.5rem" /> : label}
          </Button>
        </Grid>
      );
    return collector;
  }, [] as React.ReactNode[]);
  return (
    <Stack spacing={1}>
      <Typography variant="h6">{label}</Typography>
      <Divider />
      <Grid container spacing={1}>
        {bookingButtons.length ? bookingButtons : <Typography variant="body1">No Appointments</Typography>}
      </Grid>
    </Stack>
  );
};

export default ScheduleSlotTimeLocaleSelector;
