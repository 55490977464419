import { ComponentProps } from "react";

import AppContextValueProvider from "../../App/Compositions/AppContextValueProvider";
import ReservationCounter, { IReservationCounterProps } from "../Components/ReservationCounter";

/** A {@link ReservationCounter} with props provided by the App Context. */
const AppContextReservationCounter: React.FC = AppContextValueProvider<
  IReservationCounterProps,
  ComponentProps<typeof ReservationCounter>
>({
  Component: ReservationCounter,
  map: ({ pathName, sessionKeyCState, reservation, router, setReservation }) => ({
    pathName,
    router,
    sessionKey: sessionKeyCState,
    value: reservation,
    onExpiration: () => setReservation(null)
  })
});

export default AppContextReservationCounter;
