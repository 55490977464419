import { DateTime } from "luxon";
import React from "react";
import { CompactDatePicker, UserEvent, UserEventType } from "sonobello.utilities.react.mui";

import { IDateSelectorProps } from "./ScheduleSlotSelector";

/** A date selector to be used when in desktop mode. */
const MobileDateSelector: React.FC<IDateSelectorProps> = ({
  minDate,
  maxDate,
  value,
  onChange,
  onEvent,
  shouldDisableDate
}) => {
  const handleChange = (date?: DateTime) => {
    if (!date) return;
    onEvent(new UserEvent(UserEventType.Change, "compact-date-picker", date?.toISO()));
    onChange(date.plus(0));
  };
  return (
    <CompactDatePicker
      value={value || undefined}
      onChange={handleChange}
      pastDateMax={minDate.toISODate()}
      futureDateMax={maxDate.toISODate()}
      shouldDisableDate={shouldDisableDate}
    />
  );
};

export default MobileDateSelector;
