import { DateTime } from "luxon";
import { Nullable } from "sonobello.utilities.react";

import { Customer } from "../../../../dtos/Customer";
import { buildNote } from "../../../../types/Notes";
import { ICalendarService } from "../../../Calendar/Types/ICalendar";
import { IAppointmentNoteProps, appointmentNoteTagsConfig } from "./IAppointment";

/** A primitive version of {@link LegacyAppointmentRequest}, lacking complex types. */
export interface ILegacyAppointmentRequest {
  /** The id of the center where the appointment is scheduled. */
  readonly centerId: string;
  /** The id of the customer for which the appointment is scheduled. */
  readonly customerId: string;
  /** The id of the lead which resulted in the appointment. */
  readonly leadId: string;
  /** The services which will be conducted during the appointment. */
  readonly services: ICalendarService[];
  /** The ISO start time of the appointment in UTC. */
  readonly startTimeUtc: string;
  /** The ISO end time of the appointment in UTC. */
  readonly endTimeUtc: string;
  /** Patient specific notes for the appointment. */
  readonly note: string;
  /** The data model required for the appointment to be considered for processing by the double booker. */
  readonly easyPathInput: {
    /** The date of birth for the customer attending the appointment.
     * @remarks This property must not be present if it doesn't have a value. The server's interpretation of
     * this variable is not done corredtly.
     */
    dob?: string;
    /** The id of the opportunity assigned to the appointment's customer. */
    opportunityId: string;
    /** The id of the lead which resulted in the appointment. */
    leadId: string;
    /** The phone number of the appointment's customer. */
    phoneNumber: Nullable<string>;
    /** The email of the appointment's customer. */
    email: Nullable<string>;
    /** The zip code of the appointment's customer. */
    zipCode: Nullable<string>;
  };
}

/** {@inheritdoc ILegacyAppointmentRequest} */
class LegacyAppointmentRequest implements ILegacyAppointmentRequest {
  readonly easyPathInput: ILegacyAppointmentRequest["easyPathInput"];
  readonly centerId: string;
  readonly customerId: string;
  readonly leadId: string;
  readonly services: ICalendarService[];
  readonly startTimeUtc: string;
  readonly endTimeUtc: string;
  readonly note: string;

  constructor(
    customer: Pick<Customer, "id" | "dateOfBirth" | "opportunity" | "email" | "phoneNumber" | "zipCode">,
    centerId: string,
    leadId: string,
    service: ICalendarService,
    startTimeUtc: DateTime,
    endTimeUtc: DateTime,
    noteTagProps: IAppointmentNoteProps
  ) {
    this.centerId = centerId;
    this.customerId = customer.id;
    this.leadId = leadId;
    this.services = [service];
    this.startTimeUtc = startTimeUtc.toISO();
    this.endTimeUtc = endTimeUtc.toISO();
    this.note = buildNote(appointmentNoteTagsConfig, noteTagProps);
    this.easyPathInput = {
      opportunityId: customer.opportunity.id,
      leadId,
      phoneNumber: customer.phoneNumber || null,
      email: customer.email || null,
      zipCode: customer.zipCode || null
    };
    if (customer.dateOfBirth) this.easyPathInput.dob = customer.dateOfBirth;
  }
}

export default LegacyAppointmentRequest;
