import { useCallback, useEffect, useRef } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import useObx from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import IReservation from "../../../Types/IReservation";
import { ReservationRequest } from "./UseCreateLegacyReservation";
import { IUseReleaseReservationProps } from "./UseReleaseHybridReservation";

/** Handles the network requests necessary to release a held reservation for the legacy calendar process. */
const useReleaseLegacyReservation: TriggeredHook<
  void,
  IReservation,
  boolean,
  Pick<IUseReleaseReservationProps, "leadId">
> = props => {
  const propsRef = useRef(props);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const { setReq, err, loading } = useObx<unknown, ReservationRequest>("Release Reservation", { method: "post" });

  const execute = useCallback(
    (reservation: IReservation) =>
      setReq(
        r =>
          r && {
            ...r,
            url: ApiRequestPaths.postReleaseLegacyReservationUrl(
              propsRef.current.leadId,
              reservation.center.id,
              reservation.service.id
            ),
            payload: new ReservationRequest(reservation.slot)
          }
      ),
    []
  );

  return { result: null, error: Boolean(err), isLoading: loading, execute };
};

export default useReleaseLegacyReservation;
