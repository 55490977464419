/** Necessary requirement of using `react-error-boundary`, declares to the react framework that this is
 * a client only component (as opposed to a server component, the default is 'shared').
 * @see {@link https://github.com/reactjs/rfcs/blob/main/text/0227-server-module-conventions.md}
 */
"use client";

import React, { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";

import SomethingWentWrongView from "./components/views/SomethingWentWrongView";
import { ErrorReport } from "./types/ErrorReport";
import ApiRequestPaths from "./V2/Constants/ApiRequestPaths";

interface AppErrorBoundaryProps {
  goToSomethingWentWrongView: () => void;
}

/** The error boundary which surrounds the application and prevents a 'white screen' when an uncaught exception is
 * thrown.
 */
const AppErrorBoundary: React.FC<PropsWithChildren<AppErrorBoundaryProps>> = ({
  children,
  goToSomethingWentWrongView
}) => {
  const onError = (error: Error, info: { componentStack: string }) => {
    window.fetch(`${process.env.REACT_APP_API_URL}/${ApiRequestPaths.errorReport}`, {
      keepalive: true,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(new ErrorReport(error, info.componentStack))
    });
    goToSomethingWentWrongView();
  };
  return (
    <ErrorBoundary fallback={<SomethingWentWrongView />} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundary;
