/* eslint-disable @typescript-eslint/no-unused-vars */

import { Optional } from "sonobello.utilities.react.mui";

interface LocalStorageConfig {
  /** The location key of the data value within storage. */
  key: string;
  /** Flag indicating that this item should be skipped when programmatically clearing storage.
   * @remarks If unset, this is treated as `false`.
   */
  preventClear?: boolean;
}

interface LocalStorageConfigs {
  /** If the experiment identifier was anonymized then its value is stored here. */
  anonymousExperimentId: LocalStorageConfig;
  /** The confirmed appointment that the customer has booked. */
  appointment: LocalStorageConfig;
  /** A record indicating if the prerequisites to request a calendar have been met. */
  calendarPrerequisitesMet: LocalStorageConfig;
  /** Customer record for the current session. */
  customer: LocalStorageConfig;
  /** Customer Note record for the current session. */
  customerNote: LocalStorageConfig;
  /** Center record for the current session. */
  center: LocalStorageConfig;
  /** Centers record for the current session. */
  centers: LocalStorageConfig;
  /** Record for the disqualifying reasons available to the session. */
  disqualifyingReasons: LocalStorageConfig;
  /** The current flow for the session. */
  flow: LocalStorageConfig;
  /** Flow Id record for the current session. */
  flowId: LocalStorageConfig;
  /** Flag indicating if the device used for this local session has been recorded. */
  isDeviceLogged: LocalStorageConfig;
  /** Lead Id record for the current session. */
  leadId: LocalStorageConfig;
  /** Lead Result record for the current session. */
  leadResult: LocalStorageConfig;
  /** A calendar slot that has been reserved for booking confirmation in the legacy process. */
  legacyReservation: LocalStorageConfig;
  /** The medical form record for the session. */
  medicalForm: LocalStorageConfig;
  /** A calendar slot that has been reserved for booking confirmation in the OPS process. */
  opsReservation: LocalStorageConfig;
  /** The current step of the session. */
  step: LocalStorageConfig;
  /** The session key from the obx url used to start the session. */
  sessionKey: LocalStorageConfig;
  /** The connection info used to establish a SignalR connection. */
  signalRConnectionInfo: LocalStorageConfig;
  /** The current service that will be booked for the session. */
  service: LocalStorageConfig;
  /** Token record for the current session. */
  token: LocalStorageConfig;
  /** Record for the application version compatible with the cache. */
  version: LocalStorageConfig;
}

/** Configurations for static cached objects using static keys. */
export const LocalStorageConfigs: LocalStorageConfigs = {
  anonymousExperimentId: { key: "anonymousExperimentId" },
  appointment: { key: "appointment" },
  calendarPrerequisitesMet: { key: "calendarPrerequisitesMet" },
  center: { key: "center" },
  centers: { key: "centers" },
  customer: { key: "customer" },
  customerNote: { key: "customerNote" },
  disqualifyingReasons: { key: "disqualifyingReasons" },
  flow: { key: "flow" },
  flowId: { key: "flowId" },
  isDeviceLogged: { key: "deviceLogged" },
  leadId: { key: "leadId", preventClear: true },
  leadResult: { key: "leadResult" },
  legacyReservation: { key: "legacyReservation" },
  medicalForm: { key: "medicalForm" },
  opsReservation: { key: "opsReservation" },
  sessionKey: { key: "sessionKey", preventClear: true },
  signalRConnectionInfo: { key: "signalRConnectionInfo" },
  service: { key: "service" },
  step: { key: "step" },
  token: { key: "token" },
  version: { key: "version" }
};

/** Save the given JSON to local storage using the given key. */
export const saveToLocal = (obj: unknown, key: string): void => {
  if (obj === undefined) localStorage.removeItem(key);
  else localStorage.setItem(key, JSON.stringify(obj));
};

/** Attempt to load an object from the cache with the given key. */
export const loadFromLocal = <T>(key: string): T | undefined => {
  const store = localStorage.getItem(key);
  if (!store || store === "undefined") return undefined;
  const value = JSON.parse(store);
  return !value ? undefined : (value as T);
};

/** Attempt to load an raw string value from the cache with the given key. */
export const loadFromLocalRaw = (key: string): Optional<string> => localStorage.getItem(key) || undefined;

/** Clear local storage respecting established configurations. */
export const clearLocalStorage = (): void => {
  const configs = Object.values(LocalStorageConfigs);
  Object.keys(localStorage).forEach((key: string) => {
    if (key && !configs.find(c => c.key === key)?.preventClear) localStorage.removeItem(key);
  });
  sessionStorage.clear();
};
