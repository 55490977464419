import { Box, Typography } from "@mui/material";
import { DateTime, Interval } from "luxon";
import React, { DispatchWithoutAction, useEffect, useRef, useState } from "react";
import { IControl } from "sonobello.utilities.react";
import { Nullable, SBPalette } from "sonobello.utilities.react.mui";

import useInterval from "../../../utils/UseInterval";
import { IExpiredReservationViewRouter } from "../../Routing/Types/IRouter";
import IReservation from "../../Types/IReservation";

export const classNames = {
  timerValue: "reservationCounterTimeRemaining"
};

export interface IReservationCounterProps extends IControl<Nullable<Pick<IReservation, "expirationUtc">>> {
  /** The view router for the 'expired reservation' view. */
  router: IExpiredReservationViewRouter;
  /** The key for the session. */
  sessionKey: string;
  /** Callback to be executed when the reservation expires. */
  onExpiration: DispatchWithoutAction;
}

export class TimeLeft {
  /** The number of whole minutes remaining in the interval. */
  readonly minutes: number;
  /** The total number of seconds remaining in the interval. */
  readonly seconds: number;
  /** The human readable readout of the interval. */
  readonly timerReadout: string;

  constructor(expiration: DateTime) {
    const diff = Interval.fromDateTimes(DateTime.now(), expiration);
    this.minutes = diff.length("minutes");
    this.seconds = diff.length("seconds");
    const secondsRemainingInThisMinute = this.seconds % 60;
    this.timerReadout = `${Math.floor(this.minutes)}:${secondsRemainingInThisMinute < 10 ? 0 : ""}${Math.floor(
      secondsRemainingInThisMinute
    )}`;
  }
}

/** A component which renders the amount of time remaining in a given reservation and counts down until 00:00 at which
 * point the `onExpiration()` callback is executed and the user is sent to the ExpiredReservation view.
 */
const ReservationCounter: React.FC<IReservationCounterProps> = ({ value, router, sessionKey, onExpiration }) => {
  const [timeLeftState, setTimeLeftState] = useState(value && new TimeLeft(value.expirationUtc));
  const reservationRef = useRef(value);

  useEffect(() => {
    reservationRef.current = value;
    setTimeLeftState(value && new TimeLeft(value.expirationUtc));
  }, [value]);

  useInterval(() => {
    if (reservationRef.current)
      if (reservationRef.current.expirationUtc > DateTime.now())
        return setTimeLeftState(new TimeLeft(reservationRef.current.expirationUtc));
      else {
        onExpiration();
        router.goToExpiredReservationView({ sessionKey });
      }
    else setTimeLeftState(null);
  }, 1000);

  return (
    <Box sx={{ width: "100%", backgroundColor: SBPalette.light.watermarkLightGray.main }}>
      <Typography variant="body1" align="center" color={SBPalette.light.watermarkLightGray.contrastText}>
        <strong>
          {timeLeftState ? (
            <span>
              Holding Reservation: <span className={classNames.timerValue}>{timeLeftState.timerReadout}</span>
            </span>
          ) : (
            "Please Select a Date and Time"
          )}
        </strong>
      </Typography>
    </Box>
  );
};

export default ReservationCounter;
