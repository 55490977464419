import React from "react";

import { Question } from "../../../types/MedicalForm";
import { isMobileView } from "../../../utils/Constants";
import { MedicalStepHeader } from "./MedicalStep";
import FormQuestion from "./MedicalStep.FormQuestion";

interface MedicalQuestionsProps {
  questions: Question[];
  pageIndex: number;
  setQuestions: (questions: Question[]) => void;
}

/** Component which contains the content for the Medical verification step. */
const MedicalQuestions: React.FC<MedicalQuestionsProps> = ({ pageIndex, questions, setQuestions }) => {
  const showMobile = isMobileView();
  const onClickAnswer = (index: number, value: boolean) => {
    questions[index] = { ...questions[index], answer: value };
    setQuestions([...questions]);
  };
  return (
    <>
      {MedicalStepHeader}
      {questions.map((q, index) => (
        <FormQuestion
          eventId={`p${pageIndex}q${index}`}
          question={q}
          onChange={v => onClickAnswer(index, v)}
          isMobile={showMobile}
          key={index}
        />
      ))}
    </>
  );
};

export default MedicalQuestions;
