import CalendarIcon from "@mui/icons-material/CalendarToday";
import { Button, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { Nullable } from "sonobello.utilities.react.mui";

import { isMobileView } from "../../../utils/Constants";
import ConsultInfo from "../../../V2/Steps/Components/ConsultInfo";
import ICenter from "../../../V2/Types/ICenter";
import ViewWrapper from "../ViewWrapper";
import Confetti from "./ThankYouView.Confetti";

if (!process.env.REACT_APP_THANK_YOU_URL) throw "Required env variable 'REACT_APP_THANK_YOU_URL' is not set.";
const thankYouHref = process.env.REACT_APP_THANK_YOU_URL;

export class IThankYouViewProps {
  /** The properties of the appointment that will be shown to the user. */
  appointment: { startTimeUtc: string; endTimeUtc: string };
  /** The properties of the center that will be shown to the user. */
  center: ICenter;
  /** The url at which the user can obtain a calendar event object. */
  calendarEventUrl: Nullable<string>;

  constructor(
    center: ICenter,
    appointment: { startTimeUtc: string; endTimeUtc: string },
    calendarEventUrl: Nullable<string>
  ) {
    this.appointment = { startTimeUtc: appointment.startTimeUtc, endTimeUtc: appointment.endTimeUtc };
    this.center = center;
    this.calendarEventUrl = calendarEventUrl;
  }
}

/** View which thanks the user for confirming their booking and displayed the details of the appointment. */
const ThankYouView: React.FC<IThankYouViewProps> = ({ appointment, calendarEventUrl, center }) => {
  const showMobile = isMobileView();
  return (
    <ViewWrapper
      header="Congrats!"
      subheader="Your Consultation is Confirmed."
      nextButtonProps={{ label: "See Our Success Stories", onClick: () => (location.href = thankYouHref) }}
      hideNeedHelpDialog
    >
      <Confetti />
      <Stack justifyContent="center" alignItems="center" spacing={showMobile ? 3 : 6}>
        <Stack spacing={1}>
          <Typography variant="h5">You&apos;re One Step Closer to a New You!</Typography>
          <Typography variant="body1">
            You will receive an email and text message with all your consultation information for your records.
          </Typography>
        </Stack>
        {calendarEventUrl && (
          <Button color="primary" startIcon={<CalendarIcon />} onClick={() => window.open(calendarEventUrl, "_blank")}>
            Add to Calendar
          </Button>
        )}
        <ConsultInfo
          center={center}
          startDateTime={DateTime.fromISO(appointment.startTimeUtc, { zone: center.timeZone })}
          endDateTime={DateTime.fromISO(appointment.endTimeUtc, { zone: center.timeZone })}
        />
      </Stack>
    </ViewWrapper>
  );
};

export default ThankYouView;
