import React, { useEffect } from "react";
import { IComponentParent, Nullable } from "sonobello.utilities.react";

import LoadingExpander from "../../../App/Components/LoadingExpander";
import IBookingController from "../../../Calendar/Types/IBookingController";
import { INoAvailabilityViewRouter } from "../../../Routing/Types/IRouter";
import IReservation from "../../../Types/IReservation";

export interface ISchedulingLoaderProps {
  /** The current state of the applications booking controller. */
  bookingController: Nullable<Pick<IBookingController, "selected" | "isBookingPossible">>;
  /** The first name of the customer scheduling the appointment. */
  customerFirstName: string;
  /** The reservation held for the user if they have one. */
  reservation: Nullable<IReservation>;
  /** Router which allows the step to send the user to a view. */
  router: INoAvailabilityViewRouter;
}

/** Shows a loading spinner until the booking controller constraints are met. */
const SchedulingLoader: React.FC<ISchedulingLoaderProps & IComponentParent> = ({
  bookingController,
  customerFirstName,
  reservation,
  router,
  children
}) => {
  useEffect(() => {
    if (!reservation && bookingController && !bookingController.isBookingPossible)
      router.goToNoAvailabilityView({ customerFirstName });
  }, [bookingController, customerFirstName]);

  return !bookingController?.selected ? <LoadingExpander message="Loading the Calendar..." /> : <>{children}</>;
};

export default SchedulingLoader;
