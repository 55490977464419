import { Box, Stack } from "@mui/material";
import React, { DispatchWithoutAction } from "react";
import { IComponentParent, IDisable } from "sonobello.utilities.react";

import { isMobileView } from "../../../utils/Constants";
import INextButtonProps from "../Types/INextButtonProps";
import IUserEventSource from "../Types/IUserEventSource";

export type IUserEventNextButtonProps = Omit<INextButtonProps, keyof IUserEventSource>;

export interface IStepWrapperButtonProps {
  /** The next button component to use when in mobile view. */
  NextButtonMobile: React.FC<IUserEventNextButtonProps>;
  /** The next button component to use when in desktop view. */
  NextButtonDesktop: React.FC<IUserEventNextButtonProps>;
}

export interface IStepWrapperProps extends IDisable, IComponentParent, IStepWrapperButtonProps {
  /** When provided override the next button's label with this value. Defaults to `"Next"`. */
  nextButtonLabel?: string;
  /** Flag which overrides the next button's icon to show a `CircularProgress` when `true`. */
  nextButtonLoading?: boolean;
  /** Callback to obtain the step to transition to when the 'next' button is clicked. */
  onNext: DispatchWithoutAction;
}

const StepWrapper: React.FC<IStepWrapperProps> = ({
  children,
  disabled,
  nextButtonLabel = "Next",
  nextButtonLoading = false,
  onNext,
  NextButtonMobile,
  NextButtonDesktop
}) => {
  const showMobile = isMobileView();
  const label = nextButtonLabel.toUpperCase();

  return (
    <Box display="flex" flexGrow={1}>
      <Stack sx={{ display: "flex", flexGrow: 1, pb: showMobile ? 4 : undefined }}>
        <Box display="flex" flexGrow={1} overflow="auto" px={showMobile ? undefined : 6}>
          {children}
        </Box>
        {showMobile ? (
          <NextButtonMobile label={label} disabled={disabled} isLoading={nextButtonLoading} onClick={onNext} />
        ) : (
          <NextButtonDesktop label={label} disabled={disabled} isLoading={nextButtonLoading} onClick={onNext} />
        )}
      </Stack>
    </Box>
  );
};

export default StepWrapper;
