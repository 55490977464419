export enum View {
  loadSession,
  forbidden,
  invalidLink,
  noAvailability,
  nonCandidate,
  endedSession,
  expiredReservation,
  somethingWentWrong,
  thankYou
}

/** Constants map for url path names for the various views the user can experience. */
export const ViewKeyMap = {
  /** The url path used when this obx session has already been completed. */
  completed: { uri: "/completed", key: View.forbidden },
  /** The url path used when an obx session could not be identified from the url session key. */
  invalidLink: { uri: "/invalidLink", key: View.invalidLink },
  /**  */
  loadSession: { uri: "/loadSession", key: View.loadSession },
  /** The url path used when the user is unable to book into a center.
   * @remarks This may be because all slots are occupied, or booking into this center has been disabled.
   */
  noAvailability: { uri: "/noAvailability", key: View.noAvailability },
  /** The url path used when the user is not a candidate for Sono Bello services. */
  nonCandidate: { uri: "/nonCandidate", key: View.nonCandidate },
  /** The url path used when the session auth and refresh tokens are expired and the session must be restarted. */
  endedSession: { uri: "/endedSession", key: View.endedSession },
  /** The url path used when the reservation has expired. */
  expiredReservation: { uri: "/expiredReservation", key: View.expiredReservation },
  /** The url path used when the client experiences an uncaught exception and must display a last-resort error message
   * to the user.
   */
  somethingWentWrong: { uri: "/error", key: View.somethingWentWrong },
  /** The url path used after a user has completed their obx flow and has reached the terminal state. */
  thankYou: { uri: "/thankYou", key: View.thankYou }
};
