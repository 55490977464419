import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useOutletContext } from "react-router";

import AppContext from "../../../AppContext";
import { DisqualifyingReason, DisqualifyingReasonsType } from "../../../dtos/DisqualifyingReason";
import { LeadResult, LeadResultType } from "../../../dtos/LeadResult";
import { IFlowStepProps } from "../../../types/Flow";
import { MedicalForm, MedicalFormState } from "../../../types/MedicalForm";
import { Step, StepProps } from "../../../types/Step";
import StepWrapper from "../wrapper/StepWrapper";
import A1CQuestion from "./MedicalStep.A1CQuestion";
import MedicalQuestions from "./MedicalStep.MedicalQuestions";
import usePostLeadResult from "./MedicalStep.UsePostLeadResult";

/** Component which contains the content for the Medical verification step. */
const MedicalStep: React.FC<StepProps> = () => {
  const { customerCState, disqualifyingReasonsCState, medicalFormCState, setMedicalFormCState } =
    useContext(AppContext);
  const { flowStep, setStep } = useOutletContext<IFlowStepProps>();
  const appContextProps = useContext(AppContext);
  const { loading, setReq } = usePostLeadResult();
  const onClickNext = () => {
    if (
      (flowStep.key === Step.medicalPartOne &&
        MedicalForm.getResultPart(medicalFormCState.partOneQuestions) == MedicalFormState.Invalid) ||
      (flowStep.key === Step.medicalPartTwo &&
        MedicalForm.getResultPart(medicalFormCState.partTwoQuestions) == MedicalFormState.Invalid) ||
      (flowStep.key === Step.medicalA1C && MedicalForm.getResult(medicalFormCState) == MedicalFormState.Invalid)
    ) {
      if (!disqualifyingReasonsCState) throw "Disqualifying reasons was not populated.";
      let payload = new LeadResult(
        customerCState.id,
        customerCState.opportunity.id,
        LeadResultType.ObxNonCandidateMedical
      );
      const disqualifyingReason = DisqualifyingReason.GetReason(
        disqualifyingReasonsCState,
        DisqualifyingReasonsType.ObxMedicalNoncandidate
      );
      payload = LeadResult.AddDisqualifyingReason(payload, disqualifyingReason);
      setReq(r => r && { ...r, payload });
    } else {
      const nextStep = flowStep.nextStep!(appContextProps);
      if (nextStep) setStep(nextStep);
    }
  };

  return (
    <StepWrapper setStep={setStep} flowStep={flowStep} nextButtonLoading={loading} nextButtonOnClick={onClickNext}>
      <Stack>
        {flowStep.key === Step.medicalPartOne ? (
          <MedicalQuestions
            pageIndex={0}
            questions={medicalFormCState.partOneQuestions}
            setQuestions={partOneQuestions => setMedicalFormCState(s => ({ ...s, partOneQuestions }))}
          />
        ) : flowStep.key === Step.medicalPartTwo ? (
          <MedicalQuestions
            pageIndex={1}
            questions={medicalFormCState.partTwoQuestions}
            setQuestions={partTwoQuestions => setMedicalFormCState(s => ({ ...s, partTwoQuestions }))}
          />
        ) : (
          <A1CQuestion />
        )}
      </Stack>
    </StepWrapper>
  );
};

export const MedicalStepHeader = (
  <Typography variant="body1">Medical screening questions continued. Please answer the following:</Typography>
);

export default MedicalStep;
