import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useState } from "react";
import { Hook, IControl, IMutator, Nullable } from "sonobello.utilities.react";

import { IEndedSessionViewRouter } from "../../Routing/Types/IRouter";
import { ICalendarManagerProps as ISignalRListenerCalendarManagerProps } from "../../SignalR/Components/CalendarSignalRListener";
import { CalendarTopicPayload } from "../../SignalR/Types/CalendarTopicPayload";
import ICenter from "../../Types/ICenter";
import CalendarHub, { TrackedCalendarHubCenter } from "../Types/CalendarHub";
import IBookingController, { BookingController } from "../Types/IBookingController";
import ICalendar from "../Types/ICalendar";
import { ICalendarTopicCustom } from "../Types/ICalendarTopicCustom";

/** The state which triggers a calendar worker to perform its function.
 * @typeParam TUpdate - The type of the update data which triggers the worker's calendar acquisition process.
 */
export interface ICalendarWorkerControl<TUpdate> {
  /** The update data which controls the worker functionality. */
  update: Nullable<TUpdate>;
  /** The center data bound to the worker update. */
  center: ICalendarTopicCustom;
}

export enum CalendarFailure {
  /** The request failed to be authorized by the server. */
  Unauthorized = 1,
  /** The calendar could not be found for the requested center. */
  NotFound = 2,
  /** The calendar could not be constructed for the requested center.
   * @remarks This is most likely due to a missing or misconfigured setting within Zenoti.
   */
  BadRequest = 3
}

/** Props for a worker which  obtains a calendar for appointment reservation and booking.
 * @typeParam TControl - The type which triggers the worker's calendar acquisition process.
 * @typeParam TOutput - The updated calendar state returned by the worker.
 */
export interface ICalendarWorkerProps<TControl, TOutput>
  extends IControl<ICalendarWorkerControl<TControl>>,
    IMutator<TOutput> {
  /** Callback to be executed when the worker experiences an error. */
  onError: Dispatch<CalendarFailure>;
}

export interface ICalendarsManagerProps {
  /** The business unit id of the marketing campaign for the current session. */
  businessUnitId: string;
  /** The first name of the customer who is booking an appointment. */
  customerFirstName: string;
  /** The default center assigned to the session. */
  defaultCenter: ICenter;
  /** The router which will allow this component to send the user to the 'ended session' view. */
  router: IEndedSessionViewRouter;
  /** The key for the current OBX session. */
  sessionKey: string;
  /** Update the state of the calendar used for booking. */
  setBookingController: Dispatch<SetStateAction<Nullable<IBookingController>>>;
}

export interface ICalendarsManagerConfig {
  /** The worker which obtains the list of centers available for booking. */
  useGetCenters: Hook<ICenter[]>;
  /** The Component which will handle calendar update notifications for a given center and obtain the new calendar
   * state.
   */
  Worker: React.FC<ICalendarWorkerProps<CalendarTopicPayload, ICalendar>>;
}

/** Manages calendar workers for all centers the user can book in, and publishes the official calendar states. */
const CalendarsManager: React.FC<
  ICalendarsManagerProps & ICalendarsManagerConfig & ISignalRListenerCalendarManagerProps
> = ({
  businessUnitId,
  customerFirstName,
  value,
  router,
  defaultCenter,
  sessionKey,
  subscribe,
  unsubscribe,
  setBookingController,
  useGetCenters,
  Worker
}) => {
  const [calendarHub, setCalendarHub] = useState(new CalendarHub([]));
  const { result, error } = useGetCenters();

  useEffect(() => {
    if (!result) return;
    subscribe(result);
    const newHub = new CalendarHub(result.map(c => new TrackedCalendarHubCenter(c)));
    setCalendarHub(newHub);
    setBookingController(new BookingController(newHub, businessUnitId, defaultCenter.id));
  }, [result]);

  useEffect(() => {
    if (error)
      setCalendarHub(h => {
        const newHub = h.setUnbookable();
        setBookingController(o =>
          o ? o.updateCalendars(newHub) : new BookingController(newHub, businessUnitId, defaultCenter.id)
        );
        return newHub;
      });
  }, [error]);

  const centerCalendarFailed = useCallback(
    (centerId: string) => (errorCode?: CalendarFailure) => {
      if (errorCode === CalendarFailure.Unauthorized)
        router.goToEndedSessionView({ customerName: customerFirstName, sessionKey });
      else {
        unsubscribe(centerId);
        setCalendarHub(h => {
          const newHub = h.removeCenter(centerId);
          setBookingController(c => (c ? c.updateCalendars(newHub) : c));
          return newHub;
        });
      }
    },
    []
  );

  const handleChange = useCallback(
    (centerId: string) => (newCalendar: ICalendar) => {
      if (!newCalendar.schedules.length || newCalendar.schedules.every(s => !s.isAnySlotAvailable))
        return centerCalendarFailed(centerId)(CalendarFailure.NotFound);
      setCalendarHub(h => {
        const newHub = h.update(centerId, newCalendar);
        setBookingController(o =>
          o ? o.updateCalendars(newHub) : new BookingController(newHub, businessUnitId, centerId)
        );
        return newHub;
      });
    },
    []
  );
  return (
    <>
      {calendarHub.workerCenters.map(center => (
        <Worker
          value={{
            center,
            update: value.find(v => v.custom.id === center.id)?.payload || null
          }}
          key={center.id}
          onError={centerCalendarFailed(center.id)}
          onChange={handleChange(center.id)}
        />
      ))}
    </>
  );
};

export default CalendarsManager;
