import { DateTime } from "luxon";
import { DispatchWithoutAction } from "react";

import EnvironmentConfiguration from "../../constants/EnvironmentConfiguration";
import { ICalendarService, IScheduleSlot } from "../Calendar/Types/ICalendar";
import { IVolatileCached } from "./ICached";
import ICenter, { Center } from "./ICenter";

/** A primitive representation of a center which can be written to and restored from the browser cache. */
export interface ICachedReservationCenter extends Omit<ICenter, "timeZone" | "addressParts"> {
  /** The IANA name of the center's time zone. */
  timeZoneName: string;
}

/** An {@link ICenter} with additional support for being written to and restored from the browser cache. */
export class ReservationCenter extends Center {
  constructor(cachedCenter: ICenter) {
    super(
      cachedCenter.id,
      cachedCenter.distance,
      cachedCenter.name,
      cachedCenter.address,
      cachedCenter.phoneNumber,
      cachedCenter.timeZone
    );
  }

  /** Render this entity into a cache-friendly primitive representation. */
  readonly toPrimitive = (): ICachedReservationCenter => ({
    id: this.id,
    distance: this.distance,
    name: this.name,
    address: this.address,
    phoneNumber: this.phoneNumber,
    timeZoneName: this.timeZone.name
  });
}

/** A slot that the user has selected for booking (unconfirmed) bound to it contextual data. */
interface IReservation extends IVolatileCached {
  /** The id of the center in which confirmed appointment for the slot will occur. */
  center: ReservationCenter;
  /** {@inheritdoc IScheduleSlot} */
  slot: IScheduleSlot;
  /** The time in UTC at which point the reservation is no longer respected by the server. */
  expirationUtc: DateTime;
  /** {@inheritdoc ICalendarService} */
  service: ICalendarService;
}

/** {@inheritdoc IReservation} */
export abstract class BaseReservation implements IReservation {
  center: ReservationCenter;
  expirationUtc: DateTime;
  slot: IScheduleSlot;
  service: ICalendarService;

  /**
   * @param center - The center in which the appointment will occur.
   * @param slot - The slot that the user has selected for booking.
   * @param expiresUtc - The time in UTC at which point the reservation is no longer respected by the server.
   * @param service - The service which will be conducted at the confirmed appointment for the given slot.
   */
  protected constructor(center: ICenter, slot: IScheduleSlot, service: ICalendarService, expiresUtc?: DateTime) {
    this.center = new ReservationCenter(center);
    this.slot = slot;
    this.expirationUtc =
      expiresUtc ?? DateTime.utc().plus({ seconds: EnvironmentConfiguration.reservationLifespanSeconds });
    this.service = service;
  }

  /** {@inheritdoc ICached} */
  abstract readonly cache: DispatchWithoutAction;

  /** {@inheritdoc IVolatileCached.unsetCache} */
  abstract readonly unsetCache: DispatchWithoutAction;
}

export default IReservation;
