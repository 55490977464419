import { useCallback, useEffect, useState } from "react";
import { Nullable, TriggeredHook } from "sonobello.utilities.react";

import { LeadResult, LeadResultType } from "../../../../dtos/LeadResult";
import useObx from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import IReservation from "../../../Types/IReservation";
import { IConfirmedAppointmentResponse } from "../Components/ConfirmationStepWrapper";
import LegacyAppointmentRequest, { ILegacyAppointmentRequest } from "../Types/LegacyAppointment";
import { IUseConfirmAppointmentProps } from "./UseConfirmHybridAppointment";
import { IAppointmentResponse } from "./UseConfirmOpsAppointment";

/** A hook which confirms the appointment by creating it and dispositioning the associated lead as book. */
const useConfirmLegacyAppointment: TriggeredHook<
  IConfirmedAppointmentResponse,
  IReservation,
  boolean,
  IUseConfirmAppointmentProps
> = ({ appointmentNoteProps, customer, leadId, useCreateLeadResult }) => {
  const [result, setResult] = useState<Nullable<IConfirmedAppointmentResponse>>(null);

  const {
    res: appointmentResult,
    err: appointmentError,
    loading: appointmentLoading,
    setReq: setAppointmentReq
  } = useObx<IAppointmentResponse, ILegacyAppointmentRequest>("Post Appointment", { method: "post" });

  const {
    result: leadResult,
    error: leadResultError,
    isLoading: isLeadResultLoading,
    execute: executePostLeadResult
  } = useCreateLeadResult({ leadId });

  useEffect(() => {
    if (appointmentResult)
      executePostLeadResult(
        new LeadResult(
          appointmentResult.payload.customerId,
          customer.opportunity.id,
          LeadResultType.Booked,
          appointmentResult.payload.id
        )
      );
  }, [appointmentResult]);

  useEffect(() => {
    if (leadResult && appointmentResult)
      setResult({
        appointment: {
          id: appointmentResult.payload.id,
          startTimeUtc: appointmentResult.payload.startTimeUtc,
          endTimeUtc: appointmentResult.payload.endTimeUtc
        },
        leadResult
      });
  }, [leadResult, appointmentResult]);

  const execute = useCallback(
    (reservation: IReservation) =>
      setAppointmentReq(
        q =>
          q && {
            ...q,
            url: ApiRequestPaths.postLegacyAppointmentUrl(leadId),
            payload: new LegacyAppointmentRequest(
              customer,
              reservation.center.id,
              leadId,
              reservation.service,
              reservation.slot.startTimeUtc,
              reservation.slot.endTimeUtc,
              appointmentNoteProps
            )
          }
      ),
    []
  );

  return {
    result,
    execute,
    isLoading: isLeadResultLoading || appointmentLoading,
    error: Boolean(leadResultError || appointmentError)
  };
};

export default useConfirmLegacyAppointment;
