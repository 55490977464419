import { Optional } from "sonobello.utilities.react.mui";

import { AppContextProps } from "../AppContext";
import { FlowStepperItemProps } from "../components/main/Main.Stepper";
import StepperId from "../V2/App/Types/StepperId";
import { Step } from "./Step";

export enum FlowType {
  newPatient = "newPatient",
  noBook = "noBook",
  rebook = "rebook"
}

/** The properties governing a step in the current flow. */
export interface FlowStep {
  /** The unique {@link Step} identifier for the step. */
  key: Step;
  /** The id of the stepper label that should be styled as 'active' when this step is currently active. */
  stepperId?: StepperId;
  /** A function which identifies if this flow item is completed.
   * If the prop is undefined, this step is set to completed so long as the current step is `> this.step`.
   * @returns `true` if the step has been completed, `false` otherwise.
   */
  isComplete?: (contextProps: AppContextProps, currentStep: Step) => boolean;
  /** A function which controls the next step of the current flow.
   * If the prop is undefined, then there is no next possible step.
   * @returns `undefined` indicates that the next step is disabled for some reason.
   * @returns The next {@link Step} that the user should experience if they progress.
   */
  nextStep?: (props: AppContextProps) => Optional<Step>;
  /** A function which identified if the step should be hidden and the next step shown.
   * @returns `true` if the step should be skipped, `false` otherwise.
   */
  shouldSkip?: (contextProps: AppContextProps) => boolean;
}

export interface IFlow {
  /** The simple name of the flow.
   * @remarks All step paths should be a child of `app/FLOW_NAME`.
   */
  readonly name: string;
  /** The configuration for the steps which compose this flow.
   * @remarks The first declared step in the flow is automatically navigated to when a flow is authenticated.
   */
  readonly steps: Partial<Record<Step, FlowStep>>;
  /** The configuration for the stepper rendered during this flow. */
  readonly stepperConfiguration: FlowStepperConfiguration[];
}

/** A series of steps which a user will experience in their interaction with the OBX.
 * @remarks The first flow item is always displayed at the beginning of the flow.
 * Stepper items are displayed in the same order in which they are ordered on the object.
 */
export class Flow implements IFlow {
  readonly name: string;
  readonly steps: Partial<Record<Step, FlowStep>>;
  readonly stepperConfiguration: FlowStepperConfiguration[];

  constructor(name: string, steps: Flow["steps"], stepperConfiguration: Flow["stepperConfiguration"]) {
    this.name = name;
    this.steps = steps;
    this.stepperConfiguration = stepperConfiguration;
  }

  /** Return a flag indicating if the provided flow contains the specified step. */
  readonly ContainsStep = (step: Step) => Object.keys(this.steps).some(k => Number(k) === step);

  /** Get the first step that has no defined skip behavior, or suceeds its skip definition for the given flow given the
   * current app context.
   */
  readonly GetFirstStep = (contextProps: AppContextProps) => {
    const firstNonSkippedStep = Object.entries(this.steps).find(
      ([, value]) => !value.shouldSkip || !value.shouldSkip(contextProps)
    );
    if (!firstNonSkippedStep) throw new Error("No valid first step found in flow.");
    return Number(firstNonSkippedStep[0]) as Step;
  };

  /** Get the number of steps presented to the user for the current session. */
  readonly GetStepCount = (context: AppContextProps) =>
    this.stepperConfiguration.filter(v => !v.shouldSkip || !v.shouldSkip(context)).length;
}

export interface FlowStepperConfiguration extends Omit<FlowStepperItemProps, "setStep"> {
  /** Returns a flag indicating if this stepper item should be considered complete. */
  isComplete: (currentStep: Step) => boolean;
  /** A function which identified if the step should be hidden and the next step shown.
   * @returns `true` if the step should be skipped, `false` otherwise.
   */
  shouldSkip?: (contextProps: AppContextProps) => boolean;
}

// The base interface for a flow's step component props
export interface IFlowStepProps {
  /** The flow item which controls the flow steps' major functionality. */
  flowStep: FlowStep;
  /** Callback to set the current flow step. */
  setStep: (step: Step) => void;
}
