import React, { ComponentProps } from "react";

import EnvironmentConfiguration from "../../../../constants/EnvironmentConfiguration";
import AppContextValueProvider from "../../../App/Compositions/AppContextValueProvider";
import UserEventContextValueProvider from "../../../App/Compositions/UserEventContextValueProvider";
import IUserEventSource from "../../Types/IUserEventSource";
import DesktopDateSelector from "../Components/DesktopDateSelector";
import MobileDateSelector from "../Components/MobileDateSelector";
import ScheduleSlotSelector from "../Components/ScheduleSlotSelector";
import ScheduleSlotTimeLocaleSelector from "../Components/ScheduleSlotTimeLocaleSelector";
import { IScheduleSlotSelectorProps } from "../Components/SchedulingStep";

const UserEventScheduleSlotSelector: React.FC<
  Omit<ComponentProps<typeof ScheduleSlotSelector>, keyof IUserEventSource>
> = UserEventContextValueProvider({ Component: ScheduleSlotSelector });

const AppContextScheduleSlotSelector: React.FC<Omit<ComponentProps<typeof UserEventScheduleSlotSelector>, "schedule">> =
  AppContextValueProvider<
    Pick<ComponentProps<typeof UserEventScheduleSlotSelector>, "schedule">,
    ComponentProps<typeof UserEventScheduleSlotSelector>
  >({
    Component: UserEventScheduleSlotSelector,
    map: ({ bookingController }) => {
      if (!bookingController?.selected) throw new Error("Booking schedule is required.");
      return { schedule: bookingController.selected.schedule };
    }
  });

const DynamicScheduleSlotSelector: React.FC<IScheduleSlotSelectorProps> = props => (
  <AppContextScheduleSlotSelector
    {...props}
    slotSelectionMaskedMinutes={EnvironmentConfiguration.calendarAllowedPaddingMinutes}
    refreshIntervalMs={20000}
    timeSelectorCutOffs={{ morning: 12, afternoon: 17 }}
    DesktopDateSelector={DesktopDateSelector}
    MobileDateSelector={MobileDateSelector}
    TimeLocaleSelector={ScheduleSlotTimeLocaleSelector}
  />
);

export default DynamicScheduleSlotSelector;
