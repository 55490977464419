import React from "react";

import OpsUtilities from "../../Constants/OpsUtility";
import { CalendarTopicPayload } from "../../SignalR/Types/CalendarTopicPayload";
import ICalendar from "../Types/ICalendar";
import { ICalendarWorkerProps } from "./CalendarManager";

export type ILegacyWorkerProps = ICalendarWorkerProps<Required<CalendarTopicPayload>["legacy"], ICalendar>;
export type IOpsWorkerProps = ICalendarWorkerProps<Required<CalendarTopicPayload>["ops"], ICalendar>;

export interface IHybridCalendarWorkerConfig {
  /** The worker to be used when the worker control is for the OPS calendar process. */
  OpsWorker: React.FC<IOpsWorkerProps>;
  /** The worker to be used whe nthe worker control is for the legacy calendar process. */
  LegacyWorker: React.FC<ILegacyWorkerProps>;
}

/** A Calendar worker which obtains the calendar state from either its legacy or ops child, and maps it to a uniform
 * format before returning.
 */
const HybridCalendarWorker: React.FC<
  IHybridCalendarWorkerConfig & ICalendarWorkerProps<CalendarTopicPayload, ICalendar>
> = ({ value, LegacyWorker, OpsWorker, ...rest }) => {
  const useLegacyWorker = Boolean(value.update?.legacy) || !OpsUtilities.isCenterSupported(value.center.id);
  return useLegacyWorker ? (
    <LegacyWorker value={{ center: value.center, update: value.update?.legacy || null }} {...rest} />
  ) : (
    <OpsWorker value={{ center: value.center, update: value.update?.ops || null }} {...rest} />
  );
};

export default HybridCalendarWorker;
