import StepProvider, { IStepProviderProps } from "../Components/StepProvider.v12";
import AppContextValueProvider from "./AppContextValueProvider";

/** A provisioned {@link StepProvider} from the App Context for all props except those involving page routing. */
const AppContextStepProvider: React.FC<Pick<IStepProviderProps, "router" | "pathName">> = AppContextValueProvider<
  Omit<IStepProviderProps, "router" | "pathName">,
  IStepProviderProps
>({
  Component: StepProvider,
  map: appContextProps => ({ appContextProps })
});

export default AppContextStepProvider;
