import { ICalendarTopicCustom } from "../../Calendar/Types/ICalendarTopicCustom";
import { CalendarTopicPayload, RawCalendarTopicPayload } from "./CalendarTopicPayload";
import { TopicPayload } from "./TopicSource";

class CalendarTopicHub {
  readonly topicPayloads: TopicPayload<ICalendarTopicCustom, CalendarTopicPayload>[];

  constructor(value: TopicPayload<ICalendarTopicCustom, RawCalendarTopicPayload>[]) {
    this.topicPayloads = value.map(c => CalendarTopicHub.getParsedPayload(c));
  }

  private static getParsedPayload = (
    c: TopicPayload<ICalendarTopicCustom, RawCalendarTopicPayload>
  ): TopicPayload<ICalendarTopicCustom, CalendarTopicPayload> =>
    new TopicPayload(c.topic, c.custom, c.payload ? new CalendarTopicPayload(c.payload) : null);

  /** Update the hub with the new state of the topic payloads. Ignores change if no topic has a more recent
   * payload tha is already held.
   */
  readonly update = (value: TopicPayload<ICalendarTopicCustom, RawCalendarTopicPayload>[]): CalendarTopicHub => {
    return new CalendarTopicHub(value);
  };
}

export default CalendarTopicHub;
