import { ViewConnector } from "./ViewConnector";

/** An enum representing all possible steps within any Flow. */
export enum Step {
  welcome = 1,
  qualify = 2,
  medicalPartOne = 3,
  medicalPartTwo = 4,
  medicalA1C = 5,
  centerSelection = 6,
  scheduling = 7,
  confirmation = 8,
  updateCustomer = 9
}

/** A map between the step and the uri path it is located at. */
export const StepPathMap: Record<Step, string> = {
  [Step.centerSelection]: "centerSelection",
  [Step.confirmation]: "confirmation",
  [Step.medicalPartOne]: "medical",
  [Step.medicalPartTwo]: "medical",
  [Step.medicalA1C]: "medical",
  [Step.qualify]: "qualify",
  [Step.scheduling]: "scheduling",
  [Step.updateCustomer]: "updateCustomer",
  [Step.welcome]: "welcome"
};

/** The properties that all views are universally provided with.  */
export interface StepProps {
  /** @see {@link ViewConnector} */
  viewConnector: ViewConnector;
}

/** Returns the uri path to the given step and flow. */
export const GetStepPath = (step?: Step) => `/app${step ? `/${StepPathMap[step]}` : ""}`;

/** Discover the current step by matching from the current uri path.
 * @param currentPathName - The current uri path.
 */
export const GetCurrentStep = (currentPathName: string): Step | undefined => {
  const stepPathKeyValuePair = Object.entries(StepPathMap).find(([, pathName]) => currentPathName.includes(pathName));
  return stepPathKeyValuePair && (Number(stepPathKeyValuePair[0]) as Step);
};
