import { useCallback, useState } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import { ICalendarService, IScheduleSlot } from "../../../Calendar/Types/ICalendar";
import { OpsScheduleSlot } from "../../../Calendar/Types/OpsCalendar";
import OpsUtilities from "../../../Constants/OpsUtility";
import ICenter from "../../../Types/ICenter";
import IReservation from "../../../Types/IReservation";

export interface IUseCreateReservationProps {
  /** The id of the curren't session's lead. */
  leadId: string;
  /** The center in which the reservation should be made. */
  center: ICenter;
  /** The service which will be conducted at the reserved appointment. */
  service: ICalendarService;
}

export interface IUseCreateOpsReservationProps extends IUseCreateReservationProps {
  /** The id of the customer associated with the reservation to be made. */
  customerId: string;
}

export interface IUseCreateHybridReservationConfig {
  /** The post reservation hook for use with the OPS process. */
  useCreateOpsReservation: TriggeredHook<IReservation, OpsScheduleSlot, boolean, IUseCreateOpsReservationProps>;
  /** The post reservation hook for use with the legacy process. */
  useCreateLegacyReservation: TriggeredHook<IReservation, IScheduleSlot, boolean, IUseCreateReservationProps>;
}

/** A hook which creates a reservation for the given {@link IScheduleSlot} using the appropriate calendar process for the input. */
const useCreateHybridReservation: TriggeredHook<
  IReservation,
  IScheduleSlot,
  boolean,
  IUseCreateHybridReservationConfig & IUseCreateOpsReservationProps
> = ({ useCreateLegacyReservation, useCreateOpsReservation, center, ...rest }) => {
  const [isLegacy, setIsLegacy] = useState<boolean>(true);
  const {
    result: legacyResult,
    error: legacyError,
    isLoading: legacyIsLoading,
    execute: legacyExecute
  } = useCreateLegacyReservation({ ...rest, center });
  const {
    result: opsResult,
    error: opsError,
    isLoading: opsIsLoading,
    execute: opsExecute
  } = useCreateOpsReservation({ ...rest, center });

  const execute = useCallback((scheduleSlot: IScheduleSlot) => {
    if (OpsUtilities.isCenterSupported(center.id)) {
      setIsLegacy(false);
      if (!(scheduleSlot instanceof OpsScheduleSlot))
        throw "Expected OpsScheduleSlot type, but could not resolve to it.";
      return opsExecute(scheduleSlot as OpsScheduleSlot);
    } else {
      setIsLegacy(true);
      return legacyExecute(scheduleSlot);
    }
  }, []);

  return isLegacy
    ? { result: legacyResult, error: legacyError, isLoading: legacyIsLoading, execute }
    : { result: opsResult, error: opsError, isLoading: opsIsLoading, execute };
};

export default useCreateHybridReservation;
