import { IANAZone } from "luxon";
import React, { useEffect } from "react";
import { TriggeredHook } from "sonobello.utilities.react";

import { CalendarFailure, ICalendarWorkerProps as ICalendarManagerWorkerProps } from "./CalendarManager";

/** The config props available to the calendar hooks. */
export interface ICalendarHookProps {
  /** The if of the center to which the requested calendar belongs. */
  centerId: string;
  /** The time zone of the center to which the requested calendar belongs. */
  centerTimeZone: IANAZone;
}

/** The properties which define the calendar acquisition behaviors for an identity calendar worker.
 * @typeParam TResponse - The calendar type returned by the hook.
 */
export interface IIdentityCalendarWorkerProps<TResponse> {
  /** The hook which obtains the calendar for a center with the given id. */
  useGetCenterCalendar: TriggeredHook<TResponse, undefined, CalendarFailure, ICalendarHookProps>;
}

/** A worker which gets the calendar for its assigned center on mount and on each control on update. */
function CenterCalendarWorker<TResponse>({
  value,
  onChange,
  onError,
  useGetCenterCalendar
}: ICalendarManagerWorkerProps<unknown, TResponse> & IIdentityCalendarWorkerProps<TResponse>): ReturnType<
  React.FC<unknown>
> {
  const { result, error, execute } = useGetCenterCalendar({
    centerId: value.center.id,
    centerTimeZone: value.center.timeZone
  });

  useEffect(() => execute(), [value.update]);

  useEffect(() => {
    if (error) onError(error);
  }, [error]);

  useEffect(() => {
    if (result) onChange(result);
  }, [result]);

  return null;
}

export default CenterCalendarWorker;
