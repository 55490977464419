import { useRef } from "react";

import ExperimentContextLoader, { IExperimentContextLoaderProps } from "../Components/ExperimentContextLoader";
import useReportConnectionError from "../Hooks/UseReportConnectionError";
import { DefaultExperimentContextProps } from "../Types/IExperimentContextProps";

const useDisableExperimentation: IExperimentContextLoaderProps["firstUseGetExperimentContextHook"] = () => {
  const valueRef = useRef<ReturnType<IExperimentContextLoaderProps["firstUseGetExperimentContextHook"]>>({
    result: new DefaultExperimentContextProps(),
    error: null,
    isLoading: false
  });
  return valueRef.current;
};

/** A {@link ExperimentContextLoader} which supplies an experiment context that disables all experimentation. */
const DisabledExperimentContextLoader: React.FC<
  Omit<IExperimentContextLoaderProps, "firstUseGetExperimentContextHook" | "reportConnectionErrorHook">
> = props =>
  ExperimentContextLoader({
    ...props,
    firstUseGetExperimentContextHook: useDisableExperimentation,
    reportConnectionErrorHook: useReportConnectionError
  });

export default DisabledExperimentContextLoader;
