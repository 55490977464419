import { DateTime, Interval } from "luxon";
import { Optional } from "sonobello.utilities.react.mui";

import { bodyMassIndex } from "../utils/Constants";
import { CustomerNote } from "./CustomerNote";
import { DisqualifyingReasonsType } from "./DisqualifyingReason";
import { Opportunity } from "./Opportunity";

let environmentErrors = "";

const minimumAge = Number(process.env.REACT_APP_MINIMUM_AGE);

if (isNaN(minimumAge)) environmentErrors += "REACT_APP_MINIMUM_AGE not set in environment.";

const minimumBodyMassIndex = Number(process.env.REACT_APP_MINIMUM_BODY_MASS_INDEX);

if (isNaN(minimumBodyMassIndex)) {
  if (environmentErrors !== "") environmentErrors += " ";
  environmentErrors += "REACT_APP_MINIMUM_BODY_MASS_INDEX not set in environment.";
}

const maximumBodyMassIndex = Number(process.env.REACT_APP_MAXIMUM_BODY_MASS_INDEX);

if (isNaN(maximumBodyMassIndex)) {
  if (environmentErrors !== "") environmentErrors += " ";
  environmentErrors += "REACT_APP_MAXIMUM_BODY_MASS_INDEX not set in environment.";
}

if (environmentErrors !== "") throw environmentErrors;

/** An object represent the personal information of the user that is interacting with the client. */
export class Customer {
  /** The id of the center associated with this customer. */
  centerId!: string;
  /** The customer's date of birth. */
  dateOfBirth?: string;
  /** The distance to this customers selected center. */
  distanceToCenter?: number;
  /** The id of the customer. */
  id!: string;
  /** The properly formed email of the customer.
   * @remarks example: `test@gmail.com`
   */
  email?: string;
  /** The first name of the customer.
   * @remarks This prop should not be rendered to the user, see {@link EnhancedCustomer.displayFirstName}.
   */
  firstName!: string;
  /** The height of the customer. */
  height?: {
    inches: number;
    feet: number;
  };
  /** The last name of the customer. */
  lastName!: string;
  /** The current opportunity associated with the customer. */
  opportunity!: Opportunity;
  /** The phone number of the customer.
   * @remarks observes the following template: `9876543210`.
   */
  phoneNumber?: string;
  /** The weight of the customer. */
  weight?: number;
  /** The zip code where the customer is located. */
  zipCode?: string;
  /** Whether the Customer is previously qualified.
   * @remarks Defaults to `false`.
   */
  isPreviouslyQualified = false;
  /** Timestamp of the previous qualification. */
  qualificationDateUtc?: string;

  /** Returns the customer's bmi value.
   * @returns the calculated value, or undefined if height or weight is not set.
   */
  static getBmi = (customer: Pick<Customer, "height" | "weight">): Optional<number> => {
    if (!customer.height || !customer.weight) return undefined;
    const heightInInches = customer.height.feet * 12 + Number(customer.height.inches);
    return Number(bodyMassIndex(customer.weight, heightInInches).toFixed(2));
  };

  /**
   * Return a {@link DisqualifyingReasonsType} if the customer is not qualified for services, undefined otherwise.
   * @remarks Throws if height or weight are not provided for the customer.
   */
  static getDisqualifyingReason = (customer: Customer): Optional<DisqualifyingReasonsType> => {
    if (!customer.height || !customer.weight || !customer.dateOfBirth)
      throw "Cannot determine qualification without height, weight, and date of birth.";
    if (Interval.fromDateTimes(DateTime.fromISO(customer.dateOfBirth), DateTime.now()).length("years") < minimumAge)
      return DisqualifyingReasonsType.ObxAge;
    const heightInInches = customer.height.feet * 12 + Number(customer.height.inches);
    const bmi = Number(bodyMassIndex(customer.weight, heightInInches).toFixed(2));
    return bmi <= minimumBodyMassIndex || bmi >= maximumBodyMassIndex ? DisqualifyingReasonsType.ObxBmi : undefined;
  };

  /** Returns the customer's phone number after formatting it for easy reading. */
  static formatPhoneNumber = (customer: Customer): string => {
    return `${customer.phoneNumber?.substring(0, 3)}-${customer.phoneNumber?.substring(
      3,
      6
    )}-${customer.phoneNumber?.substring(6, 10)}`;
  };
}

const unsaltRegex = /^(.*)( \d{4}-\d{2}-\d{2}T\d{6})U?$/;
const unsaltFirstName = (firstName: string): string => {
  const matches = unsaltRegex.exec(firstName);
  if (!matches || !matches[1]) return firstName;
  return matches[1];
};

export class EnhancedCustomer extends Customer {
  /** The first name which should be displayed to the user within the UI. */
  displayFirstName: string;
  note?: CustomerNote;

  constructor(customer: Customer, note?: CustomerNote) {
    super();
    this.centerId = customer.centerId;
    this.note = note;
    this.dateOfBirth = customer.dateOfBirth;
    this.distanceToCenter = customer.distanceToCenter ?? undefined;
    this.id = customer.id;
    this.email = customer.email;
    this.firstName = customer.firstName;
    this.height = customer.height;
    this.lastName = customer.lastName;
    this.opportunity = customer.opportunity;
    this.phoneNumber = customer.phoneNumber;
    this.weight = customer.weight;
    this.zipCode = customer.zipCode;
    this.isPreviouslyQualified = customer.isPreviouslyQualified;
    this.qualificationDateUtc = customer.qualificationDateUtc;

    this.displayFirstName = unsaltFirstName(customer.firstName);
  }

  static toBase = (customer: EnhancedCustomer): Customer => {
    const clone = { ...customer } as Partial<EnhancedCustomer>;
    delete clone["displayFirstName"];
    delete clone["note"];
    return clone as Customer;
  };
}

/** Qualification of a customer. */
export interface CustomerQualification {
  /** The height of the customer in inches. */
  heightInches?: number;
  /** The weight of the customer in imperial pounds. */
  weight?: number;
  /** The calculated body mass index of the customer from the height and weight parameters. */
  bodyMassIndex?: number;
  /** The date of birth of the customer as an ISO date. */
  dateOfBirth?: string;
  /** Flag indicating if the customer successfully passed all medical qualification questions. */
  isMedicallyQualified?: boolean;
}
