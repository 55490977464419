import CancelIcon from "@mui/icons-material/Cancel";
import { Typography } from "@mui/material";
import React from "react";

import ViewWrapper, { ViewWrapperIconProps } from "./ViewWrapper";

export interface ForbiddenSessionViewProps {
  /** Error message from OBX to display to the user. */
  forbiddenSessionErrorMessage?: string;
}

/** The web page shown when the user has a valid link but the link has already been completed. */
const ForbiddenSessionView: React.FC<ForbiddenSessionViewProps> = ({ forbiddenSessionErrorMessage }) => {
  return (
    <ViewWrapper
      header="Link Has Expired"
      subheader={forbiddenSessionErrorMessage || "Sorry, but this link has expired and cannot be used."}
      icon={<CancelIcon {...ViewWrapperIconProps} />}
    >
      <Typography variant="body1">You can return to SonoBello.com by clicking the below button.</Typography>
    </ViewWrapper>
  );
};

export default ForbiddenSessionView;
