import CancelIcon from "@mui/icons-material/Cancel";
import { Typography } from "@mui/material";
import React from "react";

import ViewWrapper, { ViewWrapperIconProps } from "./ViewWrapper";

/** The web page shown when the user's provided obx link could not be verified. */
const InvalidLinkView: React.FC = () => {
  return (
    <ViewWrapper
      header="Sorry!"
      subheader="You cannot proceed without a valid link."
      icon={<CancelIcon {...ViewWrapperIconProps} />}
    >
      <Typography variant="body1">You can return to SonoBello.com by clicking the below button.</Typography>
    </ViewWrapper>
  );
};

export default InvalidLinkView;
