import { AxiosResponse } from "axios";
import { Hook } from "sonobello.utilities.react";
import { useApi } from "sonobello.utilities.react.axios";

import { Customer } from "../../../../dtos/Customer";
import { ApiObjectError } from "../../../../dtos/ObxError";
import { HttpStatusCode } from "../../../../types/HttpStatusCodes";
import { ObxAxiosInstance } from "../../../../utils/UseObx";
import { IUsePostTokenError, UsePostTokenErrorType } from "../Components/LoadSessionView";
import { TokenRequest, TokenResponse } from "../Types/TokenRequest";

class UsePostTokenError implements IUsePostTokenError {
  readonly type: UsePostTokenErrorType;
  readonly message?: string;

  constructor(errorResponse?: AxiosResponse<ApiObjectError<Customer>, unknown>) {
    if (errorResponse?.status === HttpStatusCode.Forbidden) this.type = UsePostTokenErrorType.AlreadyCompleted;
    else if (errorResponse?.status === HttpStatusCode.NotFound) this.type = UsePostTokenErrorType.NotFound;
    else this.type = UsePostTokenErrorType.SomethingWentWrong;
    this.message = errorResponse?.data?.message;
  }
}

const usePostToken: Hook<TokenResponse, IUsePostTokenError, string> = sessionKey => {
  const { res, err, loading } = useApi<TokenResponse, TokenRequest, undefined, ApiObjectError<Customer>>({
    request: {
      method: "post",
      url: "token",
      custom: undefined,
      payload: new TokenRequest(sessionKey)
    },
    config: { axiosInstance: ObxAxiosInstance }
  });

  return {
    result: res?.payload || null,
    error: (err && new UsePostTokenError(err.error.response)) || null,
    isLoading: loading
  };
};

export default usePostToken;
