import ReportStepProgress, { IReportStepProgressProps } from "../Components/ReportStepProgress";
import usePostFlowStepProgress from "../Hooks/UsePostFlowStepProgress";
import AppContextValueProvider from "./AppContextValueProvider";

/** A {@link ReportStepProgress} which obtains its flow/lead context from the AppContext. */
const AppContextReportStepProgress: React.FC<Pick<IReportStepProgressProps, "pathName">> = AppContextValueProvider<
  Omit<IReportStepProgressProps, "pathName">,
  IReportStepProgressProps
>({
  Component: ReportStepProgress,
  map: ({ flowCState, flowIdCState, leadIdCState }) => ({
    flow: flowCState,
    flowId: flowIdCState,
    leadId: leadIdCState,
    usePostFlowStepProgress
  })
});

export default AppContextReportStepProgress;
