import { Grid, styled } from "@mui/material";
import { DatePickerProps, StaticDatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React from "react";
import { UserEvent, UserEventType } from "sonobello.utilities.react.mui";

import { IDateSelectorProps } from "./ScheduleSlotSelector";

const StyledCalendarPicker = styled(StaticDatePicker)<DatePickerProps<DateTime>>(({ theme }) => ({
  "& .MuiPickersDay-today": { borderColor: `${theme.palette.primary.main} !important` },
  "& .MuiPickersToolbar-root": { padding: `${theme.spacing(0)} ${theme.spacing(3)}` }
}));

const DesktopDateSelector: React.FC<Omit<IDateSelectorProps, "maxDate" | "minDate">> = ({
  timezone,
  value,
  onChange,
  onEvent,
  shouldDisableDate
}) => {
  const handleChange = (d: unknown) => {
    const selectedDate = (d as DateTime | null)
      ? DateTime.fromObject(
          {
            day: (d as DateTime).day,
            month: (d as DateTime).month,
            year: (d as DateTime).year
          },
          { zone: timezone }
        )
      : null;
    if (!selectedDate) return;
    onEvent(new UserEvent(UserEventType.Change, "scheduleStep-mui-x-datePicker", selectedDate?.toISO()));
    onChange(selectedDate);
  };
  return (
    <Grid container direction="column" spacing={1} wrap="nowrap" sx={{ height: "100%" }}>
      <Grid item xs sx={{ overflowY: "auto", overflowX: "hidden", pb: 1, pr: 1.25 }}>
        <StyledCalendarPicker
          openTo="day"
          value={value}
          onChange={handleChange}
          shouldDisableDate={shouldDisableDate as (date: unknown) => boolean}
          orientation="portrait"
          views={["day"]}
          slotProps={{ actionBar: { sx: { display: "none" } }, toolbar: { hidden: false } }}
        />
      </Grid>
    </Grid>
  );
};

export default DesktopDateSelector;
