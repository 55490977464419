import { DateTime, IANAZone } from "luxon";

import CalendarScheduleDay from "./CalendarScheduleDay";
import CalendarServiceSchedule from "./CalendarServiceSchedule";
import CalendarSlotStatus from "./CalendarSlotStatus";
import ICalendar, { ICachedScheduleSlot, ICalendarSchedule, ICalendarServiceSchedule, ScheduleSlot } from "./ICalendar";
import { IOpsCalendarResponse, IOpsCalendarResponseSchedule, IOpsCalendarResponseSlot } from "./IOpsCalendarResponse";

const buildIsoDateTime = (dateIso: string, timeIso: string, zone: IANAZone) =>
  DateTime.fromISO(`${dateIso}T${timeIso}`, { zone });

/** The primitive OPS slot which is written to and restored from the browser cache. */
interface ICachedOpsScheduleSlot extends ICachedScheduleSlot {
  /** {@inheritdoc IOpsCalendarResponseSlot.id} */
  readonly id: number;
}

export class OpsScheduleSlot extends ScheduleSlot {
  /** The primary identifier of the slot. */
  readonly id: number;

  /**
   * @param slot - The OPS slot which contains the slot's details.
   * @param dateIso - The date of the slot in ISO format.
   * @param centerTimeZone - The time zone in which the slot's center is located.
   */
  constructor(slot: IOpsCalendarResponseSlot, dateIso: string, centerTimeZone: IANAZone) {
    super(
      buildIsoDateTime(dateIso, slot.startTime, centerTimeZone).toUTC().toISO(),
      buildIsoDateTime(dateIso, slot.endTime, centerTimeZone).toUTC().toISO(),
      slot.type !== CalendarSlotStatus.Open,
      centerTimeZone
    );
    this.id = slot.id;
  }

  /** {@inheritdoc IScheduleSlot.getCachedPrimitive} */
  getCachedPrimitive = (): ICachedOpsScheduleSlot => ({
    id: this.id,
    startTimeUtcIso: this.startTimeUtc.toISO(),
    endTimeUtcIso: this.endTimeUtc.toISO(),
    isOccupied: this.isOccupied,
    timeZoneName: this.endTimeLocal.zone.name
  });
}

export class OpsCalendarScheduleDay extends CalendarScheduleDay {
  constructor(slots: IOpsCalendarResponseSlot[], dateIso: string, centerTimeZone: IANAZone) {
    super(slots.map(slot => new OpsScheduleSlot(slot, dateIso, centerTimeZone)));
  }
}

export class OpsCalendarServiceSchedule extends CalendarServiceSchedule {
  constructor(schedule: IOpsCalendarResponseSchedule, centerTimeZone: IANAZone) {
    const formattedDays: ICalendarSchedule = {};
    for (const [date, slots] of Object.entries(schedule.days))
      formattedDays[date] = new OpsCalendarScheduleDay(slots ?? [], date, centerTimeZone);
    super(schedule.service, formattedDays, centerTimeZone);
  }
}

/** An implementation of {@link ICalendar} from the OPS calendar payload pattern. */
class OpsCalendar implements ICalendar {
  readonly schedules: ICalendarServiceSchedule[];

  constructor(calendarRequest: IOpsCalendarResponse, centerTimeZone: IANAZone) {
    this.schedules = calendarRequest.schedules.map(s => new OpsCalendarServiceSchedule(s, centerTimeZone));
  }
}

export default OpsCalendar;
