import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import React from "react";
import { IControlledMutator, IDisable } from "sonobello.utilities.react";

export interface DateOfBirthPickerProps extends IControlledMutator<DateTime | null>, IDisable {
  /** The earliest date which can be selected. */
  startDate: DateTime;
}

export const classNames = {
  inputId: "qualifyStep_dateOfBirthField"
};

/** A Date picker customized for the selection of a customer's date of birth. */
const DateOfBirthPicker: React.FC<DateOfBirthPickerProps> = ({ disabled, startDate, value, onChange }) => {
  const handleChange = (value: DateTime | null) => {
    if (!value) return onChange(null);
    if (value.isValid && (!startDate || value > startDate) && value < DateTime.now()) onChange(value.startOf("day"));
  };
  return (
    <DatePicker
      label={"Date of Birth"}
      openTo="year"
      views={["year", "month", "day"]}
      value={value}
      minDate={startDate}
      disableFuture
      onChange={handleChange}
      disabled={disabled}
      slotProps={{ textField: { id: classNames.inputId } }}
    />
  );
};

export default DateOfBirthPicker;
